import "core-js/modules/es.array.push.js";
import { trimAll } from '@/utils/customUtils';

import { defineComponent, reactive, onMounted, toRefs } from 'vue';
export default defineComponent({
  name: 'LazySelect',
  props: {
    // 是否可清空
    clearable: {
      type: Boolean,
      default: true
    },
    // 默认显示文字
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 每页请求条数
    pageSize: {
      type: Number,
      default: 10
    },
    // 请求api
    url: {
      type: Function,
      required: true
    },
    params: {
      type: Function,
      required: false
    },
    // 搜索时请求字段
    column: {
      type: String,
      required: false,
      default: 'keyWord'
    },
    // 给extraInfo中字段赋值，如果extraInfo中字段已存在值则不进行覆盖
    data: {
      type: Object,
      default: null
    },
    // 扩展信息--->搜索请求时除关键字外其他附带搜索参数
    extraInfo: {
      type: Object,
      default: null
    },
    // 请求失败自动重试次数
    retry: {
      type: Number,
      default: 3
    },
    // 是否初始化
    init: {
      type: Boolean,
      default: false
    },
    // 如果初始化--->是否初始化时一次性加载完
    initAll: {
      type: Boolean,
      default: false
    },
    // 选项 value  label字段
    selectValue: {
      type: String,
      default: 'value'
    },
    selectLabel: {
      type: String,
      default: 'label'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // 单字段不需要
    multipleColumns: {
      type: Array,
      default: []
    }
  },
  // 默认会有一个选项改变事件 change
  emits: ['update:modelValue', 'change', 'clear'],
  setup(props, {
    emit
  }) {
    const state = reactive({
      // 请求参数
      queryForm: {
        pageNum: 1,
        pageSize: props.pageSize
      },
      modelValue: null,
      // 请求地址
      action: '',
      value: null,
      options: [],
      loading: false,
      // 是否阻断请求
      request: false,
      // 选项已选择
      select: false,
      // 失焦
      blurFlag: false,
      key: '',
      lastKey: '',
      // 当前请求重试次数
      retryCount: 0
    });

    // 输入框值改变进行远程搜索请求
    const remoteMethod = val => {
      // 清除空格
      state.key = trimAll(val);
      // 如果本次请求参数与上次一致 无效请求
      if (state.lastKey === state.key) {
        return;
      } else {
        state.queryForm.pageNum = 1;
      }
      // 点击选择后 自动清除关键字导致请求
      if (state.select) return;
      if (state.key === '') {
        if (state.blurFlag) {
          // 有搜索 但未选择后失焦导致搜索内容清空
          state.key = state.lastKey;
          state.blurFlag = false;
          return;
        }
        if (state.key === '') {
          state.options = [];
          return;
        }
      }
      // 值重新改变后重新开始
      if (state.request) state.queryForm.pageNum = 1;
      // 值改变 解除上次请求加载完所有数据阻止无效请求标记
      state.request = false;
      load(true);
    };

    // 选中某条数据
    const selectChange = val => {
      // 进行选择数据标记  以阻止自动清除关键字引起的无效请求
      stopLittle();
      emit('update:modelValue', val);
      if (!props.multiple) {
        // 单选
        const item = state.options.find(item => item[props.selectValue] === val);
        if (!item) return;
        emit('change', item);
      } else {
        if (props.multipleColumns.length < 2) {
          emit('change', val);
          return;
        }
        let arr = [];
        state.options.forEach(item => {
          if (val.includes(item[props.selectValue])) {
            let obj = {};
            props.multipleColumns.forEach(v => {
              obj[v] = item[v];
            });
            arr.push(obj);
          }
        });
        emit('change', arr);
      }
    };

    // 阻止各种原因引起的值改变的无效请求
    const stopLittle = () => {
      state.select = true;
      setTimeout(() => {
        state.select = false;
      }, 500);
    };
    // 选项的手动清除
    const selectClear = () => {
      stopLittle();
      // 重置页码
      state.queryForm.pageNum = 1;
      emit('clear');
    };
    const focusEvent = () => {};
    const blurEvent = () => {
      if (state.key && !state.value) state.blurFlag = true;
    };
    const load = async flag => {
      if (state.request) return;
      // 记录本次请求参数最为历史
      state.lastKey = state.key;
      await getList(flag);
    };
    const scrollLoad = () => {
      load(false);
    };
    const dealRequest = async (params, flag) => {
      if (state.retryCount > props.retry) return;
      const {
        data,
        code
      } = await props.url(params);
      if (code === 200) {
        if (flag) state.options = [];
        // 请求成功且返回数据
        if (data.length > 0) {
          state.queryForm.pageNum++;
          state.options = [...state.options, ...data];
          if (data.length < state.queryForm.pageSize) state.request = true;
        } else {
          // 并无返回数据
          state.request = true;
        }
      } else {
        // 请求失效 进行重试  直到最大次数为止
        state.retryCount++;
        await dealRequest(params);
        if (flag) state.options = [];
      }
    };
    const getList = async flag => {
      state.loading = true;
      let params = {
        ...state.queryForm
      };
      // 存在附加信息
      if (props.extraInfo) {
        // 如果绑定有数据源  对数据赋值
        if (props.data) {
          for (const key in props.extraInfo) {
            params[key] = props.extraInfo[key] ? props.extraInfo[key] : props.data[key];
          }
        } else {
          // 无绑定数据源
          params = {
            ...params,
            ...props.extraInfo
          };
        }
      }
      // 第一次请求时  判断是否需要初始化以及初始化程度
      if (state.queryForm.pageNum === 1) {
        params.pageFlag = props.init ? !props.initAll : false;
        state.request = props.init ? props.initAll : false;
      }
      // 指定搜索字段
      params[props.column] = state.key;
      await dealRequest(params, flag);
      state.loading = false;
      setTimeout(() => {
        state.retryCount = 0;
      }, 500);
    };
    const clearData = () => {
      state.options = [];
      state.modelValue = null;
      state.value = null;
    };
    onMounted(() => {
      if (props.init) {
        load(true);
      }
    });
    return {
      ...toRefs(state),
      remoteMethod,
      selectChange,
      getList,
      selectClear,
      load,
      scrollLoad,
      focusEvent,
      blurEvent,
      clearData,
      props
    };
  }
});