import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const systemRouters: VabRouteRecord[] = [
  {
    path: '/system',
    name: 'System',
    component: Layout,
    meta: {
      title: '系统数据',
      icon: 'apps-line',
      guard: ['system'],
    },

    children: [
      {
        path: 'operationRecord',
        name: 'OperationRecord',
        meta: {
          title: '操作记录',
          icon: 'file-list-3-line',
          guard: ['system.operationData'],
        },
        children: [
          {
            path: 'updateRecord',
            name: 'UpdateRecord',
            component: () => import('@/views/data/updateRecord/index.vue'),
            meta: {
              title: '修改记录',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.updateRec'],
            },
          },
          {
            path: 'deleteRecord',
            name: 'DeleteRecord',
            component: () => import('@/views/data/deleteRecord/index.vue'),
            meta: {
              title: '删除记录',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.deleteRec'],
            },
          },
          {
            path: 'deleteOrderRecord',
            name: 'DeleteOrderRecord',
            component: () => import('@/views/data/updateOrderRecord/index.vue'),
            meta: {
              title: '删除订单',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.delOrderRec'],
            },
          },
          {
            path: 'deleteOrderRecordDetail',
            name: 'DeleteOrderRecordDetail',
            component: () =>
              import(
                '@/views/data/updateOrderRecord/deleteOrderRecordDetail.vue'
              ),
            meta: {
              title: '删除订单明细',
              // icon: 'mark-pen-line',
            // hidden: true,
              guard: ['system.operationData.delOrderProductRec'],
            },
          },
          {
            path: 'deleteDetail',
            name: 'DeleteDetail',
            component: () => import('@/views/data/updateOrderRecord/deleteDetail.vue'),
            meta: {
              title: '删除订单详情',
              // icon: 'mark-pen-line',
            hidden: true,
            // dynamicNewTab: true,
              // guard: ['data.deleteOrder'],
            },
          },
        ],
      },
         {
        path: 'printRecord',
        name: 'PrintRecord',
        meta: {
          title: '打印记录',
          icon: 'file-list-3-line',
          guard: ['system.operationData'],
        },
        children: [
          {
            path: 'shipmentPrint',
            name: 'ShipmentPrint',
            component: () => import('@/views/data/printRecord/shipmentPrint.vue'),
            meta: {
              title: '发货单打印',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.updateRec'],
            },
          },
          {
            path: 'salesProductCar',
            name: 'SalesProductCar',
            component: () => import('@/views/data/printRecord/salesProductCar.vue'),
            meta: {
              title: '装车单打印',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.updateRec'],
            },
          },
          {
            path: 'workProductPrint',
            name: 'WorkProductPrint',
            component: () => import('@/views/data/printRecord/workProductPrint.vue'),
            meta: {
              title: '工位分拣单',
              // icon: 'mark-pen-line',
              guard: ['system.operationData.updateRec'],
            },
          },
        ],
      },
      {
        path: 'systemCheck',
        name: 'SystemCheck',
        // components: () => import('@/views/setting/params/addMaterial.vue'),
        meta: {
          title: '系统校验',
          icon: 'list-settings-line',
          guard: ['setting.sysCheck'],
        },
        children: [
          {
            path: 'orderCheck',
            name: 'OrderCheck',
            component: () =>
              import('@/views/system/systemCheck/orderCheck.vue'),
            meta: {
              title: '订单校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.orderCheck'],
            },
          },
          {
            path: 'productCheck',
            name: 'ProductCheck',
            component: () =>
              import('@/views/system/systemCheck/productCheck.vue'),
            meta: {
              title: '成品校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.productCheck'],
            },
          },

          {
            path: 'salesOrderCheck',
            name: 'SalesOrderCheck',
            component: () =>
              import('@/views/system/systemCheck/salesOrderCheck.vue'),
            meta: {
              title: '销售数据校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.salesCheck'],
            },
          },

          {
            path: 'processOrderCheck',
            name: 'ProcessOrderCheck',
            component: () =>
              import('@/views/system/systemCheck/processOrderCheck.vue'),
            meta: {
              title: '加工数据校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.processCheck'],
            },
          },

          {
            path: 'pprocuredOrderCheck',
            name: 'PprocuredOrderCheck',
            component: () =>
              import('@/views/system/systemCheck/pprocuredOrderCheck.vue'),
            meta: {
              title: '外购数据校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.pprocuredCheck'],
            },
          },
          {
            path: 'productOutOrPutCheck',
            name: 'ProductOutOrPutCheck',
            component: () =>
              import('@/views/system/systemCheck/productOutOrPutCheck.vue'),
            meta: {
              title: '成品出入校验',
              // icon: 'delete-column',
              guard: ['setting.sysCheck.productOPCheck'],
            },
          },
          {
            path: 'workCheck',
            name: 'WorkCheck',
            component: () =>
              import('@/views/system/systemCheck/work/index.vue'),
            meta: {
              title: '生产校验',
              // icon: 'delete-column',
              // guard: ['setting.sysCheck.workCheck'],
            },
          },
          // {
          //   path: 'goodsCheck',
          //   name: 'GoodsCheck',
          //   component: () =>
          //     import('@/views/setting/systemCheck/goodsCheck.vue'),
          //   meta: {
          //     title: '配件校验',
          //     // icon: 'delete-column',
          //     // guard: ['setting.role.role'],
          //   },
          // },
          {
            path: 'materialCheck',
            name: 'MaterialCheck',
            meta: {
              title: '原料校验',
              // icon: 'delete-column',
              // guard: ['setting.sysCheck.productOPCheck'],
            },
            children:[
              {
                path: 'rawStoreCheck',
                name: 'RawStoreCheck',
                component: () =>
                  import('@/views/system/systemCheck/material/rawStore.vue'),
                meta: {
                  title: '原料库存校验',
                  // icon: 'delete-column',
                  // guard: ['setting.sysCheck.productOPCheck'],
                },
              },
              {
                path: 'materialProcuredCheck',
                name: 'MaterialProcuredCheck',
                component: () =>
                  import('@/views/system/systemCheck/material/materialProcured.vue'),
                meta: {
                  title: '原料采购校验',
                  // icon: 'delete-column',
                  // guard: ['setting.sysCheck.productOPCheck'],
                },
              },
              {
                path: 'rawBaseCheck',
                name: 'RawBaseCheck',
                component: () =>
                  import('@/views/system/systemCheck/material/rawBase.vue'),
                meta: {
                  title: '原纸库存校验',
                  // icon: 'delete-column',
                  // guard: ['setting.sysCheck.productOPCheck'],
                },
              },{
                path: 'baseProcuredCheck',
                name: 'BaseProcuredCheck',
                component: () =>
                  import('@/views/system/systemCheck/material/baseProcured.vue'),
                meta: {
                  title: '原纸采购校验',
                  // icon: 'delete-column',
                  // guard: ['setting.sysCheck.productOPCheck'],
                },
              },
            ]
          },
        ],
      },
    ],
  },
]
