import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const supplier: VabRouteRecord[] = [
  {
    path: '/supplier',
    name: 'Supplier',
    component: Layout,
    meta: {
      title: '供应管理',
      icon: 'hand-coin-line',
      guard: ['basic.supplier'],
    },
    children: [
      {
        path: 'supplier',
        name: 'Supplier',
        component: () => import('@/views/basic/supplier/index.vue'),
        meta: {
          title: '供应商管理',
          // icon: 'user-3-line',
          guard: ['basic.supplier.supplier'],
        },
      },
      {
        path: 'supplierDetail',
        name: 'SupplierDetail',
        component: () =>
          import('@/views/basic/supplier/components/supplierDetail.vue'),
        meta: {
          title: '供应商详情',
          // icon: 'user-3-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
