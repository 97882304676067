import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const work: VabRouteRecord[] = [
  {
    path: '/work',
    name: 'Work',
    component: Layout,
    meta: {
      title: '生产管理',
      icon: 'community-line',
      // noClosable: true,
      guard: ['workbenche.work'],
    },
    children: [
      {
        path: 'productionSchedule',
        name: 'ProductionSchedule',
        component: () => import('@/views/workbenche/work/workTask/schedule/productionSchedule.vue'),
        meta: {
          title: '排产计划',
          guard: ['workbenche.work.productionSchedule'],
        },
      },
      {
        path: 'stockSchedule',
        name: 'StockSchedule',
        component: () => import('@/views/workbenche/work/workTask/schedule/stockSchedule.vue'),
        meta: {
          title: '备货计划',
          guard: ['workbenche.work.stockSchedule'],
        },
      },
      {
        path: 'workInfo',
        name: 'WorkInfo',
        component: () => import('@/views/workbenche/work/workInfo/index.vue'),
        meta: {
          title: '机位管理',
          guard: ['workbenche.work.workinfo'],
        },
      },
      {
        path: 'steelPlateBulletin',
        name: 'SteelPlateBulletin',
        component: () => import('@/views/workbenche/work/steelPlate/steelPlateBulletin.vue'),
        meta: {
          title: '钢板看板',
          guard: ['workbenche.work.workinfo'],
        },
      },
      {
        path: 'steelPlate',
        name: 'SteelPlate',
        component: () => import('@/views/workbenche/work/steelPlate/index.vue'),
        meta: {
          title: '钢板管理',
          guard: ['workbenche.work.steelPlate'],
        },
      },
      {
        path: 'workDetails',
        name: 'WorkDetails',
        component: () => import('@/views/workbenche/work/workInfo/workDetails.vue'),
        meta: {
          title: '机位详情',
          hidden: true,
          guard: ['workbenche.work.workinfo.details'],
        },
      },
      {
        path: 'workWorker',
        name: 'WorkWorker',
        component: () => import('@/views/workbenche/work/workInfo/worker/workWorker.vue'),
        meta: {
          title: '生产工人',
          hidden: true,
          guard: ['workbenche.work.workinfo.details'],
        },
      },
      {
        path: 'workTaskCreate',
        name: 'WorkTaskCreate',
        component: () => import('@/views/workbenche/work/workTask/workTaskCreate.vue'),
        meta: {
          title: '新增任务',
          hidden: true,
          guard: ['workbenche.work.workTask.addSale','workbenche.work.workTask.addStock','workbenche.work.workTask.addProcess'],
        },
      },
      {
        path: 'workTaskCreateMany',
        name: 'WorkTaskCreateMany',
        component: () => import('@/views/workbenche/work/workTask/workTaskCreateMany.vue'),
        meta: {
          title: '多机排产',
          hidden: true,
          guard: ['workbenche.work.workTask.addSale','workbenche.work.workTask.addStock','workbenche.work.workTask.addProcess'],
        },
      },
      {
        path: 'workTaskMoveMany',
        name: 'WorkTaskMoveMany',
        component: () => import('@/views/workbenche/work/workTask/workTaskMoveMany.vue'),
        meta: {
          title: '多机转移',
          hidden: true,
          guard: ['workbenche.work.workTask.addSale','workbenche.work.workTask.addStock','workbenche.work.workTask.addProcess'],
        },
      },
      {
        path: 'workTaskDetails',
        name: 'WorkTaskDetails',
        component: () => import('@/views/workbenche/work/workTask/workTaskDetails.vue'),
        meta: {
          title: '任务详情',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskDetails'],
        },
      },
      {
        path: 'workTaskMove',
        name: 'WorkTaskMove',
        component: () => import('@/views/workbenche/work/workTask/workTaskMove.vue'),
        meta: {
          title: '任务转移',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskMove'],
        },
      },
      {
        path: 'workTaskUpdate',
        name: 'WorkTaskUpdate',
        component: () => import('@/views/workbenche/work/workTask/workTaskUpdate-new.vue'),
        meta: {
          title: '编辑任务',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskUpdate'],
        },
      },
      // {
      //   path: 'workTaskUpdate',
      //   name: 'WorkTaskUpdate',
      //   component: () => import('@/views/workbenche/work/workTask/workTaskUpdate.vue'),
      //   meta: {
      //     title: '编辑任务',
      //     hidden: true,
      //     guard: ['workbenche.work.workTask.workTaskUpdate'],
      //   },
      // },
      {
        path: 'workTaskSumbit',
        name: 'WorkTaskSumbit',
        component: () => import('@/views/workbenche/work/workTask/product/workTaskSumbit.vue'),
        meta: {
          title: '提交任务',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskSumbit'],
        },
      },
      {
        path: 'workTaskSumbitUpdate',
        name: 'WorkTaskSumbitUpdate',
        component: () => import('@/views/workbenche/work/workTask/product/workTaskSumbitUpdate.vue'),
        meta: {
          title: '修改生产产品',
          hidden: true,
          guard: ['workbenche.product.workProduct'],
        },
      },
      {
        path: 'workTaskSumbitDefec',
        name: 'WorkTaskSumbitDefec',
        component: () => import('@/views/workbenche/work/workTask/product/workTaskSumbitDefec.vue'),
        meta: {
          title: '提交次品',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskSumbitDefec'],
        },
      },
      {
        path: 'workTaskSumbitAmend',
        name: 'WorkTaskSumbitAmend',
        component: () => import('@/views/workbenche/work/workTask/product/workTaskSumbitAmend.vue'),
        meta: {
          title: '生产更正',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskSumbitAmend'],
        },
      },
      {
        path: 'workTaskSumbitDefecUpdate',
        name: 'WorkTaskSumbitDefecUpdate',
        component: () => import('@/views/workbenche/work/workTask/product/workTaskSumbitDefecUpdate.vue'),
        meta: {
          title: '修改生产次品',
          hidden: true,
          guard: ['workbenche.product.workProductSem.update'],
        },
      },
      {
        path: 'workTaskMaterialOutApply',
        name: 'WorkTaskMaterialOutApply',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialOutApply.vue'),
        meta: {
          title: '原料申请',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskMaterialOutApply'],
        },
      },
      {
        path: 'workTaskMaterialOutApplyUpdate',
        name: 'WorkTaskMaterialOutApplyUpdate',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialOutApplyUpdate.vue'),
        meta: {
          title: '修改原料申请',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'workTaskMaterialLossApply',
        name: 'WorkTaskMaterialLossApply',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialLossApply.vue'),
        meta: {
          title: '原料损耗',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskMaterialLossApply'],
        },
      },
      {
        path: 'workTaskMaterialLossApplyUpdate',
        name: 'WorkTaskMaterialLossApplyUpdate',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialLossApplyUpdate.vue'),
        meta: {
          title: '修改原料损耗',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'workTaskMaterialPutApply',
        name: 'WorkTaskMaterialPutApply',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialPutApply.vue'),
        meta: {
          title: '原料归还',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskMaterialPutApply'],
        },
      },
      {
        path: 'workTaskMaterialPutApplyUpdate',
        name: 'WorkTaskMaterialPutApplyUpdate',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialPutApplyUpdate.vue'),
        meta: {
          title: '修改原料归还',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'workTaskMaterialCloseApply',
        name: 'WorkTaskMaterialCloseApply',
        component: () => import('@/views/workbenche/work/workTask/material/workTaskMaterialCloseApply.vue'),
        meta: {
          title: '任务终止',
          hidden: true,
          guard: ['workbenche.work.workTask.workTaskClose'],
        },
      },
      {
        path: 'workTaskDetail',
        name: 'WorkTaskDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskDetail.vue'),
        meta: {
          title: '任务明细',
          guard: ['workbenche.work.workTask.workTaskDetail'],
        },
      },
      {
        path: 'workTaskProductDetail',
        name: 'WorkTaskProductDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskProductDetail.vue'),
        meta: {
          title: '生产明细',
          guard: ['workbenche.work.workTask.workTaskProductDetail'],
        },
      },
      {
        path: 'workTaskMaterialLack',
        name: 'WorkTaskMaterialLack',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskMaterialLack.vue'),
        meta: {
          title: '生产缺货',
          guard: ['workbenche.work.workTask.workTaskMaterialDetail'],
        },
      },
      {
        path: 'workTaskMaterialDetail',
        name: 'WorkTaskMaterialDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskMaterialDetail.vue'),
        meta: {
          title: '生产原料',
          guard: ['workbenche.work.workTask.workTaskMaterialDetail'],
        },
      },
      {
        path: 'workTaskProductFinishDetail',
        name: 'WorkTaskProductFinishDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskProductFinishDetail.vue'),
        meta: {
          title: '完成产品',
          guard: ['workbenche.work.workTask.workTaskProductFinishDetail'],
        },
      },
      {
        path: 'workMoney',
        name: 'workMoney',
        meta: {
          title: '生产计件',
          guard: ['workbenche.work.workTask.workTaskProductFinishDetail'],
        },
        children: [
          {
            path: 'workerFinishDetail',
            name: 'WorkerFinishDetail',
            component: () =>
              import('@/views/workbenche/work/workMoney/workerFinishDetail.vue'),
            meta: {
              title: '计件明细',
              guard: ['workbenche.product.product'],
            },
          },
          {
            path: 'workerFinishSummary',
            name: 'WorkerFinishSummary',
            component: () =>
              import('@/views/workbenche/work/workMoney/workerFinishSummary.vue'),
            meta: {
              title: '计件汇总',
              guard: ['workbenche.product.product'],
            },
          },
          {
            path: 'workerFinishMoney',
            name: 'WorkerFinishMoney',
            component: () =>
              import('@/views/workbenche/work/workMoney/workerFinishMoney.vue'),
            meta: {
              title: '工资提成',
              guard: ['workbenche.product.product'],
            },
          },
        ]
      },
      {
        path: 'workTaskProductDefecDetail',
        name: 'WorkTaskProductDefecDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskProductDefecDetail.vue'),
        meta: {
          title: '次品明细',
          guard: ['workbenche.work.workTask.workTaskMaterialUseDetail'],
        },
      },
      {
        path: 'workTaskMaterialUseDetail',
        name: 'WorkTaskMaterialUseDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskMaterialUseDetail.vue'),
        meta: {
          title: '使用原料',
          guard: ['workbenche.work.workTask.workTaskMaterialUseDetail'],
        },
      },
      {
        path: 'workTaskMaterialLossDetail',
        name: 'WorkTaskMaterialLossDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskMaterialLossDetail.vue'),
        meta: {
          title: '损耗原料',
          guard: ['workbenche.work.workTask.workTaskMaterialUseDetail'],
        },
      },
      {
        path: 'workTaskMaterialLossReturnDetail',
        name: 'WorkTaskMaterialLossReturnDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskMaterialLossReturnDetail.vue'),
        meta: {
          title: '损耗退货',
          guard: ['workbenche.work.workTask.workTaskMaterialUseDetail'],
        },
      },
      {
        path: 'workTaskPrintDetail',
        name: 'WorkTaskPrintDetail',
        component: () => import('@/views/workbenche/work/workTask/detail/workTaskPrintDetail.vue'),
        meta: {
          title: '打印记录',
          guard: ['workbenche.work.workTask.workTaskPrintDetail'],
        },
      },
      {
        path: 'workTaskProcessSet',
        name: 'WorkTaskProcessSet',
        component: () => import('@/views/workbenche/work/workTask/process/workTaskProcessSet.vue'),
        meta: {
          title: '工序设置',
          guard: ['workbenche.work.workTaskProcessSet'],
        },
      },
    ],
  },
]
