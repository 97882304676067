import type { App, DirectiveBinding } from 'vue'
import { hasPermission } from '@/utils/permission'
import { VNode } from 'vue'
import {gp} from "@gp";

export default {
  install(app: App<Element>) {
    /**
     * @description 自定义指令v-permissions
     */
    app
      .directive('permissions', {
        mounted(el: any, binding: DirectiveBinding) {
          const { value } = binding
          if (value)
            if (!hasPermission(value))
              el.parentNode && el.parentNode.removeChild(el)
        },
      })
      .directive('copy', {
        mounted(el: any, binding: DirectiveBinding, vnode: VNode) {
          const value=el.innerText
          if (!value)return
          el.addEventListener('contextmenu', function(event) {
            event.preventDefault();
            let inputs=document.createElement("input")     //创建节点
            inputs.value=value //给节点赋值
            document.body.appendChild(inputs) //渲染节点(要不然不起作用,可以添加隐藏属性)
            inputs.select() //选中节点
            console.log(">>>sl:",el.innerText)
            let  actions =  document.execCommand("Copy",false,el.innerText)
            gp.$baseMessage(
              '复制成功',
              'success',
              'vab-hey-message-success'
            )
            document.body.removeChild(inputs);
            el.removeEventListener('contextmenu',null)
          });
        },
      })
      .directive('stop',{
        updated:function (el:HTMLElement) {
          el.addEventListener('mousewheel',() => {
            const elem:any = el.tagName === 'INPUT' ? el : el.querySelector('input');
            elem.blur();
          })
        }
      })
  },
}
