import {VabRouteRecord} from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const procured: VabRouteRecord[] = [
  {
    path: '/procured',
    name: 'Procured',
    component: Layout,
    meta: {
      title: '采购管理',
      icon: 'install-line',
      // noClosable: true,
      guard: ['workbenche.procured'],
    },
    children: [
      {
        path: 'procuredMaterial',
        name: "ProcuredMaterial",
        meta: {
          title: "原料",
          icon: 'notification-badge-line',
          guard: ['workbenche.procured.material'],
        },
        children: [
          {
            path: 'plan',
            name: 'Plan',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/plan.vue'),
            meta: {
              title: '采购计划',
              guard: ['workbenche.procured.material.plan'],
            }
          },
          {
            path: 'procured',
            name: 'MaterialProcured',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/index.vue'),
            meta: {
              title: '原料采购',
              guard: ['workbenche.procured.material.procured'],
            }
          },
          {
            path: 'addMaterialProcured',
            name: 'AddMaterialProcured',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/components/AddMaterialProcured.vue'),
            meta: {
              title: '新增原料采购',
              hidden:true
            }
          },
          {
            path: 'editMaterialProcured',
            name: 'EditMaterialProcured',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/components/EditMaterialProcured.vue'),
            meta: {
              title: '编辑原料采购',
              hidden:true
            }
          },
          {
            path: 'procuredDetail',
            name: 'ProcuredMaterialDetail',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/detail.vue'),
            meta: {
              title: '原料采购详情',
              hidden:true
            }
          },
          {
            path: 'receive',
            name: 'MaterialReceive',
            component: () => import('@/views/workbenche/procuredManagement/material/receive/index.vue'),
            meta: {
              title: '原料收货',
              hidden:false,
              guard: ['workbenche.procured.material.receive']
            }
          },
          {
            path: 'receiveDetail',
            name: 'MaterialReceiveDetail',
            component: () => import('@/views/workbenche/procuredManagement/material/receive/detail.vue'),
            meta: {
              title: '原料收货单详情',
              hidden:true
            }
          },
          {
            path: 'addMaterialReceive',
            name: 'AddMaterialReceive',
            component: () => import('@/views/workbenche/procuredManagement/material/receive/components/AddMaterialReceive.vue'),
            meta: {
              title: '新增原料收货',
              hidden:true
            }
          },
          {
            path: 'editMaterialReceive',
            name: 'EditMaterialReceive',
            component: () => import('@/views/workbenche/procuredManagement/material/receive/components/EditMaterialReceive.vue'),
            meta: {
              title: '编辑原料收货',
              hidden:true
            }
          },
          {
            path: 'return',
            name: 'MaterialReturn',
            component: () => import('@/views/workbenche/procuredManagement/material/return/index.vue'),
            meta: {
              title: '原料退货',
              hidden:false,
              guard: ['workbenche.procured.material.return'],
            }
          },
          {
            path: 'returnDetail',
            name: 'MaterialReturnDetail',
            component: () => import('@/views/workbenche/procuredManagement/material/return/detail.vue'),
            meta: {
              title: '原料退货单详情',
              hidden:true
            }
          },
          {
            path: 'procuredRecord',
            name: 'ProcuredMaterialRecord',
            component: () => import('@/views/workbenche/procuredManagement/material/procured/record.vue'),
            meta: {
              title: '采购明细',
              hidden:false,
              guard: ['workbenche.procured.material.procured.record']
            }
          },
          {
            path: 'receiveRecord',
            name: 'MaterialReceiveRecord',
            component: () => import('@/views/workbenche/procuredManagement/material/receive/record.vue'),
            meta: {
              title: '收货明细',
              hidden:false
            }
          },
          {
            path: 'returnRecord',
            name: 'MaterialReturnRecord',
            component: () => import('@/views/workbenche/procuredManagement/material/return/record.vue'),
            meta: {
              title: '退货明细',
              hidden:false,
              guard: ['workbenche.procured.material.return.record'],
            }
          },
          {
            path: 'addMaterialReturn',
            name: 'AddMaterialReturn',
            component: () => import('@/views/workbenche/procuredManagement/material/return/components/AddMaterialReturn.vue'),
            meta: {
              title: '新增原料退货',
              hidden:true
            }
          },
          {
            path: 'editMaterialReturn',
            name: 'EditMaterialReturn',
            component: () => import('@/views/workbenche/procuredManagement/material/return/components/EditMaterialReturn.vue'),
            meta: {
              title: '编辑原料退货',
              hidden:true
            }
          },
        ]
      },
      {
        path: 'procuredBase',
        name: "ProcuredBase",
        meta: {
          title: "原纸",
          icon: 'mastercard-line',
        },
        children: [
          {
            path: 'basePlan',
            name: 'BasePlan',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/plan.vue'),
            meta: {
              title: '采购计划',
              guard: ['workbenche.procured.base.plan'],
            }
          },
          {
            path: 'baseProcured',
            name: 'BaseProcured',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/index.vue'),
            meta: {
              title: '原纸采购',
              hidden:false,
              guard: ['workbenche.procured.base.procured'],
            }
          },
          {
            path: 'baseDetail',
            name: 'BaseDetail',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/detail.vue'),
            meta: {
              title: '原纸采购单详情',
              hidden:true
            }
          },
          {
            path: 'addBaseProcured',
            name: 'AddBaseProcured',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/components/AddBaseProcured.vue'),
            meta: {
              title: '新增原纸采购',
              hidden:true
            }
          },
          {
            path: 'editBaseProcured',
            name: 'EditBaseProcured',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/components/EditBaseProcured.vue'),
            meta: {
              title: '编辑原纸采购',
              hidden:true
            }
          },
          {
            path: 'baseReceive',
            name: 'BaseReceive',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/receive/index.vue'),
            meta: {
              title: '原纸收货',
              hidden:false,
              guard: ['workbenche.procured.base.receive'],
            }
          },
          {
            path: 'baseReceiveDetail',
            name: 'BaseReceiveDetail',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/receive/detail.vue'),
            meta: {
              title: '原纸收货单详情',
              hidden:true
            }
          },
         {
            path: 'addBaseReceive',
            name: 'AddBaseReceive',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/receive/components/AddBaseReceive.vue'),
            meta: {
              title: '新增原纸收货',
              hidden:true
            }
          },
          {
            path: 'editBaseReceive',
            name: 'EditBaseReceive',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/receive/components/EditBaseReceive.vue'),
            meta: {
              title: '编辑原纸收货',
              hidden:true
            }
          },
          {
            path: 'baseReturn',
            name: 'BaseReturn',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/return/index.vue'),
            meta: {
              title: '原纸退货',
              hidden:false,
              guard: ['workbenche.procured.base.return'],
            }
          },
          {
            path: 'baseReturnDetail',
            name: 'BaseReturnDetail',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/return/detail.vue'),
            meta: {
              title: '原纸退货单详情',
              hidden:true
            }
          },
          {
            path: 'baseRecord',
            name: 'BaseRecord',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/procured/record.vue'),
            meta: {
              title: '采购明细',
              hidden:false,
              guard: ['workbenche.procured.base.procured.record'],
            }
          },
          {
            path: 'baseReceiveRecord',
            name: 'BaseReceiveRecord',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/receive/record.vue'),
            meta: {
              title: '收货明细',
              hidden:false,
              guard: ['workbenche.procured.base.receive.record'],
            }
          },
          {
            path: 'baseReturnRecord',
            name: 'BaseReturnRecord',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/return/record.vue'),
            meta: {
              title: '退货明细',
              hidden:false,
              guard: ['workbenche.procured.base.return.record'],
            }
          },
          {
            path: 'addBaseReturn',
            name: 'AddBaseReturn',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/return/components/AddBaseReturn.vue'),
            meta: {
              title: '新增原纸退货',
              hidden:true
            }
          },
          {
            path: 'editBaseReturn',
            name: 'EditBaseReturn',
            component: () => import('@/views/workbenche/procuredManagement/rawBase/return/components/EditBaseReturn.vue'),
            meta: {
              title: '编辑原纸退货',
              hidden:true
            }
          },
        ]
      }
    ],
  },
]
