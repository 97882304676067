/* 防止偶发性自动导入失败 */
import { computed, defineComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useSettingsStore } from '@/store/modules/settings';
var imports = require.context('./components', true, /\.vue$/);
var Components = {};
imports.keys().forEach(function (key) {
  Components[key.replace(/(\/|\.|vue)/g, '')] = imports(key).default;
});
export default defineComponent({
  name: 'VabMenu',
  components: Components,
  props: {
    item: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      default: ''
    }
  },
  setup: function (props) {
    var settingsStore = useSettingsStore();
    var collapse = storeToRefs(settingsStore).collapse;
    var menuComponent = computed(function () {
      return props.item.children && props.item.children.some(function (_route) {
        return _route.meta.hidden !== true;
      }) ? 'VabSubMenu' : 'VabMenuItem';
    });
    return {
      collapse: collapse,
      menuComponent: menuComponent
    };
  }
});