/**
 * 工作流  审核设置
 */
import request from '@/utils/request'

/**
 *  获取工作流配置列表
 */
export function getWorkFlowConfList(data: any) {
  return request({
    url: `/system/workFlow/getWorkFlowConfList`,
    method: 'post',
    data,
  })
}

/**
 *  修改配置状态
 */
export function updateWorkFlowConfUsable(data: any) {
  return request({
    url: `/system/workFlow/updateWorkFlowConfUsable`,
    method: 'post',
    data,
  })
}

/**
 *  同步最新配置到未开始审核历史工作流记录
 */
export function refreshWorkFlowRecord(data: any) {
  return request({
    url: `/system/workFlow/refreshWorkFlowRecord`,
    method: 'post',
    data,
  })
}

/**
 *  修改配置信息
 */
export function updateWorkFlowConf(data: any) {
  return request({
    url: `/system/workFlow/updateWorkFlowConf`,
    method: 'post',
    data,
  })
}

/**
 *  获取单据审核节点
 */
export function getOrderApproveNodes(data: any) {
  return request({
    url: `/system/workFlow/getOrderApproveNodes`,
    method: 'post',
    data,
  })
}


/**
 *  节点审核
 */
export function approveNode(data: any) {
  return request({
    url: `/system/workFlow/approve`,
    method: 'post',
    data,
  })
}

/**
 *  撤销单据流程
 */
export function cancelWorkFlow(data: any) {
  return request({
    url: `/system/workFlow/cancelWorkFlow`,
    method: 'post',
    data,
  })
}

/**
 *  获取订单历史流程数据
 */
export function getHistoryFlow(data: any) {
  return request({
    url: `/system/workFlow/getHistoryFlow`,
    method: 'post',
    data,
  })
}
/**
 *  检查批量审核是否有审核权限
 */
export function checkAuditPermissionsWhenApproveBatch(data: any) {
  return request({
    url: `/system/workFlow/checkAuditPermissionsWhenApproveBatch`,
    method: 'post',
    data,
  })
}
/**
 *  获取订单历史流程数据
 */
export function approveBatch(data: any) {
  return request({
    url: `/system/workFlow/approveBatch`,
    method: 'post',
    data,
  })
}


