import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const sale: VabRouteRecord[] = [
  {
    path: '/salesManagement',
    name: 'SalesManagement',
    component: Layout,
    // redirect: '/salesManagement/salesOrder',
    // component: () =>
    //   import('@/views/workbenche/salesManagement/salesOrder/addMaterial.vue'),
    meta: {
      title: '销售管理',
      icon: 'exchange-cny-fill',
      // noClosable: true,
      guard: ['workbenche.sales'],
    },
    children: [
      // {
      //   path: 'salesPriceOrder',
      //   name: 'salesPriceOrder',
      //   component: () =>
      //     import(
      //       '@/views/workbenche/salesManagement/salesPriceOrder/rawStore.vue'
      //     ),
      //   meta: {
      //     title: '报价订单',
      //     // icon: 'home-2-line',,
      //     // noClosable: true,
      //     // guard: ['Admin'],
      //   },
      // },
      // {
      //   path: 'salesPriceOrder/salesPriceDetail',
      //   name: 'SalesPriceDetail',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesPriceOrder/salesPriceDetail.vue'
      //     ),
      //   meta: {
      //     title: '报价单详情',
      //     // icon: 'home-2-line',,
      //     hidden: true,
      //     dynamicNewTab: true,
      //     // guard: ['Admin'],
      //   },
      // },
      // {
      //   path: 'salesPriceOrder/updateSalesPrice',
      //   name: 'UpdateSalesPrice',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesPriceOrder/updateSalesPrice.vue'
      //     ),
      //   meta: {
      //     title: '编辑报价单',
      //     // icon: 'home-2-line',,
      //     dynamicNewTab: true,
      //     hidden: true,
      //     // guard: ['Admin'],
      //   },
      // },
      // {
      //   path: 'salesPriceOrder/salesPriceProductDetail',
      //   name: 'SalesPriceProductDetail',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesPriceOrder/salesPriceProductDetail.vue'
      //     ),
      //   meta: {
      //     title: '报价明细',
      //     // icon: 'home-2-line',,
      //     // hidden: true,
      //     // guard: ['Admin'],
      //   },
      // },
      {
        path: 'salesOrder',
        name: 'SalesOrder',
        component: () =>
          import('@/views/workbenche/salesManagement/salesOrder/index.vue'),
        meta: {
          title: '销售订单',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.sales'],
        },
      },
      {
        path: 'salesOrder/salesDetail',
        name: 'SalesDetail',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/salesDetail.vue'
          ),
        meta: {
          title: '销售单详情',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'salesOrder/createSales',
        name: 'CreateSales',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/createSales.vue'
          ),
        meta: {
          title: '新建销售单',
          // icon: 'home-2-line',,
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'salesOrder/updateSales',
        name: 'UpdateSales',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/updateSales.vue'
          ),
        meta: {
          title: '编辑销售单',
          // icon: 'home-2-line',,
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'salesOrder/createNewSalesProduct',
        name: 'CreateNewSalesProduct',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/newSalesProduct/createNewSalesProduct.vue'
          ),
        meta: {
          title: '自建销售单',
          // icon: 'home-2-line',,
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'salesOrder/updateNewSalesProduct',
        name: 'UpdateNewSalesProduct',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/newSalesProduct/updateNewSalesProduct.vue'
          ),
        meta: {
          title: '编辑自建销售单',
          // icon: 'home-2-line',,
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      // {
      //   path: 'salesOrder/createSalesProduct',
      //   name: 'CreateSalesProduct',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesOrder/CreateSalesProduct.vue'
      //     ),
      //   meta: {
      //     title: '组合销售单',
      //     // icon: 'home-2-line',,
      //     // hidden: true,
      //     // guard: ['Admin'],
      //   },
      // },
      // {
      //   path: 'salesOrder/updateSalesProduct',
      //   name: 'UpdateSalesProduct',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesOrder/UpdateSalesProduct.vue'
      //     ),
      //   meta: {
      //     title: '编辑组合销售单',
      //     // icon: 'home-2-line',,
      //     dynamicNewTab: true,
      //     // hidden: true,
      //     // guard: ['Admin'],
      //   },
      // },
      {
        path: 'shipmentOrder',
        name: 'ShipmentOrder',
        component: () =>
          import('@/views/workbenche/salesManagement/shipmentOrder/index.vue'),
        meta: {
          title: '发货订单',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipment'],
        },
      },

      {
        path: 'shipmentOrder/shipmentsDetail',
        name: 'ShipmentsDetail',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/shipmentsDetail.vue'
          ),
        meta: {
          title: '发货详情',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'shipmentOrder/createShipments',
        name: 'CreateShipments',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/createShipments.vue'
          ),
        meta: {
          title: '新建发货',
          // icon: 'home-2-line',,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },


      {
        path: 'shipmentOrder/updateShipments',
        name: 'UpdateShipments',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/updateShipments.vue'
          ),
        meta: {
          title: '编辑发货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'shipReturnOrder',
        name: 'ShipReturnOrder',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipReturnOrder/index.vue'
          ),
        meta: {
          title: '退货订单',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipReturn'],
        },
      },
      {
        path: 'shipReturnOrder/shipReturnDetail',
        name: 'ShipReturnDetail',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipReturnOrder/shipReturnDetail.vue'
          ),
        meta: {
          title: '退货详情',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'shipReturnOrder/createShipReturn',
        name: 'CreateShipReturn',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipReturnOrder/createShipReturn.vue'
          ),
        meta: {
          title: '新建退货',
          // icon: 'home-2-line',,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'shipReturnOrder/updateShipReturn',
        name: 'UpdateShipReturn',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipReturnOrder/updateShipReturn.vue'
          ),
        meta: {
          title: '编辑退货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'salesOrder/salesProductDetail',
        name: 'SalesProductDetail',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/salesOrder/salesProductDetail.vue'
          ),
        meta: {
          title: '销售明细',
          // icon: 'home-2-line',,
          // hidden: true,
          guard: ['workbenche.sales.salesDetail'],
        },
      },
      {
        path: 'shipmentOrder/shipmentsProductDetail',
        name: 'ShipmentsProductDetail',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/shipmentsProductDetail.vue'
          ),
        meta: {
          title: '发货明细',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipmentDetail'],
        },
      },
      {
        path: 'shipReturnOrder/shipReturnProductDetail',
        name: 'ShipReturnProductDetail',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipReturnOrder/shipReturnProductDetail.vue'
          ),
        meta: {
          title: '退货明细',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipReturnDetail'],
        },
      },
      {
        path: 'shipmentOrder/shipmentApplyDisDetail',
        name: 'ShipmentApplyDisDetail',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/shipmentApplyDisDetail.vue'
          ),
        meta: {
          title: '运输明细',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipmentApplyDisDetail'],
        },
      },
      {
        path: 'shipmentOrder/shipmentApplyDisHuiZong',
        name: 'ShipmentApplyDisHuiZong',
        component: () =>
          import(
            '@/views/workbenche/salesManagement/shipmentOrder/shipmentApplyDisHuiZong.vue'
          ),
        meta: {
          title: '运输汇总',
          // icon: 'home-2-line',,
          // noClosable: true,
          guard: ['workbenche.sales.shipmentApplyDisHuiZong'],
        },
      },
      // {
      //   path: 'salesOrder/salesTakingDetail',
      //   name: 'SalesTakingDetail',
      //   component: () =>
      //     import(
      //       '~/src/views/workbenche/salesManagement/salesOrder/salesTakingDetail.vue'
      //     ),
      //   meta: {
      //     title: '接单明细',
      //     // icon: 'home-2-line',,
      //     // hidden: true,
      //     // guard: ['Admin'],
      //   },
      // },
      {
        path: 'wanNengShipmentOrder',
        name: 'WanNengShipmentOrder',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipmentOrder/index.vue'
          ),
        meta: {
          title: '万能发货',
          // icon: 'home-2-line',,
          // hidden: true,
          guard: ['workbenche.sales.wanNengShipment'],
        },
      },
      {
        path: 'wanNengShipmentOrder/createWanNengShipment',
        name: 'CreateWanNengShipment',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipmentOrder/createWanNengShipment.vue'
          ),
        meta: {
          title: '新增万能发货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengShipmentOrder/updateWanNengShipment',
        name: 'UpdateWanNengShipment',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipmentOrder/updateWanNengShipment.vue'
          ),
        meta: {
          title: '编辑万能发货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengShipReturnOrder',
        name: 'WanNengShipReturnOrder',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipReturnOrder/index.vue'
          ),
        meta: {
          title: '万能退货',
          // icon: 'home-2-line',,
          // hidden: true,
          guard: ['workbenche.sales.wanNengShipReturn'],
        },
      },
      {
        path: 'wanNengShipReturnOrder/createWanNengShipReturn',
        name: 'CreateWanNengShipReturn',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipReturnOrder/createWanNengShipReturn.vue'
          ),
        meta: {
          title: '新增万能退货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengShipReturnOrder/updateWanNengShipReturn',
        name: 'UpdateWanNengShipReturn',
        component: () =>
          import(
            '~/src/views/workbenche/salesManagement/wanNengShipReturnOrder/updateWanNengShipReturn.vue'
          ),
        meta: {
          title: '编辑万能退货',
          // icon: 'home-2-line',,
          hidden: true,
          // dynamicNewTab: true,
          // hidden: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
