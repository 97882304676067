import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

import { useVModel } from '@vueuse/core';
import { ref } from 'vue';
const _withScopeId = n => (_pushScopeId("data-v-139bdf98"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-dialog"
};
const _hoisted_2 = {
  class: "el-dialog__title"
};
const _hoisted_3 = {
  class: "vab-dialog__headerbtn"
};
export default {
  __name: 'index',
  props: {
    appendToBody: {
      type: Boolean,
      default: false
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: '50%'
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    },
    showFullscreen: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const dialogVisible = useVModel(props, 'modelValue', emit);
    const isFullscreen = ref(false);
    const closeDialog = () => {
      dialogVisible.value = false;
    };
    const setFullscreen = () => {
      isFullscreen.value = !isFullscreen.value;
    };
    return (_ctx, _cache) => {
      const _component_vab_icon = _resolveComponent("vab-icon");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, _mergeProps({
        modelValue: _unref(dialogVisible),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(dialogVisible) ? dialogVisible.value = $event : null)
      }, _ctx.$attrs, {
        "append-to-body": __props.appendToBody,
        draggable: __props.draggable,
        fullscreen: _unref(isFullscreen),
        "lock-scroll": __props.lockScroll,
        "show-close": false,
        width: __props.width
      }), {
        header: _withCtx(() => [_renderSlot(_ctx.$slots, "header", {}, () => [_createElementVNode("span", _hoisted_2, _toDisplayString(__props.title), 1)]), _createElementVNode("div", _hoisted_3, [__props.showFullscreen ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          "aria-label": "fullscreen",
          type: "button",
          onClick: setFullscreen
        }, [_unref(isFullscreen) ? (_openBlock(), _createBlock(_component_vab_icon, {
          key: 0,
          icon: "fullscreen-exit-line"
        })) : (_openBlock(), _createBlock(_component_vab_icon, {
          key: 1,
          icon: "fullscreen-line"
        }))])) : _createCommentVNode("", true), __props.showClose ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          "aria-label": "close",
          type: "button",
          onClick: closeDialog
        }, [_createVNode(_component_vab_icon, {
          icon: "close-circle-line"
        })])) : _createCommentVNode("", true)])]),
        footer: _withCtx(() => [_renderSlot(_ctx.$slots, "footer")]),
        default: _withCtx(() => [_withDirectives((_openBlock(), _createElementBlock("div", null, [_renderSlot(_ctx.$slots, "default")])), [[_directive_loading, __props.loading]])]),
        _: 3
      }, 16, ["modelValue", "append-to-body", "draggable", "fullscreen", "lock-scroll", "width"])]);
    };
  }
};