import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const user: VabRouteRecord[] = [
  {
    path: '/user',
    name: 'User',
    component: Layout,
    meta: {
      title: '公司管理',
      icon: 'community-line',
      guard: ['basic.user'],
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/basic/user/index.vue'),
        meta: {
          title: '员工管理',
          guard: ['basic.user.user'],
        },
      },
      {
        path: 'userDept',
        name: 'UserDept',
        component: () => import('@/views/basic/userDept/index.vue'),
        meta: {
          title: '部门管理',
          guard: ['basic.user.dept'],
        },
      },
      {
        path: 'userDetail',
        name: 'UserDetail',
        component: () => import('@/views/basic/user/components/userDetail.vue'),
        meta: {
          title: '员工详情',
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
