import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["tabindex", "value", "label", "option"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  return _openBlock(), _createBlock(_component_el_dropdown_item, {
    command: {
      ..._ctx.props.item,
      key: _ctx.props.index
    },
    disabled: _ctx.props.disabled,
    divided: _ctx.props.divided
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: "item-name",
      tabindex: _ctx.props.index,
      value: _ctx.props.value,
      label: _ctx.props.label,
      option: _ctx.props.label
    }, [_renderSlot(_ctx.$slots, "label", {
      value: _ctx.props.item
    }, () => [_createElementVNode("span", null, _toDisplayString(_ctx.props.label), 1)])], 8, _hoisted_1)]),
    _: 3
  }, 8, ["command", "disabled", "divided"]);
}