export default {
  name: 'VabAnchor',
  props: {
    floorList: {
      type: Array,
      default: () => {
        return [{
          title: '锚点1'
        }, {
          title: '锚点2'
        }, {
          title: '锚点3'
        }, {
          title: '锚点4'
        }, {
          title: '锚点5'
        }];
      }
    }
  },
  data() {
    return {
      step: '0',
      scrolltop: 0,
      floorObject: {}
    };
  },
  watch: {
    scrolltop(val) {
      val += 200;
      const floorObject = this.floorObject;
      for (let i = 0; i <= this.floorList.length + 1; i++) {
        if (val > floorObject[`floor${i}`] && (val <= floorObject[`floor${parseInt(i + 1)}`] || val <= Infinity)) {
          this.step = `${i}`;
        }
      }
    }
  },
  mounted() {
    this.getFloorDistance();
    document.getElementById('app').addEventListener('scroll', () => {
      this.scrolltop = document.getElementById('app').scrollTop;
    });
  },
  methods: {
    handleClick({
      index
    }) {
      this.anchors(index);
    },
    anchors(item) {
      this.pulleyRoll(this.floorObject[`floor${item}`], this.scrolltop);
    },
    pulleyRoll(top, distance) {
      if (distance < top) {
        const smallInterval = (top - distance) / 50;
        let i = 0;
        const timer = setInterval(() => {
          i++;
          distance += smallInterval;
          document.getElementById('app').scrollTop = distance;
          if (i == 50) {
            clearInterval(timer);
          }
        }, 10);
      } else if (distance > top) {
        const smallInterval = (distance - top) / 50;
        let i = 0;
        const timer = setInterval(() => {
          i++;
          distance -= smallInterval;
          document.getElementById('app').scrollTop = distance;
          if (i == 50) {
            clearInterval(timer);
          }
        }, 10);
      }
    },
    getFloorDistance() {
      for (let i = 0; i < this.floorList.length; i++) {
        this.floorObject[`floor${i}`] = document.getElementsByClassName(`floor${i}`)[0].offsetTop;
      }
    }
  }
};