import { VabRouteRecord } from "/#/router";
import Layout from "@vab/layouts/index.vue";

export const material: VabRouteRecord[] = [
  {
    path: "/material",
    name: "Material",
    component: Layout,
    redirect: "/material/alias",
    meta: {
      title: "原料管理",
      icon: "database-2-line",
      // noClosable: true,
      guard: ["workbenche.material"]
    },
    children: [
      {
        path: "rawStore",
        name: "RawStore",
        component: () => import("@/views/workbenche/material/rawStore/index.vue"),
        meta: {
          title: "原料库存",
          guard: ["workbenche.material.store"]
        }
      },
      {
        path: "rawBase",
        name: "RawBase",
        component: () => import("@/views/workbenche/material/rawBase/index.vue"),
        meta: {
          title: "原纸库存",
          guard: ["workbenche.material.base"]
        }
      },
      // {
      //   path: 'alias',
      //   name: "Alias",
      //   meta: {
      //     title: "供商编号",
      //     icon: 'barcode-box-line',
      //     guard: ['workbenche.material.alias'],
      //   },
      //   children: [
      //     {
      //       path: 'aliases',
      //       name: 'Aliases',
      //       component: () => import('@/views/workbenche/material/alias/index.vue'),
      //       meta: {
      //         title: '供商编号',
      //         guard: ['workbenche.material.alias'],
      //       },
      //     },
      //     {
      //       path: 'aliasRecord',
      //       name: 'AliasRecord',
      //       component: () => import('@/views/workbenche/material/alias/record.vue'),
      //       meta: {
      //         title: '使用记录',
      //         guard: ['workbenche.material.alias.record'],
      //       },
      //     },
      //   ]
      // },
      {
        path: "addMaterial",
        name: "AddMaterial",
        component: () => import("@/views/workbenche/material/rawStore/components/addMaterial.vue"),
        meta: {
          title: "新增原料",
          hidden: true
        }
      },
      {
        path: "materialDetail",
        name: "MaterialDetail",
        component: () => import("@/views/workbenche/material/rawStore/components/materialDetail.vue"),
        meta: {
          title: "原料详情",
          hidden: true
        }
      },
      {
        path: "recentCreate",
        name: "RecentCreate",
        component: () => import("@/views/workbenche/material/rawStore/components/recentCreate.vue"),
        meta: {
          title: "近期新增",
          hidden: true
        }
      },
      {
        path: "batchCover",
        name: "BatchCover",
        component: () => import("@/views/workbenche/material/rawStore/components/BatchCover.vue"),
        meta: {
          title: "批次转移",
          hidden: true
        }
      },
      {
        path: "storeOutPut",
        name: "StoreOutPut",
        meta: {
          title: "出入库",
          icon: "arrow-left-right-fill",
          guard: ["workbenche.material.output"]
        },
        children: [
          {
            path: "manualPut",
            name: "ManualPut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/ManualPut.vue"),
            meta: {
              title: "原料入库",
              guard: ["workbenche.material.output.put"],
              hidden: true
            }
          },
          {
            path: "addManualPut",
            name: "AddManualPut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/components/AddManualPut.vue"),
            meta: {
              title: "新增原料入库",
              hidden: true
            }
          },
          {
            path: "editManualPut",
            name: "EditManualPut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/components/EditManualPut.vue"),
            meta: {
              title: "编辑原料入库",
              hidden: true
            }
          }, {
            path: "manualOut",
            name: "ManualOut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/ManualOut.vue"),
            meta: {
              title: "原料出库",
              guard: ["workbenche.material.output.out"],
              hidden: true
            }
          },
          {
            path: "addManualOut",
            name: "AddManualOut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/components/AddManualOut.vue"),
            meta: {
              title: "新增原料出库",
              hidden: true
            }
          }, {
            path: "editManualOut",
            name: "EditManualOut",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/components/EditManualOut.vue"),
            meta: {
              title: "编辑原料出库",
              hidden: true
            }
          },
          {
            path: "processPut",
            name: "ProcessPut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/ProcessPut.vue"),
            meta: {
              title: "加工入库",
              guard: ["workbenche.material.output.processPut"],
              hidden: true
            }
          },
          {
            path: "materialProcessOut",
            name: "MaterialProcessOut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/ProcessOut.vue"),
            meta: {
              title: "加工出库",
              guard: ["workbenche.material.output.processOut"],
              hidden: true
            }
          },
          {
            path: "addProcessPut",
            name: "AddProcessPut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/components/AddProcessPut.vue"),
            meta: {
              title: "新增加工入库",
              hidden: true
            }
          }, {
            path: "editProcessPut",
            name: "EditProcessPut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/components/EditProcessPut.vue"),
            meta: {
              title: "编辑加工入库",
              hidden: true
            }
          },
          {
            path: "addProcessOut",
            name: "AddProcessOut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/components/AddProcessOut.vue"),
            meta: {
              title: "新增加工出库",
              hidden: true
            }
          },
          {
            path: "editProcessOut",
            name: "EditProcessOut",
            component: () => import("@/views/workbenche/material/storeOutPut/process/components/EditProcessOut.vue"),
            meta: {
              title: "编辑加工出库",
              hidden: true
            }
          },
          {
            path: "manualRecord",
            name: "ManualRecord",
            component: () => import("@/views/workbenche/material/storeOutPut/manual/index.vue"),
            meta: {
              title: "出入库明细",
              hidden: false,
              guard: ["workbenche.material.output.manualRecord"]
            }
          },
          {
            path: "storeRecord",
            name: "StoreRecord",
            component: () => import("@/views/workbenche/material/storeOutPut/record/index.vue"),
            meta: {
              title: "出入库记录",
              hidden: false,
              guard: ["workbenche.material.output.record"]
            }
          },
          {
            path: "processMaterial",
            name: "ProcessMaterial",
            component: () => import("@/views/workbenche/material/storeOutPut/record/processMaterial.vue"),
            meta: {
              title: "加工原料明细",
              hidden: false,
              guard: ["workbenche.material.output.processMaterial"]
            }
          },
          {
            path: "materialLock",
            name: "MaterialLock",
            component: () => import("@/views/workbenche/material/storeOutPut/record/materialLockRecord.vue"),
            meta: {
              title: "原料锁定记录",
              hidden: false,
              guard: ["workbenche.material.output.lock"]
            }
          },
          {
            path: "addMaterialLock",
            name: "AddMaterialLock",
            component: () => import("@/views/workbenche/material/storeOutPut/record/components/AddMaterialLock.vue"),
            meta: {
              title: "新增原料锁定",
              hidden: true
            }
          }
        ]
      },
      {
        path: "processOp",
        name: "ProcessOp",
        meta: {
          title: "加工明细",
          icon: "align-justify",
          guard: ["workbenche.material.processOp"]
        },
        children: [
          {
            path: "processMaterialOpDetial",
            name: "ProcessMaterialOpDetial",
            component: () => import("@/views/workbenche/material/storeOutPut/record/processMaterialOpDetial.vue"),
            meta: {
              title: "加工明细",
              guard: ["workbenche.material.processOp.processMaterialOpDetial"]
            }
          },
          {
            path: "processMaterialOpHuiZong",
            name: "ProcessMaterialOpHuiZong",
            component: () => import("@/views/workbenche/material/storeOutPut/record/processMaterialOpHuiZong.vue"),
            meta: {
              title: "加工汇总",
              guard: ["workbenche.material.processOp.processMaterialOpHuiZong"]
            }
          }
        ]
      },
      {
        path: "check",
        name: "Check",
        meta: {
          title: "原料盘点",
          icon: "shield-check-line",
          guard: ["workbenche.material.check"]
        },
        children: [
          {
            path: "checkMaterial",
            name: "CheckMaterial",
            component: () => import("@/views/workbenche/material/rawCheck/checkMaterial.vue"),
            meta: {
              title: "盘点原料",
              guard: ["workbenche.material.check.material"]
            }
          },
          {
            path: "checkData",
            name: "CheckData",
            component: () => import("@/views/workbenche/material/rawCheck/checkData.vue"),
            meta: {
              title: "盘点数据",
              guard: ["workbenche.material.check.data"]
            }
          },
          {
            path: "addMaterialCheck",
            name: "AddMaterialCheck",
            component: () => import("@/views/workbenche/material/rawCheck/AddMaterialCheck.vue"),
            meta: {
              title: "新增盘点",
              hidden: true
            }
          }
        ]
      },
      {
        path: "aliases",
        name: "Aliases",
        component: () => import("@/views/workbenche/material/alias/index.vue"),
        meta: {
          title: "供商编号",
          guard: ["workbenche.material.alias"]
        }
      },
      {
        path: "aliasRecord",
        name: "AliasRecord",
        component: () => import("@/views/workbenche/material/alias/record.vue"),
        meta: {
          title: "使用记录",
          // guard: ["workbenche.material.alias.record"],
          hidden: true
        }
      },
      {
        path: "location",
        name: "Location",
        component: () => import("@/views/workbenche/material/location/index.vue"),
        meta: {
          title: "原料库位",
          guard: ["workbenche.material.rml"]
        }
      },
      {
        path: "addRawBase",
        name: "AddRawBase",
        component: () => import("@/views/workbenche/material/rawBase/components/AddRawBase.vue"),
        meta: {
          title: "新增原纸",
          hidden: true
        }
      },
      {
        path: "rawBaseDetail",
        name: "RawBaseDetail",
        component: () => import("@/views/workbenche/material/rawBase/components/baseDetail.vue"),
        meta: {
          title: "原纸详情",
          hidden: true
        }
      },
      {
        path: "materialLoss",
        name: "MaterialLoss",
        component: () => import("@/views/workbenche/material/rawLoss/index.vue"),
        meta: {
          title: "原料损耗",
          hidden: false,
          guard: ["workbenche.material.loss"]
        }
      },
      {
        path: "addMaterialLoss",
        name: "AddMaterialLoss",
        component: () => import("@/views/workbenche/material/rawLoss/AddMaterialLoss.vue"),
        meta: {
          title: "新增原料损耗",
          hidden: true
        }
      },
      {
        path: "materialCover",
        name: "MaterialCover",
        component: () => import("@/views/workbenche/material/rawCover/index.vue"),
        meta: {
          title: "原料转换",
          hidden: false,
          guard: ["workbenche.material.cover"]
        }
      },

      {
        path: "baseCover",
        name: "BaseCover",
        component: () => import("@/views/workbenche/material/baseCover/index.vue"),
        meta: {
          title: "原纸转移",
          hidden: false,
          guard: ["workbenche.material.baseCover"]
        }
      },
      {
        path: "addBaseCover",
        name: "AddBaseCover",
        component: () => import("@/views/workbenche/material/baseCover/components/AddBaseCover.vue"),
        meta: {
          title: "新增原纸转移",
          hidden: true
        }
      },
      {
        path: "addMaterialCover",
        name: "AddMaterialCover",
        component: () => import("@/views/workbenche/material/rawCover/AddMaterialCover.vue"),
        meta: {
          title: "新增原料转换",
          hidden: true
        }
      },
      {
        path: "expirePaper",
        name: "ExpirePaper",
        component: () => import("@/views/workbenche/material/expirePaper/index.vue"),
        meta: {
          title: "过期纸",
          hidden: false,
          guard: ["workbenche.material.expire"]
        }
      },
      {
        path: "workTaskMaterialOut",
        name: "WorkTaskMaterialOut",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialOut.vue"),
        meta: {
          title: "生产出库",
          guard: ["workbenche.material.productOut"]
        }
      }, {
        path: "workTaskMaterialOutDetails",
        name: "WorkTaskMaterialOutDetails",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialOutDetails.vue"),
        meta: {
          title: "生产出库详情",
          hidden: true
        }
      }, {
        path: "workTaskMaterialLoss",
        name: "WorkTaskMaterialLoss",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialLoss.vue"),
        meta: {
          title: "生产损耗",
          guard: ["workbenche.material.productLoss"]
        }
      }, {
        path: "workTaskMaterialLossDetails",
        name: "WorkTaskMaterialLossDetails",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialLossDetails.vue"),
        meta: {
          title: "生产损耗详情",
          hidden: true
        }
      }, {
        path: "workTaskMaterialPut",
        name: "WorkTaskMaterialPut",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialPut.vue"),
        meta: {
          title: "生产归还",
          guard: ["workbenche.material.productGui"]
        }
      }, {
        path: "workTaskMaterialPutDetails",
        name: "WorkTaskMaterialPutDetails",
        component: () => import("@/views/workbenche/material/workMaterial/workTaskMaterialPutDetails.vue"),
        meta: {
          title: "生产归还详情",
          hidden: true
        }
      },
      {
        path: 'process',
        name: "process",
        meta: {
          title: "委外出库",
          icon: 'logout-circle-r-line',
          guard: ['workbenche.material.processOut'],
        },
        children: [
          {
        path: 'processOut',
        name: 'ProcessOut',
        component: () => import('@/views/workbenche/material/processOut/processOut.vue'),
        meta: {
          title: '委外出库',
          guard: ['workbenche.material.processOut'],
        }
      },{
        path: 'processOutDetail',
        name: 'ProcessOutDetail',
        component: () => import('@/views/workbenche/material/processOut/processOutDetail.vue'),
        meta: {
          title: '委外出库详情',
          hidden: true
        }
      },{
        path: 'processMaterialApply',
        name: 'ProcessMaterialApply',
        component: () => import('@/views/workbenche/material/processOut/processMaterialApply.vue'),
        meta: {
          title: '委外申请',
          guard: ['workbenche.material.processOut'],
        }
      },{
        path: 'createProcessMaterial',
        name: 'CreateProcessMaterial',
        component: () => import('@/views/workbenche/material/processOut/createProcessMaterial.vue'),
        meta: {
          title: '新增申请',
          guard: ['workbenche.material.processOut'],
          hidden: true
        }
      },{
        path: 'updateProcessMaterial',
        name: 'UpdateProcessMaterial',
        component: () => import('@/views/workbenche/material/processOut/updateProcessMaterial.vue'),
        meta: {
          title: '修改申请',
          guard: ['workbenche.material.processOut'],
          hidden: true
        }
      }
        ]
      },
    ]
  }
];
