import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b69b9da0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "vab-anchor"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_vab_card = _resolveComponent("vab-card");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.floorList, (item, key) => {
    return _openBlock(), _createElementBlock("div", {
      key: key,
      class: _normalizeClass('floor' + key)
    }, [key === key ? _renderSlot(_ctx.$slots, 'floor' + key, {
      key: 0
    }, undefined, true) : _createCommentVNode("", true)], 2);
  }), 128)), _createVNode(_component_vab_card, {
    "body-style": {
      padding: '20px 10px 20px 10px'
    },
    shadow: "never",
    style: {
      "position": "fixed",
      "top": "170px",
      "right": "68px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tabs, {
      modelValue: $data.step,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.step = $event),
      "tab-position": "right",
      onTabClick: $options.handleClick
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.floorList, (item, key) => {
        return _openBlock(), _createBlock(_component_el_tab_pane, {
          key: key,
          label: item.title
        }, null, 8, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue", "onTabClick"])]),
    _: 1
  })]);
}