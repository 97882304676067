import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "vab-form-table"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_vab_query_form_top_panel = _resolveComponent("vab-query-form-top-panel");
  const _component_vab_query_form = _resolveComponent("vab-query-form");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_table = _resolveComponent("el-table");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_vab_query_form, null, {
    default: _withCtx(() => [_createVNode(_component_vab_query_form_top_panel, {
      span: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        icon: _ctx.Plus,
        type: "primary",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.handleAdd($event))
      }, {
        default: _withCtx(() => [_createTextVNode(" 添加 ")]),
        _: 1
      }, 8, ["icon"])]),
      _: 1
    })]),
    _: 1
  }), (_openBlock(), _createBlock(_component_el_table, {
    key: _ctx.toggleIndex,
    ref: "tableRef",
    border: "",
    data: _ctx.data
  }, {
    empty: _withCtx(() => [_createVNode(_component_el_empty, {
      class: "vab-data-empty",
      description: "暂无数据"
    })]),
    default: _withCtx(() => [_ctx.drag ? (_openBlock(), _createBlock(_component_el_table_column, {
      key: 0,
      align: "center",
      label: "操作",
      width: "120"
    }, {
      default: _withCtx(() => [_createVNode(_component_vab_icon, {
        class: "vab-rank",
        icon: "drag-move-2-line",
        style: {
          "cursor": "move"
        }
      })]),
      _: 1
    })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _createVNode(_component_el_table_column, {
      align: "center",
      label: "操作",
      width: "120"
    }, {
      default: _withCtx(({
        $index,
        row
      }) => [_createVNode(_component_el_button, {
        icon: _ctx.Delete,
        plain: "",
        type: "danger",
        onClick: $event => _ctx.handleDelete(row, $index)
      }, {
        default: _withCtx(() => [_createTextVNode(" 删除 ")]),
        _: 2
      }, 1032, ["icon", "onClick"])]),
      _: 1
    })]),
    _: 3
  }, 8, ["data"]))]);
}