import { createApp } from 'vue'
import App from './App.vue'
import { setupVab } from '~/library'
import { setupI18n } from '@/i18n'
import { setupStore } from '@/store'
import { setupRouter } from '@/router'
import TableHead from '@/plugins/ApeTableHead/index.vue'
import ApeUsable from '@/plugins/ApeUsable/index.vue'
import DateSelect from '@/plugins/ApeDateSelect/index.vue'
import ApeDialog from '@/plugins/ApeDialog/index.vue'
import ApeDate from '@/plugins/ApeDate/index.vue'
import ApeAudit from '@/plugins/ApeAudit/index.vue'
import ApeText from '@/plugins/ApeText/index.vue'
// import ApeSelect from '@/plugins/ApeSelect/rawStore.vue'

/**
 * @description 正式环境默认使用mock，正式项目记得注释后再打包
 */
import { baseURL, pwa } from './config'
import { isExternal } from '@/utils/validate'
import { PARAMETER, STATIC_VARIABLE } from '@/enums/constant'
const app = createApp(App)

/**
 * 输入框仅限数组指令
 */
import inputUtils from '@/utils/inputUtils'
app.use(inputUtils)

if (!localStorage.getItem('excelExport')) {
  localStorage.setItem('excelExport', JSON.stringify({}))
}

if (process.env.NODE_ENV === 'production' && !isExternal(baseURL)) {
  const { mockXHR } = require('@/utils/static')
  mockXHR()
}

import { globalRegister } from './global'
// 全局注册(默认会将app传递给globalRegister作为参数)
app.use(globalRegister)

/**
 * @description 生产环境启用组件初始化，编译，渲染和补丁性能跟踪。仅在开发模式和支持 Performance.mark API的浏览器中工作。
 */
//if (process.env.NODE_ENV === 'development') app.config.performance = true

if (pwa) require('./registerServiceWorker')
setupVab(app)
setupI18n(app)
setupStore(app)

app.provide('PARAMETER', PARAMETER)
app.provide('CONST', STATIC_VARIABLE)

setupRouter(app)
  .isReady()
  .then(() => app.mount('#app'))
//注册全局组件
app.component('TableHead', TableHead)
app.component('ApeUsable', ApeUsable)
app.component('DateSelect', DateSelect)
app.component('ApeDialog', ApeDialog)
app.component('ApeDate', ApeDate)
app.component('ApeAudit', ApeAudit)
app.component('ApeText', ApeText)
// app.component('ApeSelect', ApeSelect)
