/* unplugin-vue-components disabled */import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"

import "./index.vue?vue&type=style&index=0&id=4320d961&lang=css"
/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=vue-filename-injector"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__