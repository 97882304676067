import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "text-align": "center",
    "margin": "0",
    "display": "flex",
    "align-items": "center"
  }
};
import { watch } from 'vue';
import { hasPermission } from '@/utils/permission';

import { useRouter } from 'vue-router';
import { inject, ref } from 'vue';
import { useClipboard } from '@vueuse/core';
export default {
  __name: 'index',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number
    },
    slotWidth: {
      type: Number,
      default: 0
    },
    buttonList: {
      type: Array
    },
    /**
     * 显示省略号
     */
    truncated: {
      type: Boolean,
      default: false
    },
    /**
     * 显示几个字
     */
    truncatedLeng: {
      type: Number,
      default: 10
    },
    /**
     * 是否可复制
     */
    copy: {
      type: Boolean,
      default: true
    },
    /**
     * dataValue key值
     */
    tag: {
      type: String
    },
    /**
     * 位置
     */
    placement: {
      type: String,
      default: 'right'
    },
    /**
     * 类型
     */
    type: {
      type: String,
      default: 'info'
    },
    /**
     * 	大小
     */
    size: {
      type: String,
      default: 'default'
    },
    /**
     * 文本内容
     */
    text: {
      type: String,
      default: ''
    },
    /**
     * margin
     */
    margin: {
      type: String,
      default: ''
    },
    /**
     * padding
     */
    padding: {
      type: String,
      default: ''
    },
    /**
     * 展示小手
     */
    xiaoshou: {
      type: Boolean,
      default: false
    },
    /**
     * 跳转链接
     */
    to: {
      type: String,
      default: ''
    },
    /**
     * 使用默认跳转样式
     */
    toStyle: {
      type: Boolean,
      default: true
    },
    /**
     * 携带参数
     */
    query: {
      type: Object,
      default: {}
    },
    /**权限 */
    permission: {
      type: String,
      default: ''
    }
  },
  emits: ['click', 'dblclick', 'rightClick', 'buttonClick'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const router = useRouter();
    const $baseMessage = inject('$baseMessage');
    const clipboard = useClipboard();
    //向父组件传递的函数

    // 定义组件的 props

    const visible = ref(true);

    //显示内容
    const showText = ref('');
    const showTruncated = ref(false);
    const popoverWidth = ref(550);
    /**
     * 初始化数据
     */
    const initData = value => {
      if (!props.truncated || value.length <= props.truncatedLeng) {
        showTruncated.value = false;
        showText.value = value;
        return;
      }
      showText.value = value.substring(0, props.truncatedLeng) + '...';
      showTruncated.value = true;
    };

    /**
     * 点击事件
     */
    const click = () => {
      if (props.to) {
        if (props.permission && !hasPermission([props.permission])) {
          $baseMessage('亲，你没有权限查看哦', 'warning', 'vab-hey-message-warning');
          return;
        }
        router.push({
          path: props.to,
          query: props.query
        });
      }
      emits('click');
    };

    /**
     * 右击事件
     */
    const rightClick = () => {
      if (props.copy) {
        toCopy();
      }
      emits('rightClick');
    };
    const toCopy = () => {
      try {
        clipboard.copy(props.text);
        $baseMessage('复制成功', 'success', 'vab-hey-message-success');
      } catch (e) {
        $baseMessage('复制失败，请重试', 'danger', 'vab-hey-message-danger');
      }
    };
    /**
     * 按钮点击
     */
    const buttonClick = item => {
      visible.value = false;
      emits('buttonClick', item, props.text);
    };

    /**
     * 双击事件
     */
    const dblclick = () => {
      emits('dblclick');
    };
    watch(() => props.text, newVal => {
      if (newVal) {
        initData(newVal);
      } else {
        showText.value = '';
      }
    }, {
      immediate: true,
      deep: true
    });
    watch(() => props.buttonList, newVal => {
      if (newVal && !props.width) {
        let permissionList = newVal.filter(e => hasPermission([e.permission]));
        popoverWidth.value = permissionList.length * 79 + (Number(props.slotWidth) || 0);
        console.log(popoverWidth.value, permissionList, props.slotWidth);
      }
    }, {
      immediate: true,
      deep: true
    });
    watch(() => props.width, newVal => {
      if (newVal) {
        popoverWidth.value = newVal;
      }
    });
    return (_ctx, _cache) => {
      const _component_el_text = _resolveComponent("el-text");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popover = _resolveComponent("el-popover");
      return _openBlock(), _createBlock(_component_el_popover, {
        placement: __props.placement,
        width: _unref(popoverWidth),
        trigger: "click",
        disabled: __props.disabled,
        effect: 'dark'
      }, {
        reference: _withCtx(() => [_createVNode(_component_el_text, {
          type: __props.type,
          style: _normalizeStyle({
            margin: __props.margin,
            padding: __props.padding
          }),
          tag: __props.tag,
          class: "text-primary navgation",
          onClick: _withModifiers(click, ["stop"]),
          onDblclick: _withModifiers(dblclick, ["stop"]),
          onContextmenu: _withModifiers(rightClick, ["prevent"])
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(showText) || '-'), 1)]),
          _: 1
        }, 8, ["type", "style", "tag", "onClick", "onDblclick", "onContextmenu"])]),
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_renderSlot(_ctx.$slots, "default"), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.buttonList, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: "ml-1"
          }, [_unref(hasPermission)([item.permission]) ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            size: item.size ? item.size : '',
            type: item.type ? item.type : '',
            onClick: $event => buttonClick(item)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(item.title), 1)]),
            _: 2
          }, 1032, ["size", "type", "onClick"])) : _createCommentVNode("", true)]);
        }), 128))])])]),
        _: 3
      }, 8, ["placement", "width", "disabled"]);
    };
  }
};