/* unplugin-vue-components disabled */import script from "./VabThemeDrawer.vue?vue&type=script&lang=ts&setup=true"
export * from "./VabThemeDrawer.vue?vue&type=script&lang=ts&setup=true"

import "./VabThemeDrawer.vue?vue&type=style&index=0&id=593c99c8&lang=scss"
/* custom blocks */
import block0 from "./VabThemeDrawer.vue?vue&type=custom&index=0&blockType=vue-filename-injector"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__