export var useAclStore = defineStore('acl', {
  state: function () {
    return {
      admin: false,
      role: [],
      permission: []
    };
  },
  getters: {
    getAdmin: function (state) {
      return state.admin;
    },
    getRole: function (state) {
      return state.role;
    },
    getPermission: function (state) {
      return state.permission;
    }
  },
  actions: {
    setFull: function (admin) {
      this.admin = admin;
    },
    setRole: function (role) {
      this.role = role;
    },
    setPermission: function (permission) {
      this.permission = permission;
    }
  }
});