import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const pProcured: VabRouteRecord[] = [
  {
    path: '/pProcuredManagement',
    name: 'PProcuredManagement',
    component: Layout,
    // redirect: '/pProcuredManagement/procuredOrder',
    // component: () =>
    //   import('@/views/workbenche/pProcuredManagement/procuredOrder/addMaterial.vue'),
    meta: {
      title: '外购管理',
      icon: 'inbox-archive-line',
      // noClosable: true,
      guard: ['workbenche.pprocured'],
    },
    children: [
      {
        path: 'procuredOrder/procuredPlan',
        name: 'ProcuredPlan',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/procuredPlan.vue'
          ),
        meta: {
          title: '外购计划',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.procured.procuredPlan'],
        },
      },
      {
        path: 'procuredOrder',
        name: 'ProcuredOrder',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/index.vue'
          ),
        meta: {
          title: '外购订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.pprocured'],
        },
      },
      {
        path: 'procuredOrder/procuredDetail',
        name: 'ProcuredDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/procuredDetail.vue'
          ),
        meta: {
          title: '外购单详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredOrder/createProcured',
        name: 'CreateProcured',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/createProcured.vue'
          ),
        meta: {
          title: '新建外购单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredOrder/updateProcured',
        name: 'UpdateProcured',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/updateProcured.vue'
          ),
        meta: {
          title: '编辑外购单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredReceiveOrder',
        name: 'ProcuredReceiveOrder',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReceiveOrder/index.vue'
          ),
        meta: {
          title: '收货订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.pprocuredRev'],
        },
      },

      {
        path: 'procuredReceiveOrder/procuredReceiveDetail',
        name: 'ProcuredReceiveDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReceiveOrder/procuredReceiveDetail.vue'
          ),
        meta: {
          title: '收货详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredReceiveOrder/createProcuredReceive',
        name: 'CreateProcuredReceive',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReceiveOrder/createProcuredReceive.vue'
          ),
        meta: {
          title: '新建收货单',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredReceiveOrder/updateProcuredReceive',
        name: 'UpdateProcuredReceive',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReceiveOrder/updateProcuredReceive.vue'
          ),
        meta: {
          title: '编辑收货单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'procuredReturnOrder',
        name: 'ProcuredReturnOrder',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReturnOrder/index.vue'
          ),
        meta: {
          title: '退货订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.pprocuredRet'],
        },
      },
      {
        path: 'procuredReturnOrder/createProcuredReturn',
        name: 'CreateProcuredReturn',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReturnOrder/createProcuredReturn.vue'
          ),
        meta: {
          title: '新增退货',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredReturnOrder/updateProcuredReturn',
        name: 'UpdateProcuredReturn',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReturnOrder/updateProcuredReturn.vue'
          ),
        meta: {
          title: '编辑退货',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredReturnOrder/procuredReturnDetail',
        name: 'ProcuredReturnDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReturnOrder/procuredReturnDetail.vue'
          ),
        meta: {
          title: '退货详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'procuredOrder/procuredProductDetail',
        name: 'ProcuredProductDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredOrder/procuredProductDetail.vue'
          ),
        meta: {
          title: '外购明细',
          // icon: 'home-2-line',
          // hidden: true,
          guard: ['workbenche.pprocured.pprocuredDetail'],
        },
      },
      {
        path: 'procuredReceiveOrder/procuredReceiveProductDetail',
        name: 'ProcuredReceiveProductDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReceiveOrder/procuredReceiveProductDetail.vue'
          ),
        meta: {
          title: '收货明细',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.pprocuredRevDetail'],
        },
      },
      {
        path: 'procuredReturnOrder/procuredReturnProductDetail',
        name: 'ProcuredReturnProductDetail',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/procuredReturnOrder/procuredReturnProductDetail.vue'
          ),
        meta: {
          title: '退货明细',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.pprocuredRetDetail'],
        },
      },
      {
        path: 'wanNengProcuredReceiveOrder',
        name: 'WanNengProcuredReceiveOrder',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReceiveOrder/index.vue'
          ),
        meta: {
          title: '万能收货',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.wanNengpprocuredRev'],
        },
      },
      {
        path: 'wanNengProcuredReceiveOrder/createWanNengProcuredReceive',
        name: 'CreateWanNengProcuredReceive',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReceiveOrder/createWanNengProcuredReceive.vue'
          ),
        meta: {
          title: '新增万能收货',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengProcuredReceiveOrder/updateWanNengProcuredReceive',
        name: 'UpdateWanNengProcuredReceive',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReceiveOrder/updateWanNengProcuredReceive.vue'
          ),
        meta: {
          title: '编辑万能收货',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengProcuredReturnOrder',
        name: 'WanNengProcuredReturnOrder',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReturnOrder/index.vue'
          ),
        meta: {
          title: '万能退货',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.pprocured.wanNengpprocuredRet'],
        },
      },
      {
        path: 'wanNengProcuredReturnOrder/createWanNengProcuredReturn',
        name: 'CreateWanNengProcuredReturn',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReturnOrder/createWanNengProcuredReturn.vue'
          ),
        meta: {
          title: '新增万能退货',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'wanNengProcuredReturnOrder/updateWanNengProcuredReturn',
        name: 'UpdateWanNengProcuredReturn',
        component: () =>
          import(
            '@/views/workbenche/pProcuredManagement/wanNengProcuredReturnOrder/updateWanNengProcuredReturn.vue'
          ),
        meta: {
          title: '编辑万能退货',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
