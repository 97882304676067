import "core-js/modules/es.array.push.js";
import { toRefs } from 'vue';
import { Edit, Refresh, Pointer } from '@element-plus/icons-vue';
import { approveBatch, cancelWorkFlow, checkAuditPermissionsWhenApproveBatch } from '@/api/system/approve';
import { useUserStore } from '@/store/modules/user';
import { APPROVE_STATE_NOT, SYS_PARAM } from '@/enums/constant';
import ApeDialog from '@/plugins/ApeDialog/index.vue';

import { defineComponent, inject, reactive } from 'vue';
import { ElLoading } from 'element-plus/es';
import 'element-plus/es/components/base/style/index';
import 'element-plus/es/components/loading/style/index';
export default defineComponent({
  name: 'ApproveBatch',
  props: {
    showRefuse: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ApeDialog
  },
  emits: ['approve-chang'],
  setup(props, {
    emit
  }) {
    const $baseMessage = inject('$baseMessage');
    const $baseConfirm = inject('$baseConfirm');
    const userInfo = useUserStore();
    const state = reactive({
      nodeInfo: {
        orderCodes: {},
        //以单据code为key   confirmState做value
        approveResult: 0,
        //1同意 2：拒绝,
        approveRemark: '',
        //审核意见
        approveUser: userInfo.userCode,
        //审核人,
        approveUserName: userInfo.username,
        //审核人
        businessType: '' //业务类型
      },

      orderList: [],
      orderKey: 'orderCode',
      //批量审核中orderList的表单code的key值
      nodeUserData: {},
      //审核权限
      businessType: '',
      btnLoading: false,
      dialogVisible: false,
      scanState: SYS_PARAM.CONFIREM_STATE.value,
      title: '',
      textName: '',
      approveType: 1,
      // 1 审核 2 撤销
      orderCode: '',
      //撤销单号
      confirmMap: {},
      confirmFlag: false,
      loading: null
    });
    const approve = async result => {
      if (result == 1) {
        state.orderList.forEach(v => {
          if (APPROVE_STATE_NOT.indexOf(v.approveState) > -1 && state.nodeUserData[v[state.orderKey]] === 1) {
            state.nodeInfo.orderCodes[v[state.orderKey]] = state.confirmMap[v[state.orderKey]];
            // console.log(' >>>>>>>>>>>1', state.nodeInfo.orderCodes)
          }
        });

        let list = state.orderList.filter(v => APPROVE_STATE_NOT.indexOf(v.approveState) > -1 && state.nodeUserData[v[state.orderKey]] === 1);
        if (!list || list.length === 0) {
          return $baseMessage('亲，当前单据不存在待审单据，无法审核', 'info', 'vab-hey-message-error');
        }
        state.nodeInfo.businessType = state.businessType;
        state.nodeInfo.approveResult = result;
        state.btnLoading = true;
        // const { code } = await approveBatch(state.nodeInfo)
        // if (code === 200) {
        //   $baseMessage('审核成功', 'success', 'vab-hey-message-success')
        //   emit('approve-chang')
        //   close()
        // }
        let newList = [];
        let timeout = 200;
        if (list.length > 4) {
          timeout = 2000;
          newList = group(list, 4);
        } else {
          newList = [list];
        }
        state.loading = ElLoading.service({
          lock: true,
          text: '正在' + state.title + ',切勿关闭页面！！！',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        await toApprove(newList, 0, timeout);
      } else {
        $baseConfirm('是否确认全部拒绝审核？', '温馨提示', async () => {
          // console.log(' state.orderList', state.orderList)
          state.orderList.forEach(v => {
            if (APPROVE_STATE_NOT.indexOf(v.approveState) > -1 && state.nodeUserData[v[state.orderKey]] === 1) {
              state.nodeInfo.orderCodes[v[state.orderKey]] = state.confirmMap[v[state.orderKey]];
              // console.log(' >>>>>>>>>>>1', state.nodeInfo.orderCodes)
            }
          });

          let list = state.orderList.filter(v => APPROVE_STATE_NOT.indexOf(v.approveState) > -1 && state.nodeUserData[v[state.orderKey]] === 1);
          if (!list || list.length === 0) {
            return $baseMessage('亲，当前单据不存在待审单据，无法审核', 'info', 'vab-hey-message-error');
          }
          state.nodeInfo.businessType = state.businessType;
          state.nodeInfo.approveResult = result;
          state.btnLoading = true;
          // const { code } = await approveBatch(state.nodeInfo)
          // if (code === 200) {
          //   $baseMessage('审核成功', 'success', 'vab-hey-message-success')
          //   emit('approve-chang')
          //   close()
          // }
          let newList = [];
          let timeout = 200;
          if (list.length > 4) {
            timeout = 2000;
            newList = group(list, 4);
          } else {
            newList = [list];
          }
          state.loading = ElLoading.service({
            lock: true,
            text: '正在' + state.title + ',切勿关闭页面！！！',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          await toApprove(newList, 0, timeout);
        }, () => {}, '确认');
      }
    };
    /**
     * 去审核
     */
    const toApprove = async (newList, index, timeout) => {
      if (index > newList.length - 1) {
        $baseMessage('审核成功', 'success', 'vab-hey-message-success');
        emit('approve-chang');
        state.btnLoading = false;
        state.loading.close();
        close();
        return;
      }
      state.nodeInfo.orderCodes = {};
      newList[index].forEach(v => {
        state.nodeInfo.orderCodes[v[state.orderKey]] = state.confirmMap[v[state.orderKey]];
      });
      console.log(state.nodeInfo.orderCodes, index);
      const {
        code
      } = await approveBatch(state.nodeInfo);
      if (code === 200) {
        setTimeout(async () => {
          index++;
          await toApprove(newList, index, timeout);
        }, timeout);
      } else {
        state.btnLoading = false;
        state.loading.close();
        close();
      }
    };
    const group = (array, subGroupLength) => {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
      }
      return newArray;
    };
    const undone = async () => {
      const map = {};
      map.orderCode = state.orderCode;
      map.approveRemark = state.nodeInfo.approveRemark;
      map.businessType = state.businessType;
      if (!map.approveRemark || map.approveRemark.length === 0) {
        $baseMessage('亲，请填写撤销意见哦', 'warning', 'vab-hey-message-warning');
        return;
      }
      state.btnLoading = true;
      const {
        code
      } = await cancelWorkFlow(map);
      if (code === 200) {
        $baseMessage('撤销成功', 'success', 'vab-hey-message-success');
        emit('approve-chang');
        close();
      }
      state.btnLoading = false;
    };
    const open = async e => {
      state.confirmMap = {};
      state.approveType = e.approveType;
      state.orderKey = e.orderKey || 'orderCode';
      if (e.approveType === 1) {
        state.title = '批量审核';
        state.textName = '审核';
        state.dialogVisible = true;
        if (e.list[0].confirmState) {
          state.confirmFlag = true;
        }
        let codeList = e.list.map(item => {
          state.confirmMap[item[state.orderKey]] = state.confirmFlag ? item.confirmState : 1;
          return item[state.orderKey];
        });
        await checkAuditPermissions(codeList, e.approveType);
        state.orderList = e.list;
        state.businessType = e.businessType;
      } else {
        state.title = '审核撤销';
        state.textName = '撤销';
        state.dialogVisible = true;
        state.orderCode = e.orderCode;
        state.businessType = e.businessType;
      }
    };

    /**
     * 检查审核权限
     */
    const checkAuditPermissions = async (codeList, approveType) => {
      const {
        data
      } = await checkAuditPermissionsWhenApproveBatch({
        codeList: codeList,
        approveType: approveType
      });
      if (!data || data.length === 0) {
        state.nodeUserData = {};
        return;
      }
      data.forEach(e => {
        state.nodeUserData[e.applyCode] = e.isNodeUser;
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const close = val => {
      state.nodeInfo = {
        orderCodes: {},
        //以单据code为key   confirmState做value
        approveResult: 0,
        //1同意 2：拒绝,
        approveRemark: '',
        //审核意见
        approveUser: userInfo.userCode,
        //审核人,
        approveUserName: userInfo.username,
        //审核人
        businessType: '' //业务类型
      };

      state.orderList = [];
      state.businessType = '';
      state.orderCode = '';
      state.approveType = 1;
      state.confirmMap = {};
      state.dialogVisible = false;
    };
    return {
      ...toRefs(state),
      approve,
      close,
      open,
      undone,
      Edit,
      Refresh,
      Pointer,
      APPROVE_STATE_NOT
    };
  }
});