import { defineComponent, reactive, toRefs } from 'vue';
export default defineComponent({
  name: "ApeCustomOption",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    divided: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    // 选项中需要获取的值
    value: {
      type: [String, Number],
      required: true
    },
    // 选项展示
    label: {
      type: [String, Number],
      required: true
    },
    // 选项对象
    item: {
      type: [String, Number, Object],
      required: true
    },
    // 选项索引
    index: {
      type: Number,
      required: true
    },
    right: {
      type: String,
      default: null
    }
  },
  setup(props, {
    emit
  }) {
    const state = reactive({
      optionObj: JSON.stringify(props.item)
    });
    const liClick = () => {};
    return {
      ...toRefs(state),
      props,
      liClick
    };
  }
});