//type =1 返回当前年月日  =2 返回当前年月 =3返回当前年
export function getNowTime(type = 1) {
  const now = new Date()
  const year = now.getFullYear() //得到年份
  let month: string | number = now.getMonth() //得到月份
  let date: string | number = now.getDate() //得到日期
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  let defaultDate = ''
  if (type == 1) {
    defaultDate = `${year}-${month}-${date}`
  } else if (type == 2) {
    defaultDate = `${year}-${month}`
  } else {
    defaultDate = `${year}`
  }
  return defaultDate
}

/**
 * 求上个月 YYYY-MM
 */
export function getLastMonth(yearMonth) {
  if(yearMonth){
    const arr = yearMonth.split("-");
    let year = arr[0];
    let month = Number(arr[1]);
    if (month === 1) { // 如果输入的月份为1（一月）
      year--; // 则将年份减去1
      month = 12; // 并设置月份为12（十二月）
    } else {
      month--; // 否则直接将月份减去1
    }
    const monthStr = month.toString().padStart(2, '0')
    return `${year}-${monthStr}`; // 返回结果字符串
  }
  const now = new Date()
  let year = now.getFullYear() //得到年份
  let month: string | number = now.getMonth() //得到月份
  if (month === 0) { // 如果输入的月份为1（一月）
    year--; // 则将年份减去1
    month = 12; // 并设置月份为12（十二月）
  }
  month = month.toString().padStart(2, '0')
  return `${year}-${month}`
}

/**
 * 根据日期求第一天和最后一天
 * @param yearMonth 指定日期
 */
export function getFirstLastDayOfYearMonth(yearMonth) {
  const arr = yearMonth.split("-");
  const year = arr[0];
  const month = Number(arr[1]);
  const date = new Date(); // 创建当前日期对象
  // 设置日期为指定年份、月份的1号
  date.setFullYear(year);
  date.setMonth(month - 1); // JavaScript中月份从0开始计数，所以需要将传入的月份-1
  date.setDate(1);
  // 获取本月的最大天数
  const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  return [`${yearMonth}-01`,`${yearMonth}-${lastDayOfMonth}`]
}

function setNumber(year: any, month: any, day: any, number: number) {
  let str = ''
  switch (number) {
    case 1:
      str = `${year}`
      break

    case 2:
      str = `${year}-${month}`
      break
    case 3:
      str = `${year}-${month}-${day}`
      break
  }
  return str
}

// 获取当前日期的前一段时间 type:年、月、日 day:前后day天  number:1 年、2 月、 3 日   timeType:1当前日期、 指定日期  arr:返回数组(小时间在前)-->[当前时间,计算时间]
export function getAfterDate(type = 'day', day = 0, number = 3, timeType = 1, arr = false) {
  let nowdate: Date | number
  let y: Date | number | string
  let m: Date | number | string
  let d: Date | number | string
  let retrundate: Date | number | string
  let weekdate: Date | number | string
  if (timeType == 1) {
    nowdate = new Date()
  } else {
    nowdate = new Date(timeType)
  }
  switch (type) {
    case 'day': //取day天前、后的时间
      nowdate.setTime(nowdate.getTime() + 24 * 3600 * 1000 * day)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      if (m < 10) {
        m = `0${m}`
      }
      if (d < 10) {
        d = `0${d}`
      }

      retrundate = setNumber(y, m, d, number)
      break
    case 'week': //取day周前、后的时间
      weekdate = new Date(Number(nowdate) + 7 * 24 * 3600 * 1000 * day)
      y = weekdate.getFullYear()
      m = weekdate.getMonth() + 1
      d = weekdate.getDate()
      retrundate = setNumber(y, m, d, number)
      break
    case 'month': //取day月前、后的时间
      nowdate.setMonth(nowdate.getMonth() + day)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      if (m < 10) {
        m = `0${m}`
      }
      if (d < 10) {
        d = `0${d}`
      }
      retrundate = setNumber(y, m, d, number)
      break
    case 'year': //取day年前、后的时间
      nowdate.setFullYear(nowdate.getFullYear() + day)
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      if (m < 10) {
        m = `0${m}`
      }
      if (d < 10) {
        d = `0${d}`
      }
      retrundate = setNumber(y, m, d, number)
      break
    default: //取当前时间
      y = nowdate.getFullYear()
      m = nowdate.getMonth() + 1
      d = nowdate.getDate()
      retrundate = setNumber(y, m, d, number)
  }
  if (!arr)
    return retrundate
  let times=[retrundate,retrundate]
  const formatType = number===3?1:number===1?3:2
  if (day>0){
    times[0]=getNowTime(formatType)
  }else{
    times[1]=getNowTime(formatType)
  }
  return times
}

//获取十分秒
export function getNowTimes() {
  const now = new Date()
  const year = now.getFullYear() //得到年份
  let month: number | string = now.getMonth() //得到月份
  let date: number | string = now.getDate() //得到日期
  let hour: number | string = now.getHours() //获取系统时间
  let minute: number | string = now.getMinutes() //分
  let second: number | string = now.getSeconds() //秒
  month = month + 1
  month = month.toString().padStart(2, '0')
  date = date.toString().padStart(2, '0')
  hour = hour.toString().padStart(2, '0')
  minute = minute.toString().padStart(2, '0')
  second = second.toString().padStart(2, '0')
  const defaultDate = `${year}-${month}-${date} ${hour}:${minute}:${second}`
  return defaultDate
}

//获取上个月的最后一天
export function getLastMonthEndDate() {
  const myDate = new Date()
  let tYear = myDate.getFullYear()
  let tMonth: number | string = myDate.getMonth()
  let lastMonthEndDate = ''
  tMonth += 1
  if (tMonth == 1) {
    tMonth -= 1
    tYear -= 1
    tMonth = tMonth.toString().padStart(2, '0')
    lastMonthEndDate = `${tYear}-12-31`
  } else {
    tMonth -= 1
    tMonth = tMonth.toString().padStart(2, '0')
    lastMonthEndDate = `${tYear}-${tMonth}-31`
  }
  return lastMonthEndDate
}

//获取本月月初和月末 0是月出 1是月末
export function getThisMonthDate(type = 1) {
  const nowMonth = new Date()
  const yearMonth = nowMonth.getFullYear() //得到年份
  let monthM: number | string = nowMonth.getMonth() //得到月份
  monthM += 1
  let MonthDay = ''

  function getDays(year: any, month: any) {
    const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      days[1] = 29
    }
    return days[month - 1]
  }

  if (type == 0) {
    monthM = monthM.toString().padStart(2, '0')
    MonthDay = `${yearMonth}-${monthM}-01`
  } else {
    let day: number | string = ''
    day = getDays(yearMonth, monthM)
    monthM = monthM.toString().padStart(2, '0')
    MonthDay = `${yearMonth}-${monthM}-${day}`
  }
  return MonthDay
}

//获取指定月月初和月末 0是月出 1是月末
export function getSelectMonthDate(year: any, month: any, type: any) {
  const daySelect = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    daySelect[1] = 29
  }
  let selectDay = ''
  const selectMonth = month.toString().padStart(2, '0')
  if (type == 0) {
    selectDay = `${year}-${selectMonth}-01`
  } else if (type == 1) {
    selectDay = `${year}-${selectMonth}-${daySelect[month - 1]}`
  } else {
    if (month == 1) {
      selectDay = `${year - 1}-12-31` // (year - 1) + "-12-31"
    } else {
      selectDay = `${year}-${month - 1}-${daySelect[month - 2]}` //year + "-" + (month - 1) + '-' + daySelect[month - 2]
    }
    // selectMonth=(month-1).toString().padStart(2, "0");
    // selectDay=year+'-'+selectMonth+'-'+daySelect[month-1]
  }
  return selectDay
}

//获取指定月的天数
export function getDay(year: any, month: any) {
  const daySelect = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
    daySelect[1] = 29
  }
  return daySelect[month - 1]
}

// 数字转换成汉字
export function toFix(num1: any, num2: any) {
  if (typeof num1 == 'undefined') {
    return num1
  } else {
    return Number(num1.toFixed(num2))
  }
}

// 获取指定日期的第几天
export function getNextDate(date: any, day: any) {
  const dd = new Date(date)
  dd.setDate(dd.getDate() + day)
  const y = dd.getFullYear()
  const m = dd.getMonth() + 1 < 10 ? `0${dd.getMonth() + 1}` : dd.getMonth() + 1
  const d = dd.getDate() < 10 ? `0${dd.getDate()}` : dd.getDate()
  return `${y}-${m}-${d}` //y + "-" + m + "-" + d;
}

//获取当前时间的前几天或后几天的日期
export function getBeforeDate(n: any) {
  const start = new Date()
  const time = start.getTime() - 3600 * 1000 * 24 * n
  const date = new Date(time)
  const YY = date.getFullYear()
  const MM =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const DD = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  return `${YY}-${MM}-${DD}`
}


export function getTime() {
  return [
    {
      text: '当天',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '一周前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '半个月前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 15)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '一个月前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '三个月前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '半年前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 182)
        picker.$emit('pick', [start, end])
      },
    },
    {
      text: '一年前',
      onClick(picker: any) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
        picker.$emit('pick', [start, end])
      },
    },
  ]
}

/**
 * 两个时间相减
 * @param endTime 截止时间 不能为空
 * @param stateTime 开始时间
 * @param type
 */
export function getTimeout(stateTime: string, endTime: string, type = 1) {
  if (!stateTime) {
    stateTime = getNowTimes()
  }
  if (!endTime) {
    endTime = getNowTimes()
  }
  const dateTimes = new Date(endTime).getTime() - new Date(stateTime).getTime() //时间差的毫秒数
  const subMinutes = Math.floor(dateTimes / (60 * 1000)) //获取总共的分钟差
  //计算出相差天数
  const days = Math.floor(dateTimes / (24 * 3600 * 1000))
  //计算出小时数
  const leave1 = dateTimes % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
  const hours = Math.floor(leave1 / (3600 * 1000))
  //计算相差分钟数
  const leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000))
  //计算相差秒数
  const leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
  const seconds = Math.round(leave3 / 1000)
  if (type === 1) {
    return days
  } else if (type === 2) {
    return `${days}天 ${hours}小时`
  } else if (type === 3) {
    return `${days}天 ${hours}小时 ${minutes} 分钟`
  } else if (type === 4) {
    return `${days}天 ${hours}小时 ${minutes} 分钟${seconds} 秒`
  } else {
    return '0'
  }
}

export function getMonthNum(stateTime: string, endTime: string){
  const stateArr = stateTime.split("-");
  const endArr = endTime.split("-");
  return (Number(endArr[0])*12+Number(endArr[1]))-(Number(stateArr[0])*12+Number(stateArr[1]))
}

export function compareDate(stateTime: string, endTime: string){
  if (!stateTime) {
    stateTime = getNowTimes()
  }
  if (!endTime) {
    endTime = getNowTimes()
  }
  return new Date(Date.parse(stateTime))>new Date(Date.parse(endTime))
}

/**
 * 时间戳转年月日
 * @param num
 */
export function timeStampToDate(num){
  if(!num){
    return ""
  }
  let date = new Date(Number(num));
  let Y = date.getFullYear() + '年';
  let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月';
  let D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + '日 ';

  let h = (date.getHours() < 10 ? '0' + (date.getHours()) : date.getHours()) + ':';
  let m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':';
  let s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds());
  return  Y + M + D + h + m + s;
}

/**
 * 近几个月集合
 * @param num
 */
export function getNearMonthList(num){
  // 获取当前日期对象
  const currentDate = new Date();
// 定义存放结果的数组
  const result = [];
  for (let i = 0; i < num; i++) {
    // 创建新的日期对象并设置为当前日期加上i个月
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i);
    // 将年份和月份添加到结果数组中
    result.push(`${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}`);
  }
  return result;
}

