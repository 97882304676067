import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'
import { user } from './basic/user'
import { customer } from './basic/customer'
import { supplier } from './basic/supplier'

export const basicRouters: VabRouteRecord[] = [
  {
    path: '/basic',
    name: 'Basic',
    component: Layout,
    meta: {
      title: '基础数据',
      icon: 'archive-drawer-line',
      guard: ['basic'],
    },
    children: [
      {
        path: 'personalCenter',
        name: 'PersonalCenter',
        component: () => import('@/views/basic/personalCenter/index.vue'),
        meta: {
          title: '个人中心',
          icon: 'user-line',
          guard: ['basic.userinfo'],
        },
      },
      ...user,
      ...customer,
      ...supplier,
    ],
  },
]
