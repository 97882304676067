//@ts-nocheck
import { defineComponent, defineEmits } from 'vue';

import { useVModel } from '@vueuse/core';
export default defineComponent({
  name: 'DateSelect',
  props: {
    //是否时间段选择 true启用 false单选日期
    monthrange: {
      type: Boolean,
      default: false
    },
    //value改变函数，父组件用于接收value值
    valChange: {
      type: Function,
      required: true
    },
    //回显数据,数组形式时[开始日期,结束日期]
    echoData: {
      type: [Array, String],
      default: undefined
    },
    //是否禁用未到来的日期
    disableNext: {
      type: Boolean,
      default: false
    },
    //是否可清除
    clearable: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Date, Array]
    }
  },
  setup: function (props) {
    //绑定的值
    var emits = defineEmits(["update:modelValue"]);
    var value = useVModel(props, "modelValue", emits);
    //时间段快捷日期
    var shortcuts = [{
      text: '当天',
      value: function () {
        var end = new Date();
        var start = new Date();
        return [start, end];
      }
    }, {
      text: '一周前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      }
    }, {
      text: '半个月前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
        return [start, end];
      }
    }, {
      text: '一个月前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      }
    }, {
      text: '三个月前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      }
    }, {
      text: '半年前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90 * 2);
        return [start, end];
      }
    }, {
      text: '一年前',
      value: function () {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
        return [start, end];
      }
    }];
    //时间点快捷日期
    var timeShortcuts = [{
      text: '今天',
      value: new Date()
    }, {
      text: '昨天',
      value: function () {
        var date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24);
        return date;
      }
    }, {
      text: '一周前',
      value: function () {
        var date = new Date();
        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
        return date;
      }
    }];
    //禁用日期函数
    var disabledDate = function (time) {
      if (!props.disableNext) {
        return;
      } else {
        return time.getTime() > Date.now();
      }
    };
    //回显
    if (props.echoData) {
      value.value = props.echoData;
    }
    return {
      value: value,
      shortcuts: shortcuts,
      disabledDate: disabledDate,
      timeShortcuts: timeShortcuts
    };
  }
});