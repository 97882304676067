/**
 * 公司常量，上线替换成客户信息
 */
// export const COMPANY_INFO = {
//   CI_NAME: "成都起猿维度科技有限公司",//公司全称
//   CI_NAME_JC:"起猿软件",//公司简称
//   CI_LOGO:"https://aperise.oss-cn-chengdu.aliyuncs.com/logo/logo2.png",//LOGO链接
// }
export const COMPANY_INFO = {
  CI_NAME: "成都市欣乐森装饰材料有限公司",//公司全称
  CI_NAME_JC:"欣乐森",//公司简称
  CI_LOGO:"https://aperise.oss-cn-chengdu.aliyuncs.com/logo/logo2.png",//LOGO链接
}

/**
 * 起猿信息
 */
export const APERISE_INFO = {
  APE_NAME: '成都起猿维度科技有限公司', //公司全称
  APE_NAME_JC: '起猿软件', //公司简称
  APE_LOGO: 'https://aperise.oss-cn-chengdu.aliyuncs.com/logo/logo2.png', //LOGO链接
  WEB_URL:"http://aperise.net/"
}

/**
 * 系统信息
 */
export const SYS_INFO = {
  SYS_NAME:"起猿软件",
  SYS_COMPANY:"成都起猿维度科技有限公司",//系统显示公司名称
  SYS_COMPANY_JC:"起猿软件",//简称
  SYS_LOGO:"https://aperise.oss-cn-chengdu.aliyuncs.com/logo/logo2.png"
}
