import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA } from '@/config'

// ---------------------------------------------登录相关-----------------------------------------
/**
 * 用户登录接口
 */
export async function login(data: any) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/login/login',
    method: 'post',
    data,
  })
}
/**
 * 锁屏登入
 */
export async function lockLogin(data: any) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/login/lockLogin',
    method: 'post',
    data,
  })
}
/**
 * 员工自己修改个人信息
 */
export function updateUserWhenOwn(data: any) {
  return request({
    url: '/user/updateUserWhenOwn',
    method: 'post',
    data,
  })
}
export async function socialLogin(data: any) {
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/socialLogin',
    method: 'post',
    data,
  })
}

/**
 * 登录时获取账户人员信息（角色  权限）等；
 */
export function getAccountInfo() {
  return request({
    url: '/login/getUserInfo',
    method: 'post',
  })
}

/**
 * 登出
 */
export function logout() {
  return request({
    url: '/login/logout',
    method: 'post',
  })
}

/**
 * 登出
 */
export function register() {
  return request({
    url: '/login/register',
    method: 'post',
  })
}

// ---------------------------------------------用户人员相关-----------------------------------------

/**
 * 新增人员
 */
export function createUser(data: any) {
  return request({
    url: '/user/createUser',
    method: 'post',
    data,
  })
}

/**
 * 编辑人员
 */
export function updateUser(data: any) {
  return request({
    url: '/user/updateUser',
    method: 'post',
    data,
  })
}

/**]
 * 编辑人员状态
 * @param data
 */
export function updateUserUsable(data: any) {
  return request({
    url: '/user/updateUserUsable',
    method: 'post',
    data,
  })
}

/**
 * 获取员工列表List
 */
export function getUserList(data: any) {
  return request({
    url: '/user/getUserList',
    method: 'post',
    data,
  })
}

/**
 * 获取员工信息
 */
export function getUserInfo(data: any) {
  return request({
    url: '/user/getUserInfo',
    method: 'post',
    data,
  })
}

/**
 * 获取员工详情Table
 */
export function getUserDetailTable(data: any) {
  return request({
    url: '/user/getUserDetailTable',
    method: 'post',
    data,
  })
}

/**
 * 删除员工
 */
export function delUser(data: any) {
  return request({
    url: '/user/delUser',
    method: 'post',
    data,
  })
}

/**
 * 修改员工密码
 */
export function updateUserPwd(data: any) {
  return request({
    url: '/user/updateUserPwd',
    method: 'post',
    data,
  })
}

/**
 * 密码一键重置
 */
export function resetUserPwd(data: any) {
  return request({
    url: '/user/resetUserPwd',
    method: 'post',
    data,
  })
}

/**
 * 解锁登录权限
 */
export function updatePersonStaffLoginLockState(data: any) {
  return request({
    url: '/user/updatePersonStaffLoginLockState',
    method: 'post',
    data,
  })
}

/**
 * 获取部门列表
 */
export function getDeptListSelect(data: any) {
  return request({
    url: '/system/pub/getDeptList',
    method: 'post',
    data,
  })
}

/**
 * 获取角色列表
 */
export function getRoleList(data: any) {
  return request({
    url: '/system/action/getRoleList',
    method: 'post',
    data,
  })
}

/**
 * 新增部门
 */
export function createDept(data: any) {
  return request({
    url: '/user/createDept',
    method: 'post',
    data,
  })
}

/**
 * 删除部门
 */
export function delDept(data: any) {
  return request({
    url: '/user/delDept',
    method: 'post',
    data,
  })
}

/**
 * 修改部门
 */
export function updateDept(data: any) {
  return request({
    url: '/user/updateDept',
    method: 'post',
    data,
  })
}

/**
 * 获取部门list
 */
export function getDeptList(data: any) {
  return request({
    url: '/user/getDeptList',
    method: 'post',
    data,
  })
}

/**
 * 新增部门人员
 */
export function saveDeptUser(data: any) {
  return request({
    url: '/user/saveDeptUser',
    method: 'post',
    data,
  })
}

/**
 * 删除部门人员
 */
export function delDeptUser(data: any) {
  return request({
    url: '/user/delDeptUser',
    method: 'post',
    data,
  })
}

/**
 * 获取部门人员
 */
export function getDeptUserList(data: any) {
  return request({
    url: '/user/getDeptUserList',
    method: 'post',
    data,
  })
}
