import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'
import { material } from './workbenche/material'
import { product } from './workbenche/product'
import { goods } from './workbenche/goods'
import { procured } from './workbenche/procured'
import { sale } from './workbenche/sale'
import { process } from './workbenche/process'
import { work } from './workbenche/work'
import { pProcured } from './workbenche/pProcured'
export const workbencheRouters: VabRouteRecord[] = [
  {
    path: '/',
    name: 'Workbenche',
    component: Layout,
    meta: {
      title: '工作台',
      icon: 'macbook-line',
      breadcrumbHidden: true,
      guard: ['workbenche'],
    },
    children: [
      {
        path: 'index',
        name: 'Index',
        component: () => import('@/views/workbenche/index.vue'),
        meta: {
          title: '工作看板',
          icon: 'airplay-line',
          noClosable: true,
          guard: ['workbenche.home'],
        },
      },
      ...procured,
      ...material,
      ...pProcured,
      ...sale,
      ...work,              
      ...product,
      ...goods,
      ...process,
      {
        path: 'orderPrint',
        name: 'OrderPrint',
        component: () => import('@/views/workbenche/print.vue'),
        meta: {
          title: '单据打印',
          // guard: ['system.permission.permission.group'],
          icon: 'printer-line',
          hidden:true
        },
      },
    ],
  },
]
