import "core-js/modules/es.array.push.js";
export var useErrorLogStore = defineStore('errorLog', {
  state: function () {
    return {
      errorLogs: []
    };
  },
  getters: {
    getErrorLogs: function (state) {
      return state.errorLogs;
    }
  },
  actions: {
    addErrorLog: function (errorLog) {
      this.errorLogs.push(errorLog);
    },
    clearErrorLog: function () {
      this.errorLogs.splice(0);
    }
  }
});