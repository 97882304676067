import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const dataRouters: VabRouteRecord[] = [
  {
    path: '/data',
    name: 'Data',
    component: Layout,
    meta: {
      title: '数据统计',
      icon: 'bar-chart-grouped-line',
      levelHidden: true,
      guard: ['data'],
    },
    children: [
      //   {
      //     path: 'goods',
      //     name: 'Goods',
      //     components: () => import('@/views/data/goods/addMaterial.vue'),
      //     meta: {
      //       title: '物料市场',
      //       icon: 'shopping-cart-line',
      //       badge: 'Hot',
      //     },
      //   },
      // {
      //   path: 'workbench',
      //   name: 'Workbench',
      //   component: () => import('@/views/data/kanBan/workbench.vue'),
      //   meta: {
      //     title: '工作台',
      //     icon: 'mark-pen-line',
      //     guard: ['data.workbenche'],
      //   },
      // },

      {
        path: 'salesData',
        name: 'SalesData',
        meta: {
          title: '销售分析',
          icon: 'database-line',
          guard: ['data.salesAly'],
        },
        children: [
          {
            path: 'salesLiRunReport',
            name: 'SalesLiRunReport',
            component: () => import('@/views/data/salesData/salesLiRunReport.vue'),
            meta: {
              title: '销售利润',
              icon: 'money-cny-box-line',
              guard: ['data.salesAly.selesLirun'],
            },
          },
          {
            path: 'salesStatistics',
            name: 'SalesStatistics',
            component: () => import('@/views/data/salesData/salesStatistics.vue'),
            meta: {
              title: '销售统计',
              icon: 'bar-chart-box-line',
              guard: ['data.salesAly.saleStatistics'],
            },
          },
          {
            path: 'customerManagerData',
            name: 'CustomerManagerData',
            component: () => import('@/views/data/salesData/customerManagerData.vue'),
            meta: {
              title: '客户经理',
              icon: 'bar-chart-box-line',
              guard: ['data.salesAly.mangager'],
            },
          },
        ]
      },
      {
        path: 'customerDate',
        name: 'CustomerDate',
        meta: {
          title: '客户分析',
          icon: 'database-line',
          guard: ['data.customerDate'],
        },
        children: [
          {
            path: 'customerAly',
            name: 'CustomerAly',
            component: () =>
              import('@/views/data/customerData/customerAly.vue'),
            meta: {
              title: '客户分析',
              guard: ['data.dataHuizong.notTrading'],
            },
          },
          {
            path: 'notTradingCustomer',
            name: 'NotTradingCustomer',
            component: () =>
              import('@/views/data/customerData/notTradingCustomer.vue'),
            meta: {
              title: '近期无交往客户',
              guard: ['data.dataHuizong.notTrading'],
            },
          },
          {
            path: 'shipmentUseMaterial',
            name: 'ShipmentUseMaterial',
            component: () =>
              import('@/views/data/customerData/shipmentUseMaterial.vue'),
            meta: {
              title: '发货所需原料',
              guard: ['data.dataHuizong.useMaterial'],
            },
          },
          {
            path: 'shipmentPaperIsBase',
            name: 'ShipmentPaperIsBase',
            component: () =>
              import('@/views/data/customerData/shipmentPaperIsBase.vue'),
            meta: {
              title: '发货原料(纸对应板)',
              guard: ['data.dataHuizong.paperIsBase'],
            },
          },
          {
            path: 'customerFundFlow',
            name: 'CustomerFundFlow',
            component: () =>
              import('@/views/data/customerData/customerFundFlow.vue'),
            meta: {
              title: '客户资金流',
              guard: ['data.dataHuizong.customerFundFlow'],
            },
          },
          {
            path: 'shipNotFinished',
            name: 'ShipNotFinished',
            component: () =>
              import('@/views/data/customerData/shipNotFinished.vue'),
            meta: {
              title: '未完成销售单',
              guard: ['data.dataHuizong.shipNotFinished'],
            },
          },
          {
            path: 'customerOrderDetail',
            name: 'CustomerOrderDetail',
            component: () =>
              import('@/views/data/customerData/customerOrderDetail.vue'),
            meta: {
              title: '客户订单明细',
              guard: ['data.dataHuizong.shipNotFinished'],
            },
          },
        ],
      },
      {
        path: 'supplierData',
        name: 'SupplierData',
        meta: {
          title: '供应商分析',
          icon: 'database-line',
          guard: ['data.supplierData'],
        },
        children: [
          {
            path: 'supplierAny',
            name: 'SupplierAny',
            component: () =>
              import('@/views/data/supplierData/index.vue'),
            meta: {
              title: '供应商分析',
              guard: ['data.supplierData.supplierAny'],
            },
          },
          {
            path: 'supplierFundFlow',
            name: 'SupplierFundFlow',
            component: () =>
              import('@/views/data/supplierData/supplierFundFlow.vue'),
            meta: {
              title: '供应商资金流',
              guard: ['data.supplierData.supplierFlow'],
            },
          },
        ]
      },
      {
        path: 'personData',
        name: 'PersonData',
        meta: {
          title: '员工分析',
          icon: 'database-line',
          guard: ['data.userData'],
        },
        children: [
          {
            path: 'salesmanData',
            name: 'SalesmanData',
            component: () =>
              import('@/views/data/personData/salesmanData.vue'),
            meta: {
              title: '客户经理排行',
              guard: ['data.userData.managerTank'],
            },
          },
        ],
      },
      {
        path: 'workData',
        name: 'WorkData',
        meta: {
          title: '生产分析',
          icon: 'database-line',
          guard: ['data.workData'],
        },
        children: [
          {
            path: 'workCount',
            name: 'WorkCount',
            component: () =>
              import('@/views/data/workData/workCount.vue'),
            meta: {
              title: '生产统计',
              guard: ['data.workData.tongji'],
            },
          }
        ],
      },
      {
        path: 'productData',
        name: 'productData',
        meta: {
          title: '产品分析',
          icon: 'database-line',
          guard: ['data.productData'],
        },
        children: [
          {
            path: 'productWarning',
            name: 'ProductWarning',
            component: () =>
              import('@/views/data/productData/productWarning.vue'),
            meta: {
              title: '产品预警',
              guard: ['data.productData.Yujing'],
            },
          },
          {
            path: 'productStatistic',
            name: 'ProductStatistic',
            component: () =>
              import('@/views/data/productData/productStatistic.vue'),
            meta: {
              title: '产品统计',
              guard: ['data.productData.tongJi'],
            },
          },
        ],
      },
      {
        path: 'materialDataAny',
        name: 'MaterialDataAny',
        meta: {
          title: '原料分析',
          icon: 'database-line',
          guard: ['data.materialDataAny'],
        },
        children: [
          {
            path: 'materialWarn',
            name: 'MaterialWarn',
            component: () =>
              import('@/views/data/materialData/materialWarn.vue'),
            meta: {
              title: '预警分析',
              guard: ['data.materialDataAny.warn'],
            },
          },
          {
            path: 'materialAny',
            name: 'MaterialAny',
            component: () =>
              import('@/views/data/materialData/index.vue'),
            meta: {
              title: '原料分析',
              guard: ['data.materialDataAny.materialAny'],
            },
          },
        ]
      },
      {
        path: 'orderHuiZong',
        name: 'OrderHuiZong',
        meta: {
          title: '数据汇总',
          icon: 'database-line',
          guard: ['data.dataHuizong'],
        },
        children: [
          {
            path: 'salesProductHuiZong',
            name: 'SalesProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/salesProductHuiZong.vue'),
            meta: {
              title: '销售汇总',
              guard: ['data.dataHuizong.sales'],
            },
          },
          {
            path: 'shipmentProductHuiZong',
            name: 'ShipmentProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/shipmentProductHuiZong.vue'),
            meta: {
              title: '发货汇总',
              guard: ['data.dataHuizong.shipment'],
            },
          },
          {
            path: 'shipReturnHuiZong',
            name: 'ShipReturnHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/shipReturnHuiZong.vue'),
            meta: {
              title: '退货汇总',
              guard: ['data.dataHuizong.shipReturn'],
            },
          },
          {
            path: 'procuredProductHuiZong',
            name: 'ProcuredProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/procuredProductHuiZong.vue'),
            meta: {
              title: '外购汇总',
              guard: ['data.dataHuizong.pprocured'],
            },
          },
          {
            path: 'procuredRevProductHuiZong',
            name: 'ProcuredRevProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/procuredRevProductHuiZong.vue'),
            meta: {
              title: '外购收货汇总',
              guard: ['data.dataHuizong.pprocuredRev'],
            },
          },
          {
            path: 'procuredRetProductHuiZong',
            name: 'ProcuredRetProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/procuredRetProductHuiZong.vue'),
            meta: {
              title: '外购退货汇总',
              guard: ['data.dataHuizong.pprocuredRet'],
            },
          },
          {
            path: 'processProductHuiZong',
            name: 'ProcessProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/processProductHuiZong.vue'),
            meta: {
              title: '加工汇总',
              guard: ['data.dataHuizong.process'],
            },
          },
          {
            path: 'processRevProductHuiZong',
            name: 'ProcessRevProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/processRevProductHuiZong.vue'),
            meta: {
              title: '加工收货汇总',
              guard: ['data.dataHuizong.processRev'],
            },
          },
          {
            path: 'processRetProductHuiZong',
            name: 'ProcessRetProductHuiZong',
            component: () =>
              import('@/views/data/orderhuiZong/processRetProductHuiZong.vue'),
            meta: {
              title: '加工退货汇总',
              guard: ['data.dataHuizong.processRet'],
            },
          },
          {
            path: 'materialProcuredHz',
            name: 'MaterialProcuredHz',
            component: () =>
              import('@/views/data/orderhuiZong/materialProcured.vue'),
            meta: {
              title: '原料采购汇总',
              guard: ['data.dataHuizong.materialProcured'],
            },
          },
          {
            path: 'materialOpStatistics',
            name: 'MaterialOpStatistics',
            component: () =>
              import('@/views/data/orderhuiZong/materialOpStatistics.vue'),
            meta: {
              title: '原料出入库统计',
            },
          },
        ],
      },
      {
        path: 'dataAssay',
        name: 'DataAssay',
        meta: {
          title: '数据分析',
          icon: 'database-line',
          guard: ['data.dataAssay'],
        },
        children: [
          {
            path: 'progressTracking',
            name: 'ProgressTracking',
            component: () =>
              import('@/views/data/dataAssay/progressTracking.vue'),
            meta: {
              title: '进度跟踪',
              guard: ['data.dataAssay.jinDu'],
            },
          },
        ],
      },
      {
        path: 'dataReport',
        name: 'DataReport',
        meta: {
          title: '数据报告',
          icon: 'database-line',
          guard: ['data.dataReport'],
        },
        children: [
          {
            path: 'materialReport',
            name: 'MaterialReport',
            component: () =>
              import('@/views/data/dataReport/materialReport.vue'),
            meta: {
              title: '原料报告',
              guard: ['data.dataReport.material'],
            },
          },
          // {
          //   path: 'materialReport1',
          //   name: 'MaterialReport1',
          //   component: () =>
          //     import('@/views/data/dataReport/materialReport_1.vue'),
          //   meta: {
          //     title: '原料报告1',
          //     guard: ['data.dataHuizong.notTrading'],
          //   },
          // },
          {
            path: 'productReport',
            name: 'ProductReport',
            component: () =>
              import('@/views/data/dataReport/productReport.vue'),
            meta: {
              title: '产品报告',
              guard: ['data.dataReport.product'],
            },
          },
          // {
          //   path: 'productReport1',
          //   name: 'ProductReport1',
          //   component: () =>
          //     import('@/views/data/dataReport/productReport_1.vue'),
          //   meta: {
          //     title: '产品报告_1',
          //     guard: ['data.dataHuizong.notTrading'],
          //   },
          // },
          {
            path: 'workReport',
            name: 'WorkReport',
            component: () =>
              import('@/views/data/dataReport/workReport.vue'),
            meta: {
              title: '生产报告',
              guard: ['data.dataReport.work'],
            },
          },
          // {
          //   path: 'workReport1',
          //   name: 'WorkReport1',
          //   component: () =>
          //     import('@/views/data/dataReport/workReport_1.vue'),
          //   meta: {
          //     title: '生产报告1',
          //     guard: ['data.dataHuizong.notTrading'],
          //   },
          // },
          {
            path: 'manageReport',
            name: 'ManageReport',
            component: () =>
              import('@/views/data/dataReport/manageReport.vue'),
            meta: {
              title: '经营报告',
              guard: ['data.dataReport.jingYing'],
            },
          },
          // {
          //   path: 'manageData',
          //   name: 'ManageData',
          //   component: () =>
          //     import('@/views/data/manageData/manageData.vue'),
          //   meta: {
          //     title: '经营报告1',
          //     guard: ['data.dataHuizong.notTrading'],d
          //   },
          // },
        ],
      },
    ],
  },
]
