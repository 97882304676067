import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const product: VabRouteRecord[] = [
  {
    path: '/productManagement',
    name: 'ProductManagement',
    component: Layout,
    redirect: '/productManagement/product',
    meta: {
      title: '成品管理',
      icon: 'product-hunt-line',
      // noClosable: true,
      guard: ['workbenche.product'],
    },
    children: [
      {
        path: 'product',
        name: 'Product',
        component: () =>
          import('@/views/workbenche/productManagement/product/index.vue'),
        meta: {
          title: '成品库存',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.product.product'],
        },
      },
      {
        path: 'product/productDetail',
        name: 'ProductDetail',
        component: () =>
          import(
            '@/views/workbenche/productManagement/product/components/productDetail.vue'
          ),
        meta: {
          title: '成品详情',
           // icon: 'map-pin-user-line',
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'product/createProduct',
        name: 'CreateProduct',
        component: () =>
          import(
            '@/views/workbenche/productManagement/product/components/createProduct.vue'
          ),
        meta: {
          title: '新增成品',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'product/customProduct',
        name: 'CustomProduct',
        component: () =>
          import(
            '@/views/workbenche/productManagement/product/components/customProduct.vue'
          ),
        meta: {
          title: '自建成品',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      // {
      //   path: 'productOutOrPut',
      //   name: 'ProductOutOrPut',
      //   component: () =>
      //     import(
      //       '@/views/workbenche/productManagement/productOutOrPut/rawStore.vue'
      //     ),
      //   meta: {
      //     title: '出入库管理',
      //      // icon: 'map-pin-user-line',
      //     // guard: ['Admin'],
      //   },
      // },
      {
        path: 'productOutOrPut',
        name: 'ProductOutOrPut',
        meta: {
          title: '出入库',
          icon: 'arrow-left-right-fill',
          guard: ['workbenche.product.outOrPut'],
        },
        children:[
          {
            path: 'product/productOutOrPut/outOrPutDetail',
            name: 'outOrPutDetail',
            component: () =>
              import(
                '@/views/workbenche/productManagement/productOutOrPut/outOrPutDetail.vue'
              ),
            meta: {
              title: '出入库明细',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.product.outOrPut.opDetial'],
            },
          },
          {
            path: 'product/productOutOrPut/outOtPutCollent',
            name: 'outOtPutCollent',
            component: () =>
              import(
                '@/views/workbenche/productManagement/productOutOrPut/outOtPutCollent.vue'
              ),
            meta: {
              title: '出入库汇总',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.product.outOrPut.opHuiZong'],
            },
          },
          {
            path: 'product/productOutOrPut/outOrPutApply',
            name: 'outOrPutApply',
            component: () =>
              import(
                '@/views/workbenche/productManagement/productOutOrPut/outOrPutApply.vue'
              ),
            meta: {
              title: '出入库单据',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.product.outOrPut.opOrder'],
            },
          },
          {
            path: 'product/productOutOrPut/productManualOutOrPutDetail',
            name: 'ProductManualOutOrPutDetail',
            component: () =>
              import(
                '@/views/workbenche/productManagement/productOutOrPut/productManualOutOrPutDetail.vue'
              ),
            meta: {
              title: '手动出入明细',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.product.outOrPut.opManualDetail'],
            },
          },
        ]
      },
      {
        path: 'productOutOrPut/createProductPut',
        name: 'CreateProductPut',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/createProductPut.vue'
          ),
        meta: {
          title: '新增成品入库',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/createProductOut',
        name: 'CreateProductOut',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/createProductOut.vue'
          ),
        meta: {
          title: '新增成品出库',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/productWareChange',
        name: 'ProductWareChange',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/productWareChange.vue'
          ),
        meta: {
          title: '产品仓库转换',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/salesProductWareChange',
        name: 'SalesProductWareChange',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/salesProductWareChange.vue'
          ),
        meta: {
          title: '销售仓库转换',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/productBatchChange',
        name: 'ProductBatchChange',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/productBatchChange.vue'
          ),
        meta: {
          title: '产品批次转换',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/productChangeProduct',
        name: 'ProductChangeProduct',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/productChangeProduct.vue'
          ),
        meta: {
          title: '产品转换',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/productMergeChangeProduct',
        name: 'ProductMergeChangeProduct',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/productMergeChangeProduct.vue'
          ),
        meta: {
          title: '产品合并转换',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productName',
        name: 'ProductName',
        component: () =>
          import('@/views/workbenche/productManagement/productName/index.vue'),
        meta: {
          title: '名称管理',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.product.productName'],
        },
      },
      {
        path: 'bomReplace',
        name: 'BomReplace',
        component: () =>
          import('@/views/workbenche/productManagement/bomReplace/index.vue'),
        meta: {
          title: 'BOM替换',
           // icon: 'map-pin-user-line',
          // hidden: true,
          guard: ['workbenche.product.bomReplace'],
        },
      },
      {
        path: 'productWare',
        name: 'ProductWare',
        component: () =>
          import('@/views/workbenche/productManagement/productWare/index.vue'),
        meta: {
          title: '仓储管理',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.product.ware'],
        },
      },
      {
        path: 'product/productBei',
        name: 'ProductBei',
        component: () =>
          import(
            '@/views/workbenche/productManagement/product/components/productBei.vue'
          ),
        meta: {
          title: '客户库存',
           // icon: 'map-pin-user-line',
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'product/productAddDate',
        name: 'ProductAddDate',
        component: () =>
          import(
            '@/views/workbenche/productManagement/product/components/productAddDate.vue'
          ),
        meta: {
          title: '产品近期新增',
           // icon: 'map-pin-user-line',
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'productOutOrPut/manualOutPutDetial',
        name: 'ManualOutPutDetial',
        component: () =>
          import(
            '@/views/workbenche/productManagement/productOutOrPut/components/manualOutPutDetial.vue'
          ),
        meta: {
          title: '手动出入库详情',
          // icon: 'checkbox-blank-circle-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'checkProduct/checkProductRecord',
        name: 'CheckProductRecord',
        component: () =>
          import(
            '@/views/workbenche/productManagement/checkProduct/checkProductRecord.vue'
          ),
        meta: {
          title: '盘点记录',
          // icon: 'checkbox-blank-circle-line',
          // dynamicNewTab: true,
          guard: ['workbenche.product.productCheck'],
        },
      },
      {
        path: 'checkProduct/checkProductExportRecord',
        name: 'CheckProductExportRecord',
        component: () =>
          import(
            '@/views/workbenche/productManagement/checkProduct/checkProductExportRecord.vue'
          ),
        meta: {
          title: '盘点导入',
          // icon: 'checkbox-blank-circle-line',
          // dynamicNewTab: true,
          guard: ['workbenche.product.productCheckImport'],
        },
      },
      {
        path: 'workTaskProductPut',
        name: 'WorkTaskProductPut',
        component: () =>
          import(
            '@/views/workbenche/productManagement/workProduct/workTaskProductPut.vue'
            ),
        meta: {
          title: '生产入库',
          guard: ['workbenche.product.workProduct'],
        },
      },
      {
        path: 'workTaskProductPutDetails',
        name: 'WorkTaskProductPutDetails',
        component: () =>
          import(
            '@/views/workbenche/productManagement/workProduct/workTaskProductPutDetails.vue'
            ),
        meta: {
          title: '生产入库详情',
          hidden: true,
          // guard: ['workbenche.work.workTaskProductPutDetails'],
        },
      },
      {
        path: 'workTaskProductDefec',
        name: 'WorkTaskProductDefec',
        component: () =>
          import(
            '@/views/workbenche/productManagement/workProduct/workTaskProductDefec.vue'
            ),
        meta: {
          title: '生产次品',
          guard: ['workbenche.product.workProductSem'],
        },
      },
      {
        path: 'workTaskProductDefecDetails',
        name: 'WorkTaskProductDefecDetails',
        component: () =>
          import(
            '@/views/workbenche/productManagement/workProduct/workTaskProductDefecDetails.vue'
            ),
        meta: {
          title: '生产次品详情',
          hidden: true,
          // guard: ['workbenche.work.workTaskProductDefecDetails'],
        },
      },
      {
        path: 'processHighOut',
        name: 'ProcessHighOut',
        component: () =>
          import(
            '@/views/workbenche/productManagement/processOut/processHighOut.vue'
            ),
        meta: {
          title: '加工出库',
          guard: ['workbenche.product.workProductSem'],
        },
      },
      {
        path: 'processHighOutDetail',
        name: 'ProcessHighOutDetail',
        component: () =>
          import(
            '@/views/workbenche/productManagement/processOut/processHighOutDetail.vue'
            ),
        meta: {
          title: '加工出库详情',
          hidden: true,
        },
      },
      {
        path: 'productData',
        name: 'ProductData',
        component: () =>
          import('@/views/workbenche/productManagement/product/productData.vue'),
        meta: {
          title: '成品分析',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.product.product'],
        },
      },
      {
        path: 'notUseBatchProduct',
        name: 'NotUseBatchProduct',
        component: () =>
          import('@/views/workbenche/productManagement/product/notUseBatchProduct.vue'),
        meta: {
          title: '闲置库存',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.product.notUseBatchProduct'],
        },
      },
      // {
      //   path: 'jingCheng',
      //   name: 'JingCheng',
      //   component: () =>
      //     import('@/views/workbenche/productManagement/jingCheng/index.vue'),
      //   meta: {
      //     title: '精诚定制',
      //     // icon: 'map-pin-user-line',
      //     guard: ['workbenche.product.product'],
      //   },
      // },
    ],
  },
]
