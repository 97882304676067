import "core-js/modules/es.array.push.js";
import { trimAll } from '@/utils/customUtils';
import ApeCustomOption from '@/plugins/ApeCustomSelect/ApeCustomOption';
import { Search, Plus, Edit, Delete } from '@element-plus/icons-vue';
import { toRefs } from 'vue';

import { defineComponent, reactive, watch, toRef, onMounted } from 'vue';
import { useVModel } from '@vueuse/core';
export default defineComponent({
  name: 'ApeCustomSelect',
  components: {
    ApeCustomOption
  },
  props: {
    // 是否可清空 如果不是Boolean  则值存在有清除
    clearable: {
      type: [Boolean, String],
      default: true
    },
    // 是否禁用 如果不是Boolean  则值存在不禁用
    disabled: {
      type: [Boolean, String],
      default: false
    },
    // // 是否只读 如果不是Boolean  则值存在只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 默认显示文字
    placeholder: {
      type: String,
      default: '请选择'
    },
    // 下拉选项中展示字段
    labelField: {
      type: String,
      required: true
    },
    // 下拉选项需要的字段
    valueField: {
      type: String,
      required: true
    },
    // v-model绑定值字段
    modelField: {
      type: String,
      required: true
    },
    // 输入框聚焦事件
    focusFun: {
      type: Function
    },
    // 输入框失焦事件
    blurFun: {
      type: Function
    },
    // 当前行绑定对象
    modelItem: {
      type: Object,
      required: true
    },
    // 是否需要下拉加载  如果需要则必传查询API
    lazyLoad: {
      type: Boolean,
      default: true
    },
    // 查询API
    queryFun: {
      type: Function
    },
    // 查询字段
    queryField: {
      type: String
    },
    // 当前行是否存在有效数据的标识字段
    valueKey: {
      type: String,
      required: true
    },
    // 每页条数
    pageSize: {
      type: Number,
      default: 10
    },
    // 请求附带额外参数
    extraInfo: {
      type: Object,
      default: {
        usable: 1
      }
    },
    // 父组件绑定值
    modelValue: {
      type: String
    },
    // 初始化显示内容  组件单独使用时
    initShow: {
      type: [String, Number]
    }
  },
  // 默认会有一个选项改变事件 change
  /**
   * queryAgain:选中后再次点击搜索
   */
  emits: ['update:modelValue', 'blur', 'focus', 'input', 'change', 'clear', 'queryAgain'],
  setup(props, {
    emit
  }) {
    const state = reactive({
      inputRef: null,
      dropdownRef: null,
      menuRef: null,
      inputValue: '',
      inputText: props.placeholder,
      iconClass: 'iconClass',
      loadFlag: false,
      count: 0,
      visible: false,
      empty: false,
      selectValue: null,
      selectStyle: null,
      clearFlag: true,
      query: {
        pageNum: 1,
        pageSize: props.pageSize
      },
      // 是否允许请求
      requestState: true,
      // 字段对应结果集key
      listKey: null,
      // 请求进行中
      loading: false,
      focusState: false
    });
    const sleep = time => {
      return new Promise(resolve => setTimeout(resolve, time));
    };
    const inputBlur = async e => {
      if (props.readonly || isBool(props.disabled, false)) return;
      // await sleep(100)
      state.iconClass = 'iconClass';
      state.empty = false;
      if (props.placeholder !== state.inputText) {
        if (props.modelItem[props.valueKey]) {
          // 当前行有选中项  失焦时恢复展示
          state.inputValue = state.inputText;
        } else {
          state.inputText = state.inputValue;
        }
      }
      if (props.blurFun) props.blurFun(props.modelField);
    };
    const inputFocus = e => {
      if (props.readonly || isBool(props.disabled, false)) return;
      state.focusState = true;
      if (props.modelItem[props.valueKey]) {
        // 暂时清空原值
        state.inputValue = '';
      }
      if (state.clearFlag) {
        state.iconClass = 'PointerIcon';
        state.visible = true;
        setTimeout(() => {
          //includes
          const childs = state['menuRef'].$el.children;
          if (childs.length === 1) {
            const className = childs[0].className;
            if (className.includes('no-data-menu')) {
              state.empty = true;
            }
          }
        }, 50);
      }
      if (props.focusFun) props.focusFun(props.modelField);
      if (!props.lazyLoad) return;
      // 聚焦时创建对应的查询字段和查询结果list
      if (!props.modelItem['query']) {
        props.modelItem['query'] = {
          [props.queryField]: null
        };
      }
      state.listKey = `${props.modelField}List`;
      if (!props.modelItem[state.listKey]) {
        props.modelItem[state.listKey] = [];
      }
    };
    const inputChange = val => {
      console.log('>>>>change:', state.inputValue);
    };
    const inputFun = val => {
      if (!props.lazyLoad) return;
      setTimeout(() => {
        if (val) {
          let flag = false;
          if (!props.modelItem['query']) {
            props.modelItem['query'] = {
              [props.queryField]: null
            };
          }
          // 允许请求 ----> 封装请求参数进行请求
          if (props.modelItem['query'][props.queryField] !== val) {
            // 请求参数改变--->清除结果集
            flag = true;
            props.modelItem['query'][props.queryField] = val;
          }
          // 存在搜索关键字
          if (state.requestState || flag) {
            queryRequest(flag);
          }
        }
      }, 10);
    };
    const queryRequest = async flag => {
      if (flag) {
        // 搜索改变
        state.requestState = true;
        state.loading = false;
        state.query.pageNum = 1;
        // 选中后重新搜索
        if (props.modelItem[props.valueKey]) {
          emit('queryAgain', props.modelField);
          // 清空搜索条件
          props.modelItem['query'] = {};
          props.modelItem['query'][props.queryField] = state.inputValue;
        }
      }
      if (state.loading) return;
      if (!state.requestState) return;
      state.loading = true;
      const searchData = {
        ...state.query,
        ...props.extraInfo,
        ...props.modelItem['query']
      };
      const {
        code,
        data
      } = await props.queryFun(searchData);
      state.loading = false;
      if (code === 200) {
        if (data.length < props.pageSize) {
          state.requestState = false;
        } else {
          // 没有触底  页码加1
          state.query.pageNum++;
        }
        if (!state.listKey) {
          state.listKey = `${props.modelField}List`;
          props.modelItem[state.listKey] = [];
        }
        if (searchData.pageNum === 1 || flag) {
          props.modelItem[state.listKey] = data;
        } else {
          props.modelItem[state.listKey].push(...data);
        }
        if (!state.visible) state['dropdownRef'].handleOpen();
      }
    };
    const inputClear = () => {
      if (!state.visible) state.clearFlag = false;
      state.inputValue = null;
      if (state.selectValue) {
        state.selectValue = null;
        if (state.selectStyle === null) state.selectStyle = {};
        state.selectStyle.color = 'black';
        state.selectStyle.fontWeight = 'normal';
        state.selectStyle = null;
      }
      state.inputText = props.placeholder;
      state.requestState = true;
      state.loading = false;
      state.query.pageNum = 1;
      emit('update:modelValue', '');
      emit('clear', props.modelField);
      setTimeout(() => {
        state.visible = true;
        state.clearFlag = true;
      }, 200);
    };
    const scrollLoad = () => {
      queryRequest(false);
    };
    const isBool = (val, type) => {
      if (val === true || val === false) return val;
      if (type) {
        if (!val) return false;
        return true;
      } else {
        if (!val) return true;
        return false;
      }
    };
    watch(() => toRefs(props.modelItem)[state.listKey], newVal => {
      if (props.modelItem[state.listKey] && props.modelItem[state.listKey].length > 0) {
        state.empty = false;
      } else {
        state.empty = true;
      }
    });
    const downCommand = target => {
      const key = target['key'];
      state.inputValue = target[props.labelField];
      state.inputText = target[props.labelField];
      const value = target[props.valueField];
      if (state.selectValue !== key) {
        if (state.selectStyle) {
          state.selectStyle.color = '#606266';
          state.selectStyle.fontWeight = 'inherit';
        }
        let child = state['menuRef'].$el.children[key];
        state.selectStyle = child.style;
        child.style.color = '#41B584';
        child.style.fontWeight = '900';
        state.selectValue = key;
        emit('update:modelValue', value);
        emit('change', props.modelField, value, target);
      }
      setTimeout(() => {
        state['inputRef'].blur();
      }, 10);
    };
    const visibleChange = flag => {
      state.visible = flag;
      if (flag) {
        inputFocus();
      } else {
        inputBlur();
      }
    };
    watch(() => toRef(props.modelItem[props.valueKey]), newVal => {
      if (newVal.value) {
        state.inputText = props.modelItem[props.modelField];
        state.inputValue = state.inputText;
      } else {
        if (state.inputValue) {
          if (props.modelItem['query'] && props.modelItem['query'][props.queryField] !== state.inputValue) {
            state.inputText = props.placeholder;
            state.inputValue = '';
          }
        }
        if (state.selectValue > -1 && state.selectStyle) {
          state.selectStyle.color = 'black';
          state.selectStyle.fontWeight = 'normal';
          state.selectStyle = null;
          state.selectValue = -1;
        }
      }
    });
    onMounted(() => {
      const value = useVModel(props, 'modelValue', emit);
      if (props.initShow) {
        state.inputText = props.initShow;
        state.inputValue = props.initShow;
        state.selectValue = -1;
      } else {
        if (value.value) {
          state.inputText = value.value;
          state.inputValue = value.value;
          state.selectValue = -1;
        }
      }
    });
    return {
      ...toRefs(state),
      props,
      Search,
      Plus,
      inputBlur,
      inputFocus,
      inputChange,
      inputFun,
      inputClear,
      scrollLoad,
      isBool,
      downCommand,
      visibleChange
    };
  }
});