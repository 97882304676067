import { toRefs, watch } from "vue";
import { Edit, Refresh, Pointer } from '@element-plus/icons-vue';
import { getOrderApproveNodes, approveNode, getHistoryFlow } from '@/api/system/approve';

import { defineComponent, inject, reactive } from 'vue';
export default defineComponent({
  name: "ApproveStep",
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  emits: ['change'],
  setup(props, {
    emit
  }) {
    const $baseMessage = inject('$baseMessage');
    const state = reactive({
      nodeInfo: {
        nodeLevel: 1,
        nodes: []
      },
      hisFlowList: [],
      listLoading: false,
      approvePopover: false,
      btnLoading: false,
      dialogVisible: false,
      hisLoading: false,
      divLoading: false
    });
    const getNodeList = async () => {
      if (state.listLoading) return;
      if (state.nodeInfo.approveState === 4) return;
      state.listLoading = true;
      const {
        data,
        code
      } = await getOrderApproveNodes({
        'orderCode': props.data.orderCode
      });
      if (code === 200) {
        state.nodeInfo = data;
      }
      state.listLoading = false;
    };
    watch(() => toRefs(props.data)['orderCode'], newVal => {
      if (props.data.orderCode) {
        getNodeList();
      }
    }, {
      immediate: true,
      deep: true
    });
    const approve = async (result, item) => {
      let data = {
        ...props.data
      };
      data.approveResult = result;
      data.approveRemark = item.wfnRemark;
      data.nodeLevel = item.wfnLevel;
      data.wfcCode = state.nodeInfo.wfcCode;
      data.wfCode = state.nodeInfo.wfCode;
      data.maxLevel = state.nodeInfo.maxLevel;
      state.btnLoading = true;
      const {
        code
      } = await approveNode(data);
      if (code === 200) {
        $baseMessage('审核成功', 'success', 'vab-hey-message-success');
        state.approvePopover = false;
        if (data.nodeLevel === data.maxLevel - 1) {
          emit('refreshTotal', state.total);
        }
        await getNodeList();
      }
      state.btnLoading = false;
    };
    const historyFlow = async () => {
      state.divLoading = true;
      const {
        data,
        code
      } = await getHistoryFlow({
        'orderCode': props.data.orderCode
      });
      if (code === 200) {
        state.hisFlowList = data;
      }
      state.divLoading = false;
    };
    const clickHistoryFlow = async () => {
      state.hisLoading = true;
      await historyFlow();
      state.dialogVisible = true;
      state.hisLoading = false;
    };
    const close = val => {
      state.dialogVisible = false;
    };
    return {
      ...toRefs(state),
      getNodeList,
      approve,
      historyFlow,
      clickHistoryFlow,
      close,
      Edit,
      Refresh,
      Pointer
    };
  }
});