import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-flow": "row wrap",
    "margin-top": "5px"
  }
};
const _hoisted_2 = {
  style: {
    "margin-right": "10px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  const _component_el_button = _resolveComponent("el-button");
  const _component_ape_dialog = _resolveComponent("ape-dialog");
  return _openBlock(), _createBlock(_component_ape_dialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.dialogVisible = $event),
    "confirm-loading": _ctx.btnLoading,
    "footer-slot": "",
    title: _ctx.title,
    onClose: _ctx.close
  }, {
    footer: _withCtx(() => [_ctx.approveType === 1 ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      loading: _ctx.btnLoading,
      type: "primary",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.approve(1))
    }, {
      default: _withCtx(() => [_createTextVNode(" 同 意 ")]),
      _: 1
    }, 8, ["loading"])) : _createCommentVNode("", true), _ctx.approveType === 1 && _ctx.showRefuse ? (_openBlock(), _createBlock(_component_el_button, {
      key: 1,
      loading: _ctx.btnLoading,
      type: "danger",
      onClick: _cache[2] || (_cache[2] = $event => _ctx.approve(2))
    }, {
      default: _withCtx(() => [_createTextVNode(" 拒 绝 ")]),
      _: 1
    }, 8, ["loading"])) : _createCommentVNode("", true), _ctx.approveType === 2 ? (_openBlock(), _createBlock(_component_el_button, {
      key: 2,
      loading: _ctx.btnLoading,
      type: "warning",
      onClick: _cache[3] || (_cache[3] = $event => _ctx.undone())
    }, {
      default: _withCtx(() => [_createTextVNode(" 撤销 ")]),
      _: 1
    }, 8, ["loading"])) : _createCommentVNode("", true)]),
    default: _withCtx(() => [_createVNode(_component_el_descriptions, {
      column: 1
    }, {
      default: _withCtx(() => [_createVNode(_component_el_descriptions_item, null, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: _ctx.nodeInfo.approveRemark,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.nodeInfo.approveRemark = $event),
          placeholder: '请输入' + _ctx.textName + '意见',
          rows: 4,
          "show-word-limit": true,
          type: "textarea"
        }, null, 8, ["modelValue", "placeholder"])]),
        _: 1
      }), _ctx.approveType === 1 ? (_openBlock(), _createBlock(_component_el_descriptions_item, {
        key: 0,
        label: _ctx.textName + '单据'
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderList, (e, i) => {
          return _openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.APPROVE_STATE_NOT.indexOf(e.approveState) > -1 ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            class: "box-item",
            content: "您无该订单的审核权限，批量审核时将会过滤",
            effect: "dark",
            placement: "top",
            disabled: _ctx.nodeUserData[e[_ctx.orderKey]] === 1
          }, {
            default: _withCtx(() => [_createVNode(_component_el_text, {
              tag: _ctx.nodeUserData[e[_ctx.orderKey]] === 1 ? 'p' : 'mark'
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(e[_ctx.orderKey]), 1)]),
              _: 2
            }, 1032, ["tag"])]),
            _: 2
          }, 1032, ["disabled"])) : (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 1,
            class: "box-item",
            content: "该单据已审核,批量审核时将会过滤",
            effect: "dark",
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_text, {
              tag: "del",
              type: "danger"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(e[_ctx.orderKey]), 1)]),
              _: 2
            }, 1024)]),
            _: 2
          }, 1024))]);
        }), 256))])]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "confirm-loading", "title", "onClose"]);
}