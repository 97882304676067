import "core-js/modules/es.array.push.js";
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-742f6b0d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "p-1 mb-1",
  style: {
    "padding-bottom": "0",
    "border": "1px solid rgb(234, 238, 251)",
    "min-height": "100px"
  }
};
const _hoisted_2 = {
  class: "d-flex flex-wrap p-1",
  style: {
    "padding-bottom": "0",
    "border": "1px solid rgb(234, 238, 251)"
  }
};
import { baseURL, tokenName } from '@/config';
import { useUserStore } from '@/store/modules/user';
import Draggable from 'vuedraggable';
import ApeDialog from '@/plugins/ApeDialog/index.vue';
import { getNowTimes } from '@/utils/dateUtils';
import { gp } from "@gp";
import { exportExcel } from "@/api/system/printExcel";

import { inject, ref, computed } from 'vue';
export default {
  __name: 'index',
  props: {
    fileName: {
      type: String,
      default: ''
    },
    showTimes: {
      type: Boolean,
      default: true
    },
    // 1:释放查询参数 2：查询参数单独封装
    type: {
      type: Number,
      default: 1
    },
    // 表头是否合并
    headerMerge: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, {
    expose: __expose
  }) {
    const props = __props;
    const $baseMessage = inject("$baseMessage");
    const userStore = useUserStore();
    const {
      token
    } = userStore;
    const exportDialog = ref(false);
    const submitLoading = ref(false);
    const list = ref([]);
    const showList = ref([]);
    const requestApi = ref('');
    const exportType = ref('');
    const queryForm = ref({});
    const headers = ref({
      [tokenName]: `${token}`
    });
    const searchData = ref({
      fileName: '',
      headList: [],
      paramMap: {}
    });
    const open = async (type, exportHead, query, url) => {
      requestApi.value = url;
      exportType.value = type;
      if (props.type === 1) {
        queryForm.value = query;
      } else {
        searchData.value.paramMap = query;
        searchData.value.paramMap.pageFlag = false;
      }
      if (type === '') {
        list.value = exportHead;
        excel();
      } else {
        exportDialog.value = true;
        list.value = JSON.parse(JSON.stringify(exportHead));
        let localList = [];
        /**
         * 保证首次必须要有
         */
        if (localStorage.getItem('EXCEL_EXPORT')) {
          localList = JSON.parse(localStorage.getItem('EXCEL_EXPORT'))[type];
        }
        if (localList && localList.length > 0) {
          const arr = localList.map(v => v.label);
          list.value.forEach(v => {
            v.value = !!arr.includes(v.label);
            showList.value = localList;
          });
        } else {
          list.value.forEach(v => {
            v.value = true;
            showList.value.push({
              label: v.label,
              popr: v.popr
            });
          });
        }
      }
    };
    const sort = async () => {
      list.value.forEach(v => v.value = true);
      showList.value = [];
      showList.value = JSON.parse(JSON.stringify(list.value));
    };
    const select = item => {
      item.value = !item.value;
      if (item.value) {
        showList.value.push({
          label: item.label,
          popr: item.popr
        });
      } else {
        const index = showList.value.findIndex(v => v.label === item.label);
        if (index > -1) {
          showList.value.splice(index, 1);
        }
      }
    };
    const tagClose = (label, index) => {
      showList.value.splice(index, 1);
      list.value.forEach(v => {
        if (v.label === label) {
          v.value = false;
        }
      });
    };
    const close = () => {
      exportDialog.value = false;
    };
    const save = async () => {
      if (props.type === 1) {
        queryForm.value.headList = showList.value;
      } else {
        searchData.value.headList = showList.value;
      }
      if (exportType.value) {
        const temp = JSON.parse(localStorage.getItem('EXCEL_EXPORT')) || {};
        temp[exportType.value] = showList.value;
        localStorage.setItem('EXCEL_EXPORT', JSON.stringify(temp));
      }
      await excel();
    };
    const excel = async () => {
      submitLoading.value = true;
      queryForm.value.fileName = props.fileName;
      searchData.value.fileName = props.fileName;
      queryForm.value.headerMerge = props.headerMerge;
      searchData.value.headerMerge = props.headerMerge;
      if (props.headerMerge) {
        searchData.value.headList = list.value;
      }
      const {
        data,
        headers: header
      } = await exportExcel(requestApi.value, props.type === 1 ? queryForm.value : searchData.value, {
        responseType: "blob",
        headers: headers.value
      });
      if (!data.code) {
        const blob = new Blob([data], {
          type: header["content-type"]
        });
        const fileName = decodeURI(header["content-disposition"]).split(";")[1].split("=")[1];
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = e => {
          const a = document.createElement("a");
          a.download = fileName;
          a.href = e.target.result;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        };
        $baseMessage("导出成功", "success", "vab-hey-message-success");
        close();
        submitLoading.value = false;
      }
    };
    const dragOptions = computed(() => {
      return {
        sort: true,
        scroll: true,
        scrollSpeed: 2,
        animation: 150,
        filter: '.disabled',
        ghostClass: 'dragable-ghost',
        chosenClass: 'dragable-chose',
        dragClass: 'dragable-drag'
      };
    });
    __expose({
      open
    });
    return (_ctx, _cache) => {
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_tooltip = _resolveComponent("el-tooltip");
      return _openBlock(), _createBlock(ApeDialog, {
        modelValue: _unref(exportDialog),
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(exportDialog) ? exportDialog.value = $event : null),
        "confirm-loading": _unref(submitLoading),
        "footer-slot": true,
        title: __props.fileName + '导出',
        width: '1000px',
        onClose: close,
        onConfirm: save
      }, {
        footer: _withCtx(() => [_createVNode(_component_el_button, {
          type: "info",
          onClick: sort
        }, {
          default: _withCtx(() => [_createTextVNode("重置顺序")]),
          _: 1
        }), _createVNode(_component_el_button, {
          loading: _unref(submitLoading),
          type: "primary",
          onClick: _cache[1] || (_cache[1] = $event => save())
        }, {
          default: _withCtx(() => [_createTextVNode(" 导出 ")]),
          _: 1
        }, 8, ["loading"])]),
        default: _withCtx(() => [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_unref(Draggable), {
          modelValue: _unref(showList),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(showList) ? showList.value = $event : null),
          class: "d-flex flex-wrap",
          "item-key": "label",
          options: _unref(dragOptions)
        }, {
          item: _withCtx(({
            element,
            index
          }) => [_createVNode(_component_el_tag, {
            class: "mb-1",
            closable: "",
            size: "default",
            type: "success",
            style: {
              "cursor": "pointer"
            },
            onClose: $event => tagClose(element.label, index)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(element.label), 1)]),
            _: 2
          }, 1032, ["onClose"])]),
          _: 1
        }, 8, ["modelValue", "options"])]), _createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index,
            class: "mr-1 mb-1"
          }, [_createVNode(_component_el_tooltip, {
            content: item.value ? '点击取消' : '点击选中',
            placement: "top"
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              plain: !item.value,
              size: "default",
              type: item.value ? 'primary' : 'info',
              onClick: $event => select(item)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["plain", "type", "onClick"])]),
            _: 2
          }, 1032, ["content"])]);
        }), 128))])])]),
        _: 1
      }, 8, ["modelValue", "confirm-loading", "title"]);
    };
  }
};