import { baseURL, tokenName } from '@/config';
import { useUserStore } from "@/store/modules/user";

import { inject, reactive, toRefs } from 'vue';
export default {
  name: "UploadFile",
  props: {
    // 上传地址
    url: {
      type: String,
      required: true
    },
    // 提示文字 默认文件上传
    content: {
      type: String,
      default: '文件上传'
    },
    // 上传附带参数
    data: {
      type: Object,
      default: {}
    },
    // 文件类型限制  默认只能上传 xlsx,xls
    fileType: {
      type: String,
      default: 'xlsx,xls'
    }
  },
  // startUpload():开始上传时  endUpload(code):上传结束时 code===200:上传成功
  emits: ['startUpload', 'endUpload'],
  setup(props, {
    emit
  }) {
    const $baseMessage = inject('$baseMessage');
    const userStore = useUserStore();
    const {
      token
    } = userStore;
    const state = reactive({
      headers: {
        [tokenName]: `${token}`
      },
      action: baseURL + props.url,
      fileList: []
    });
    const beforeAvatarUpload = file => {
      // emit('startUpload')
      let name = file.name;
      const suffix = name.substring(name.lastIndexOf(".") + 1);
      console.log(">>>>", suffix);
      if (props.fileType.includes(suffix)) {
        return true;
      }
      $baseMessage(`请上传${props.fileType}类型文件上传`, 'error', 'vab-hey-message-error');
      return false;
    };
    const handleAvatarSuccess = (data, file, files) => {
      emit('endUpload', data.code);
      if (data.code === 200) {
        $baseMessage(`上传成功`, 'success', 'vab-hey-message-success');
        return;
      }
      $baseMessage(`上传失败,${data.message}`, 'error', 'vab-hey-message-error');
    };
    const handleAvatarError = (err, file, files) => {
      emit('endUpload', 202);
      $baseMessage(`上传失败,请检查网络或查看服务是否正常`, 'error', 'vab-hey-message-error');
    };
    const handleChange = (file, fileList) => {};
    return {
      ...toRefs(state),
      beforeAvatarUpload,
      handleAvatarSuccess,
      handleChange,
      handleAvatarError
    };
  }
};