import "core-js/modules/es.array.push.js";
import { __awaiter, __generator, __read, __spreadArray } from "tslib";
import { useUserStore } from '@/store/modules/user';
import { baseURL, contentType, debounce, messageName, requestTimeout, statusName, successCode, tokenName } from '@/config';
import qs from 'qs';
import router from '@/router';
import { isArray } from '@/utils/validate';
import { addErrorLog, needErrorLog } from '@vab/plugins/errorLog';
import { refreshToken } from '@/api/refreshToken';
import { gp } from '@gp';
var loadingInstance;
var refreshToking = false;
var requests = [];
// 操作正常Code数组
var codeVerificationArray = isArray(successCode) ? __spreadArray([], __read(successCode), false) : [successCode];
var CODE_MESSAGE = {
  200: '服务器成功返回请求数据',
  201: '新建或修改数据成功',
  202: '服务器开小差了,请稍后重试!',
  203: '登录相关异常',
  204: '登录失效，请重新登录',
  205: '令牌过期,刷新token',
  400: '发出信息有误',
  401: '用户没有权限(令牌失效、用户名、密码错误、登录过期)',
  402: '令牌过期',
  403: '用户得到授权，但是访问是被禁止的',
  404: '访问资源不存在',
  406: '请求格式不可得',
  410: '请求资源被永久删除，且不会被看到',
  500: '服务器发生错误',
  502: '网关错误',
  503: '服务不可用，服务器暂时过载或维护',
  504: '网关超时'
};
/**
 * axios请求拦截器配置
 * @param config
 * @returns {any}
 */
var requestConf = function (config) {
  var userStore = useUserStore();
  var token = userStore.token;
  // 不规范写法 可根据setting.config.js tokenName配置随意自定义headers
  // if (token) config.headers[tokenName] = token
  // 规范写法 不可随意自定义
  if (token) config.headers[tokenName] = "".concat(token);
  // 控制是否自动提示错误
  if (!config.params) {
    config.params = {
      showError: true
    };
  }
  // if (config.responseType&&config.responseType==='blob'){
  //   config.headers['Content-Type']='multipart/form-data'
  // }
  if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') config.data = qs.stringify(config.data);
  if (debounce.some(function (item) {
    return config.url.includes(item);
  })) loadingInstance = gp.$baseLoading();
  return config;
};
/**
 * 刷新刷新令牌
 * @param config 过期请求配置
 * @returns {any} 返回结果
 */
var tryRefreshToken = function (config) {
  return __awaiter(void 0, void 0, void 0, function () {
    var token_1, setToken, error_1, resetAll_1;
    return __generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          if (!!refreshToking) return [3 /*break*/, 6];
          refreshToking = true;
          _a.label = 1;
        case 1:
          _a.trys.push([1, 3, 4, 5]);
          return [4 /*yield*/, refreshToken()];
        case 2:
          token_1 = _a.sent().data.token;
          if (token_1) {
            setToken = useUserStore().setToken;
            setToken(token_1);
            // 已经刷新了token，将所有队列中的请求进行重试
            requests.forEach(function (cb) {
              return cb(token_1);
            });
            requests = [];
            return [2 /*return*/, instance(requestConf(config))];
          }
          return [3 /*break*/, 5];
        case 3:
          error_1 = _a.sent();
          resetAll_1 = useUserStore().resetAll;
          router.push({
            path: '/login',
            replace: true
          }).then(function () {
            resetAll_1().then(function () {});
          });
          return [3 /*break*/, 5];
        case 4:
          refreshToking = false;
          return [7 /*endfinally*/];
        case 5:
          return [3 /*break*/, 7];
        case 6:
          return [2 /*return*/, new Promise(function (resolve) {
            // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
            requests.push(function () {
              resolve(instance(requestConf(config)));
            });
          })];
        case 7:
          return [2 /*return*/];
      }
    });
  });
};
/**
 * axios响应拦截器
 * @param config 请求配置
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @param headers headers
 * @returns {Promise<*|*>}
 */
var handleData = function (_a) {
  var config = _a.config,
    data = _a.data,
    status = _a.status,
    statusText = _a.statusText,
    headers = _a.headers;
  return __awaiter(void 0, void 0, void 0, function () {
    var _b, resetAll, systemSet, code, _c, errMsg;
    return __generator(this, function (_d) {
      switch (_d.label) {
        case 0:
          _b = useUserStore(), resetAll = _b.resetAll, systemSet = _b.systemSet;
          if (loadingInstance) loadingInstance.close();
          code = data && data[statusName] ? data[statusName] : status;
          // 若code属于操作正常code，则status修改为200
          if (codeVerificationArray.indexOf(data[statusName]) + 1) code = 200;
          if (!(config.responseType === 'blob')) return [3 /*break*/, 3];
          if (!(data.type === "application/json")) return [3 /*break*/, 2];
          return [4 /*yield*/, handleBlob(data)];
        case 1:
          _d.sent();
          code = data.code;
          _d.label = 2;
        case 2:
          if (code === 200) {
            return [2 /*return*/, {
              'data': data,
              'headers': headers,
              'systemSet': systemSet
            }];
          }
          _d.label = 3;
        case 3:
          _c = code;
          switch (_c) {
            case 200:
              return [3 /*break*/, 4];
            case 204:
              return [3 /*break*/, 5];
            case 205:
              return [3 /*break*/, 6];
            case 403:
              return [3 /*break*/, 8];
          }
          return [3 /*break*/, 9];
        case 4:
          return [2 /*return*/, data
          // 重新登录
          ];

        case 5:
          router.push({
            path: '/login',
            replace: true
          }).then(function () {
            resetAll().then(function () {});
          });
          return [3 /*break*/, 9];
        case 6:
          return [4 /*yield*/, tryRefreshToken(config)];
        case 7:
          return [2 /*return*/, _d.sent()];
        case 8:
          router.push({
            path: '/403'
          }).then(function () {});
          return [3 /*break*/, 9];
        case 9:
          errMsg = "".concat(data && data[messageName] ? data[messageName] : CODE_MESSAGE[code] ? CODE_MESSAGE[code] : statusText);
          // 是否显示高亮错误(与errorHandler钩子触发逻辑一致)
          if (config.params.showError) gp.$baseMessage(errMsg, 'error', 'vab-hey-message-error', false);
          if (needErrorLog()) addErrorLog({
            message: errMsg,
            stack: data,
            isRequest: true
          });
          // return Promise.reject(data)
          return [2 /*return*/, data];
      }
    });
  });
};
var handleBlob = function (data) {
  return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
      return [2 /*return*/, new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.readAsText(data, 'utf-8');
        reader.onload = function () {
          return __awaiter(this, void 0, void 0, function () {
            var _a, code, message;
            return __generator(this, function (_b) {
              if (typeof reader.result === 'string') {
                _a = JSON.parse(reader.result), code = _a.code, message = _a.message;
                if (message) data[messageName] = message;
                data.code = code;
                resolve();
              }
              return [2 /*return*/];
            });
          });
        };
      })];
    });
  });
};
/**
 * @description axios初始化
 */
var instance = axios.create({
  baseURL: baseURL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType
  }
});
/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(requestConf, function (error) {
  return Promise.reject(error);
});
/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(function (response) {
  return handleData(response);
}, function (error) {
  var response = error.response;
  if (response === undefined) {
    if (loadingInstance) loadingInstance.close();
    gp.$baseMessage('服务器开小差了！', 'error', 'vab-hey-message-error', false);
    return {};
  } else {
    return handleData(response);
  }
});
export default instance;