/**
 * 自定义工具类
 */
import { isArray } from 'lodash-es'
import { translate } from '@/i18n'
import _ from 'lodash'

// 数组处理成','隔开字符串
export function dealArrToStr(list: (string | number | object)[]) {
  let result = ''
  list.forEach((item) => (result += `,${item}`))
  return result.substring(1)
}

// 厘米转磅
export function CMToPounds(value: number) {
  const result = (value * 100 * 72) / 25
  return Math.round(result * 100) / 100
}

// 数字和小数校验
export const floatCheck = (value: string | number) => {
  const posPattern = /^\d*\.?\d+$/
  return posPattern.test(<string>value)
}

/**
 * 数字输入框聚焦 事件  0-->''
 * @param e
 */
export function focusNumber(e: any, row: any, lastValueField: string) {
  if (e.target.value === 0 || e.target.value === '0') {
    e.target.value = ''
    e.target.placeholder = 0
    row[lastValueField] = 0
  } else {
    e.target.placeholder = e.target.value
    e.target.value = ''
    row[lastValueField] = e.target.value
  }
}

/**
 * 数字输入框失去焦点事件
 * @param row 当前行或表单对象
 * @param e 输入框失焦参数
 * @param lastValue 上次记录值
 * @param $baseMessage
 */
export function blurNumber(
  row: any,
  e: any,
  lastValueField: string,
  $baseMessage: Function
) {
  if (e.target.value === '' || !e.target.value) {
    if (e.target.placeholder) {
      e.target.value = e.target.placeholder
    } else {
      e.target.value = 0
    }
  }
  if (!floatCheck(e.target.value)) {
    $baseMessage('请填写数字', 'error', 'vab-hey-message-error')
    e.target.value = row[lastValueField]
  }
}
// 原料类别
export function rawTypeCover(rawType: number) {
  let str = ''
  switch (rawType) {
    case 1:
      str = '板'
      break
    case 2:
      str = '加工板'
      break
    case 3:
      str = '纸'
      break
    case 4:
      str = '加工纸'
      break
    case 5:
      str = '封边条'
      break
    case 6:
      str = '低耗'
      break
  }
  return str
}

// 去除各种空格
export function trimAll(value: string | object | number) {
  if (typeof value === 'string') {
    return value.split(/[\t\r\f\n\s]*/g).join('')
  }
  return value
}

/**
 * 判断用户那些项没有输入
 * @param obj 需要查看的表单对象,必填在前，非必填再后
 * @returns 没输入的第一项
 */
export function findFirstEmptyProperty(obj: any) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value === null || value === undefined || value === '') {
        return key
      }
    }
  }

  return null // 如果没有空值属性，则返回 null
}

/**
 * 两个对象同键赋值
 * @param from 最新数据对象
 * @param to 被赋值数据对象
 * @param force true:强制赋值，无属性则添加属性
 */
export function assignValues(from: any, to: any, force = false) {
  for (const key in from) {
    if (force) {
      to[key] = from[key]
    } else {
      if (key in to) {
        to[key] = from[key]
      }
    }
  }
}

/**
 * 根据typeCode寻找typeName
 * @param array 类别数组
 * @param property
 * @param value
 * @returns
 */
export function findTypeNameByTypeCode(
  array: any,
  value: any,
  property?: string
) {
  if (property) {
    const foundItem = array.find((item: any) => item[property] === value)
    return foundItem ? foundItem.typeName : null
  }
  const foundItem = array.find((item: any) => item.typeCode === value)
  return foundItem ? foundItem.typeName : null
}

/**
 * 判断主对象除去副对象中任意一项为空就返回false
 * @param bigObject  主对象
 * @param smallObject 副对象
 * @returns
 */
export function isObjectComplete(bigObject: any, smallObject: any) {
  for (const key in bigObject) {
    if (smallObject.hasOwnProperty(key)) {
      continue // 跳过小对象中已包含的键
    }

    const value = bigObject[key]
    if (value === null || value === '' || typeof value === 'undefined') {
      return false // 如果任一项值为空，则返回 false
    }
  }

  return true // 所有非小对象包含的键都有值，返回 true
}

export function deepCopy(obj: any) {
  // 检查传入的参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // 根据 obj 的类型创建一个空的目标对象或数组
  const copy = Array.isArray(obj) ? [] : {}

  // 遍历 obj 的属性，并递归调用 deepCopy 进行深度复制
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      //@ts-ignore
      copy[key] = deepCopy(obj[key])
    }
  }

  return copy
}

/**
 * 根据值从数组中找出 描述
 * @param array 目标数组
 * @param value 值
 * @param property 值字段
 * @param labelField 描述字段
 */
export function findLabelByValueArray(
  array: any,
  value: any,
  property?: string,
  labelField?: string
) {
  if (!value) return null
  const valueField = property ? property : 'value'
  const label = labelField ? labelField : 'label'

  const foundItem = array.find((item: any) => item[valueField] === value)
  return foundItem ? foundItem[label] : null
}

/**
 * 根据值 从数组中查找并封装为对象数组
 * @param array 数组列表
 * @param values 值数组
 * @param property 值对应的字段
 * @param keys 封装对象中字段
 */
export function findObjByValueArray(
  array: any,
  values: any,
  property: string,
  keys: any
) {
  if (!values) return []
  const valueField = property ? property : 'value'
  const res = []
  // 已查找到的数量
  let count = 0
  if (keys.length < 2) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      if (values.includes(item[valueField])) {
        res.push(item[keys[0]])
        count++
      }
      if (count === values.length) break
    }
  } else {
    for (let i = 0; i < array.length; i++) {
      const item = array[i]
      if (values.includes(item[valueField])) {
        const obj = {}
        keys.forEach((key) => {
          obj[key] = item[key]
        })
        res.push(obj)
        count++
      }
      if (count === values.length) break
    }
  }
  return res
}

/**
 * 数组展示成字符串
 * @param array 数组
 * @param separator 连接符
 * @param field 展示字段
 */
export function arrShowStr(array: any, separator: string, field: string) {
  let str = ''
  if (!array || array.length < 1) return str
  array.forEach((item: any) => (str = str + separator + item[field]))
  return str.substring(separator.length)
}

//数量处理函数  null或'' 处理成0 , 最多保留两位小数采用四舍五入 , 去除小数点后无用的0
export function processNum(num: any) {
  if (num === null || num === '') {
    return 0
  } else {
    const value = parseFloat(num)
    if (!isNaN(value)) {
      const formattedValue = Math.floor(value * 100) / 100
      return formattedValue
    } else {
      return 0
    }
  }
}

//时间处理函数
export function getFormattedDate(fullDatetime: any, usable = true) {
  if (usable&&fullDatetime) {
    const datetimeParts = fullDatetime.split(' ')
    const datePart = datetimeParts[0]

    return datePart
  }
  return fullDatetime
}

/**
 * 比较两个值是否相等 默认 字符串比较
 * 字符串比较：两个值都会被转成字符串进行比较
 * 数值比较:都被转成数值进行比较 如果是 "" 或 null 将被视为0
 * @param value1
 * @param value2
 * @param type 1:字符串比较  2：数值比较
 */
export function compareValue(value1: any, value2: any, type = 1) {
  const v1 = !value1 ? (type === 1 ? '' : 0) : value1
  const v2 = !value2 ? (type === 1 ? '' : 0) : value2
  if (type === 2) {
    if (floatCheck(v1) && floatCheck(v2)) return Number(v1) - Number(v2) === 0
    return false
  }
  return v1 === v2
}

/**
 *
 * @param data 整个表单对象
 * @param dataOldKeys 表单中需要对比的上次值字段
 * @param dataKeys 表单中需要对比的字段
 * @param arrKey 如果存在列表-->列表字段名
 * @param arrOldKey 如果存在列表-->列表需要对比的上次值字段名
 * @param arrKeys 列表中需要对比字段
 * @param itemKey 列表修改信息中唯一标识
 */
export function createOrderUpdateInfo(data:object,dataOldKeys:any,dataKeys:any,arrKey:string,arrOldKeys:any,arrKeys:any,itemKey:any) {
  const infoArr = []
  dataKeys.forEach((key,index)=>{
    const oldKey = dataOldKeys[index]
    if (!compareValue(data[key], data[oldKey])) {
      const str = concatUpdateInfo(translate(key), data[oldKey], data[key])
      infoArr.push(str)
    }
  })
  let itemInfoArr=[]
  if (arrKey && data[arrKey] && data[arrKey].length>0){
    // 存在列表
    const arr = data[arrKey]
    arr.forEach((item,index)=>{
     const itemInfo = createOrderUpdateInfo(item,arrOldKeys,arrKeys,null,null,null,itemKey)
       const itemInfos = JSON.parse(itemInfo)
      if (itemInfos.length>0){
       const str = item[itemKey]+':'+itemInfo
       itemInfoArr.push(str)
     }
    })
  }
  if (itemInfoArr.length>0){
    infoArr.push("items:"+JSON.stringify(itemInfoArr))
  }
  return JSON.stringify(infoArr)
}
/**
 *
 * {
 *   suName:....,
 *   items:[]
 * }
 */
/**
 * 生成修改信息 单对象
 * @param oldData 原对象
 * @param newData 修改后数据对象
 * @param keys 需要对比的字段
 */
export function createUpdateInfo(oldData: object, newData: object, keys: any) {
  const infoArr = []
  if (keys && keys.length > 0) {
    keys.forEach((key) => {
      if (!compareValue(oldData[key], newData[key])) {
        const str = concatUpdateInfo(translate(key), oldData[key], newData[key])
        infoArr.push(str)
      }
    })
  }
  return JSON.stringify(infoArr)
}

const concatUpdateInfo = (filedName, oldValue, newValue) => {
  return `${filedName}:${oldValue}->${newValue}`
}

/**
 * 保存修改记录表(www)
 * @param updateRecordMap 记录对象
 * @param key 键
 * @param value 值
 * @param path 路径
 * @param index 下标
 */
export function saveUpdateRecordMap(
  updateRecordMap: object,
  key: string,
  value: string,
  path: string,
  index: string
) {
  if (index.length !== 0 && path.length !== 0) {
    const pwd = `${path}[${index}].${key}`
    updateRecordMap[pwd] = `${value}[${index}]`
  } else if (path) {
    const pwd = `${path}.${key}`
    updateRecordMap[pwd] = value
  } else {
    updateRecordMap[key] = value
  }
  return updateRecordMap
}

/**
 * 生成修改信息(www)
 * @param oldData 原对象
 * @param newData 修改后数据对象
 * @param keys 需要对比的字段
 */
export function getUpdateInfo(
  oldData: object,
  newData: object,
  updateRecordMap: object
) {
  const infoArr = []
  if (Object.keys(updateRecordMap).length === 0) return
  for (const key in updateRecordMap) {
    const str = concatUpdateInfo(
      _.merge(_.get(updateRecordMap, key)),
      _.merge(_.get(oldData, key)),
      _.merge(_.get(newData, key))
    )
    infoArr.push(str)
  }
  return JSON.stringify(infoArr)
}

/**
 * 删除对象指定属性
 * @param source
 * @param keys
 */
export function delPropertys(source: any, keys: any) {
  if (!source) return
  if (Array.isArray(keys)) {
    keys.forEach((key) => {
      if (key in source) delete source[key]
    })
  } else {
    delete source[keys]
  }
}

export function getTableHeader(tableRef: any) {
  const list = tableRef.store.states._columns._rawValue
  const tableHeaderList = []
  if (list && list.length > 0) {
    //递归出需要的表头数据 放进数组里
    list.forEach((column, index) => {
      //这里根据需求有条件去取舍 如果是多级表头的是 判断是否有子表头：column.children.length >0 有的话再往里放
      if (column.property) {
        //这里根据需要放入需要的相关 参数
        tableHeaderList.push({
          popr: column.property,
          label: column.label,
        })
      }
    })
  }
  return tableHeaderList
}

export function createCustomUpdateInfo(data:object,dataOldKeys:any,dataKeys:any,
                                       arrKey:string,arrOldKeys:any,arrKeys:any,itemKey:any,
                                       optionDesc:string,optionType:string){
  const info = createOrderUpdateInfo(data, dataOldKeys, dataKeys, arrKey, arrOldKeys, arrKeys, itemKey)
  if (info==='[]')return null
  return  {
    'optionInfo':info,
    'optionDesc':optionDesc,
    'optionType':optionType
  }
}

export function createCustomUpdateInfoSingle(data:object,dataOldKeys:any,dataKeys:any,
                                       optionDesc:string,optionType:string){
  const infoArr = []
  if (dataKeys && dataKeys.length > 0) {
    dataKeys.forEach((key,index)=>{
      const oldKey = dataOldKeys[index]
      if (!compareValue(data[key], data[oldKey])) {
        const str = concatUpdateInfo(translate(key), data[oldKey], data[key])
        infoArr.push(str)
      }
    })
  }
  if (infoArr.length<1)return null
  return  {
    'optionInfo':JSON.stringify(infoArr),
    'optionDesc':optionDesc,
    'optionType':optionType
  }
}
