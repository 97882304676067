import "core-js/modules/es.array.push.js";
import { Delete, Plus } from '@element-plus/icons-vue';
import Sortable from 'sortablejs';

import { defineComponent, reactive, nextTick, onMounted, watch, toRefs } from 'vue';
export default defineComponent({
  name: 'VabFormTable',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    rowTemplate: {
      type: Object,
      default: () => {}
    },
    drag: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, {
    emit
  }) {
    const state = reactive({
      tableRef: null,
      data: [],
      toggleIndex: 0
    });
    const rowDrop = () => {
      const tbody = state.tableRef.$el.querySelector('.el-table__body-wrapper tbody');
      Sortable.create(tbody, {
        handle: '.vab-rank',
        animation: 300,
        onEnd({
          newIndex,
          oldIndex
        }) {
          const tableData = state.data;
          const currRow = tableData.splice(oldIndex, 1)[0];
          tableData.splice(newIndex, 0, currRow);
          state.toggleIndex += 1;
          nextTick(() => {
            rowDrop();
          });
        }
      });
    };
    const handleAdd = () => {
      state.data.push(JSON.parse(JSON.stringify(props.rowTemplate)));
    };
    const handleDelete = (row, index) => {
      state.data.splice(index, 1);
    };
    onMounted(() => {
      state.data = props.modelValue;
      if (props.drag) rowDrop();
    });
    watch(() => props.modelValue, () => state.data = props.modelValue);
    watch(() => state.data, () => emit('update:modelValue', state.data));
    return {
      ...toRefs(state),
      rowDrop,
      handleAdd,
      handleDelete,
      Delete,
      Plus
    };
  }
});