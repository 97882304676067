import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_backtop = _resolveComponent("el-backtop");
  var _component_vab_theme_drawer = _resolveComponent("vab-theme-drawer");
  var _component_vab_theme_setting = _resolveComponent("vab-theme-setting");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vue-admin-better-wrapper", {
      mobile: _ctx.mobile
    }])
  }, [(_openBlock(), _createBlock(_resolveDynamicComponent('vab-layout-' + _ctx.theme.layout), {
    collapse: _ctx.collapse,
    device: _ctx.device,
    "fixed-header": _ctx.theme.fixedHeader,
    "show-tabs": _ctx.theme.showTabs
  }, null, 8, ["collapse", "device", "fixed-header", "show-tabs"])), _createVNode(_component_el_backtop, {
    target: "#app"
  }), _createVNode(_component_vab_theme_drawer), _createVNode(_component_vab_theme_setting)], 2);
}