import { App } from 'vue'
import { gp } from '@gp'
import router from '@/router'
// App是用来确定类型
export default function registerProperties(app: App) {
  app.config.globalProperties.$Methods = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    async print(params = {}, printFun: Function) {
      const { data,systemSet:{printView} } = await printFun(params, {
        responseType: 'blob',
      })
     if (!data.code){
       const binaryData = []
       binaryData.push(data)
       const pdfUrl = window.URL.createObjectURL(
         new Blob(binaryData, {
           type: 'application/pdf',
         })
       )
       if (printView){
         const state = {
           pdfUrl: pdfUrl
         }
         router.push({
           path: '/orderPrint',
           state,
         })
       }else{
         window.open(pdfUrl);
       }
       // const state = {
       //   pdfUrl: `/static/pdf/web/viewer.html?file=${encodeURIComponent(
       //     pdfUrl
       //   )}`,
       // }
     }
    },
    // Print.prototype = {
    //   /*
    //    *文件请求地址 将返回的流数据转换为url
    //    */
    //   getObjectURL: function (file) {
    //     let url = null;
    //     if (window.createObjectURL != undefined) { // basic
    //       url = window.createObjectURL(file);
    //     } else if (window.webkitURL != undefined) { // webkit or chrome
    //       try {
    //         url = window.webkitURL.createObjectURL(file);
    //       } catch (error) {
    //
    //       }
    //     } else if (window.URL != undefined) { // mozilla(firefox)
    //       try {
    //         url = window.URL.createObjectURL(file);
    //       } catch (error) {
    //
    //       }
    //     }
    //     return url;
    //   }
    // },
  }
}
