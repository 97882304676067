import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0
};
var _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createElementBlock("div", null, [_ctx.monthrange ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_date_picker, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return _ctx.value = $event;
    }),
    modelModifiers: {
      trim: true
    },
    type: "daterange",
    "unlink-panels": "",
    "range-separator": "至",
    "start-placeholder": "开始日期",
    "end-placeholder": "结束日期",
    shortcuts: _ctx.shortcuts,
    size: "default",
    style: {
      "width": "220px"
    },
    clearable: _ctx.clearable,
    format: "YYYY-MM-DD",
    "value-format": "YYYY-MM-DD",
    onChange: _cache[1] || (_cache[1] = function ($event) {
      return _ctx.valChange(_ctx.value);
    }),
    "disabled-date": _ctx.disabledDate
  }, null, 8, ["modelValue", "shortcuts", "clearable", "disabled-date"])])) : _createCommentVNode("", true), !_ctx.monthrange ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_date_picker, {
    modelValue: _ctx.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
      return _ctx.value = $event;
    }),
    modelModifiers: {
      trim: true
    },
    type: "date",
    placeholder: "请选择日期",
    "disabled-date": _ctx.disabledDate,
    shortcuts: _ctx.timeShortcuts,
    style: {
      "width": "150px"
    },
    "value-format": "YYYY-MM-DD",
    onChange: _cache[3] || (_cache[3] = function ($event) {
      return _ctx.valChange(_ctx.value);
    }),
    clearable: _ctx.clearable
  }, null, 8, ["modelValue", "disabled-date", "shortcuts", "clearable"])])) : _createCommentVNode("", true)]);
}