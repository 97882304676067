import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const goods: VabRouteRecord[] = [
  {
    path: '/goodsManagement',
    name: 'GoodsManagement',
    component: Layout,
    // redirect: '/process/productManager',
    // components: () => import('@/views/workbenche/process/addMaterial.vue'),
    meta: {
      title: '配件管理',
      icon: 'coupon-line',
      // noClosable: true,
      guard: ['workbenche.goods'],
    },
    children: [
      {
        path: 'goods',
        name: 'Goods',
        component: () =>
          import('@/views/workbenche/goodsManagement/goods/index.vue'),
        meta: {
          title: '配件仓库',
           // icon: 'map-pin-user-line',
          guard: ['workbenche.goods.goods'],
        },
      },
      {
        path: 'goods/createGoods',
        name: 'createGoods',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goods/components/createGoods.vue'
          ),
        meta: {
          title: '新增配件',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'goods/goodsDetail',
        name: 'GoodsDetail',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goods/components/goodsDetail.vue'
          ),
        meta: {
          title: '配件详情',
           // icon: 'map-pin-user-line',
          hidden: true,
          // dynamicNewTab: true,
          // guard: ['Admin'],
        },
      },
      // {
      //   path: 'goodsOutOrPut',
      //   name: 'GoodsOutOrPut',
      //   component: () =>
      //     import('@/views/workbenche/goodsManagement/goodsOutOrPut/rawStore.vue'),
      //   meta: {
      //     title: '出入库管理',
      //      // icon: 'map-pin-user-line',
      //     // guard: ['Admin'],
      //   },
      // },
      {
        path: 'goods/goodsOutOrPut/outOrPutDetail',
            name: 'GoodsOutOrPutDetail',
            component: () =>
              import(
                '@/views/workbenche/goodsManagement/goodsOutOrPut/outOrPutDetail.vue'
              ),
            meta: {
              title: '出入库明细',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.goods.outOrPutDetail'],
            },
          },

          {
            path: 'goods/goodsOutOrPut/outOtPutCollent',
            name: 'GoodsOutOtPutCollent',
            component: () =>
              import(
                '@/views/workbenche/goodsManagement/goodsOutOrPut/outOtPutCollent.vue'
              ),
            meta: {
              title: '出入库汇总',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.goods.outOtPutHuiZong'],
            },
          },
          {
            path: 'goods/goodsOutOrPut/outOrPutApply',
            name: 'GoodsOutOrPutApply',
            component: () =>
              import(
                '@/views/workbenche/goodsManagement/goodsOutOrPut/outOrPutApply.vue'
              ),
            meta: {
              title: '出入库单据',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.goods.outOrPutOrder'],
            },
          },
          {
            path: 'goods/goodsOutOrPut/manualOutOrPutDetail',
            name: 'ManualOutOrPutDetail',
            component: () =>
              import(
                '@/views/workbenche/goodsManagement/goodsOutOrPut/manualOutOrPutDetail.vue'
              ),
            meta: {
              title: '手动出入明细',
               // icon: 'map-pin-user-line',
              hidden: false,
              guard: ['workbenche.goods.outOrPutManual'],
            },
          },
      {
        path: 'goodsOutOrPut/createGoodsPut',
        name: 'CreateGoodsPut',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goodsOutOrPut/components/createGoodsPut.vue'
          ),
        meta: {
          title: '新增配件入库',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'goodsOutOrPut/createGoodsOut',
        name: 'CreateGoodsOut',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goodsOutOrPut/components/createGoodsOut.vue'
          ),
        meta: {
          title: '新增配件出库',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'goodsOutOrPut/goodsManualOutPutDetial',
        name: 'GoodsManualOutPutDetial',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goodsOutOrPut/components/goodsManualOutPutDetial.vue'
          ),
        meta: {
          title: '手动出入库详情',
          // icon: 'checkbox-blank-circle-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'goodsOutOrPut/goodsChangeProduct',
        name: 'GoodsChangeProduct',
        component: () =>
          import(
            '@/views/workbenche/goodsManagement/goodsOutOrPut/components/goodsChangeProduct.vue'
          ),
        meta: {
          title: '转配件',
           // icon: 'map-pin-user-line',
          hidden: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
