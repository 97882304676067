import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-be87205a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "upload"
};
const _hoisted_2 = {
  key: 0,
  style: {
    "position": "absolute",
    "top": "10px",
    "left": "15px",
    "color": "#999"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_alert = _resolveComponent("el-alert");
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_el_upload = _resolveComponent("el-upload");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.dialogFormVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.dialogFormVisible = $event),
    "before-close": _ctx.handleClose,
    "close-on-click-modal": false,
    title: _ctx.title,
    width: "909px"
  }, {
    footer: _withCtx(() => [_ctx.show ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 正在上传中... 当前上传成功数:" + _toDisplayString(_ctx.imgSuccessNum) + "张 当前上传失败数:" + _toDisplayString(_ctx.imgErrorNum) + "张 ", 1)) : _createCommentVNode("", true), _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.handleClose
    }, {
      default: _withCtx(() => [_createTextVNode("关闭")]),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      loading: _ctx.loading,
      style: {
        "margin-left": "10px"
      },
      type: "success",
      onClick: _ctx.submitUpload
    }, {
      default: _withCtx(() => [_createTextVNode(" 开始上传 ")]),
      _: 1
    }, 8, ["loading", "onClick"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_alert, {
      closable: false,
      title: `支持jpg、jpeg、png格式，单次可最多选择${_ctx.limit}张图片，每张不可大于${_ctx.size}M，如果大于${_ctx.size}M会自动为您过滤`,
      type: "info"
    }, null, 8, ["title"]), _createVNode(_component_el_upload, {
      ref: "uploadRef",
      accept: "image/png, image/jpeg",
      action: _ctx.action,
      "auto-upload": false,
      class: "upload-content",
      "close-on-click-modal": false,
      data: _ctx.data,
      "file-list": _ctx.fileList,
      headers: _ctx.headers,
      limit: _ctx.limit,
      "list-type": "picture-card",
      multiple: true,
      name: _ctx.name,
      "on-change": _ctx.handleChange,
      "on-error": _ctx.handleError,
      "on-exceed": _ctx.handleExceed,
      "on-preview": _ctx.handlePreview,
      "on-progress": _ctx.handleProgress,
      "on-remove": _ctx.handleRemove,
      "on-success": _ctx.handleSuccess
    }, {
      trigger: _withCtx(() => [_createVNode(_component_vab_icon, {
        icon: "add-line"
      })]),
      default: _withCtx(() => [_createVNode(_component_el_dialog, {
        modelValue: _ctx.dialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.dialogVisible = $event),
        "append-to-body": "",
        title: "查看大图"
      }, {
        default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_image, {
          src: _ctx.dialogImageUrl
        }, null, 8, ["src"])])]),
        _: 1
      }, 8, ["modelValue"])]),
      _: 1
    }, 8, ["action", "data", "file-list", "headers", "limit", "name", "on-change", "on-error", "on-exceed", "on-preview", "on-progress", "on-remove", "on-success"])])]),
    _: 1
  }, 8, ["modelValue", "before-close", "title"]);
}