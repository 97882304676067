import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const process: VabRouteRecord[] = [
  {
    path: '/processManagement',
    name: 'ProcessManagement',
    component: Layout,
    // redirect: '/processManagement/processOrder',
    // component: () =>
    //   import('@/views/workbenche/processManagement/processOrder/addMaterial.vue'),
    meta: {
      title: '加工管理',
      icon: 'inbox-unarchive-line',
      // noClosable: true,
      guard: ['workbenche.process'],
    },
    children: [
      {
        path: 'processOrder',
        name: 'ProcessOrder',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/index.vue'
          ),
        meta: {
          title: '加工订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.process.process'],
        },
      },
      {
        path: 'processOrder/processDetail',
        name: 'ProcessDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/processDetail.vue'
          ),
        meta: {
          title: '加工单详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processOrder/createProcess',
        name: 'CreateProcess',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/createProcess.vue'
          ),
        meta: {
          title: '新建加工单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processOrder/updateProcess',
        name: 'UpdateProcess',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/updateProcess.vue'
          ),
        meta: {
          title: '编辑加工单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processReceiveOrder',
        name: 'ProcessReceiveOrder',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReceiveOrder/index.vue'
          ),
        meta: {
          title: '收货订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.process.processRev'],
        },
      },

      {
        path: 'processReceiveOrder/processReceiveDetail',
        name: 'ProcessReceiveDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReceiveOrder/processReceiveDetail.vue'
          ),
        meta: {
          title: '收货详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processReceiveOrder/createProcessReceive',
        name: 'CreateProcessReceive',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReceiveOrder/createProcessReceive.vue'
          ),
        meta: {
          title: '新建收货单',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processReceiveOrder/updateProcessReceive',
        name: 'UpdateProcessReceive',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReceiveOrder/updateProcessReceive.vue'
          ),
        meta: {
          title: '编辑收货单',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },

      {
        path: 'processReturnOrder',
        name: 'ProcessReturnOrder',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReturnOrder/index.vue'
          ),
        meta: {
          title: '退货订单',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.process.processRet'],
        },
      },
      {
        path: 'processReturnOrder/createProcessReturn',
        name: 'CreateProcessReturn',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReturnOrder/createProcessReturn.vue'
          ),
        meta: {
          title: '新增退货',
          // icon: 'home-2-line',
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processReturnOrder/updateProcessReturn',
        name: 'UpdateProcessReturn',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReturnOrder/updateProcessReturn.vue'
          ),
        meta: {
          title: '编辑退货',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processReturnOrder/processReturnDetail',
        name: 'ProcessReturnDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReturnOrder/processReturnDetail.vue'
          ),
        meta: {
          title: '退货详情',
          // icon: 'home-2-line',
          // dynamicNewTab: true,
          hidden: true,
          // noClosable: true,
          // guard: ['Admin'],
        },
      },
      {
        path: 'processOrder/processProductDetail',
        name: 'ProcessProductDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/processProductDetail.vue'
          ),
        meta: {
          title: '加工明细',
          // icon: 'home-2-line',
          // hidden: true,
          guard: ['workbenche.process.processDetail'],
        },
      },
      {
        path: 'processOrder/processMaterialDetail',
        name: 'ProcessMaterialDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/processMaterialDetail.vue'
          ),
        meta: {
          title: '加工原料明细',
          // icon: 'home-2-line',
          // hidden: true,
          guard: ['workbenche.process.productMaterial'],
        },
      },
          {
            path: 'procuredMaterialHuiZong',
            name: 'ProcuredMaterialHuiZong',
            component: () =>
          import(
            '@/views/workbenche/processManagement/processOrder/procuredMaterialHuiZong.vue'
          ),
            meta: {
              title: '加工原料汇总',
          guard: ['workbenche.process.productMaterial'],
            },
          },
      {
        path: 'processReceiveOrder/processReceiveProductDetail',
        name: 'ProcessReceiveProductDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReceiveOrder/processReceiveProductDetail.vue'
          ),
        meta: {
          title: '收货明细',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.process.processRevDetail'],
        },
      },
      {
        path: 'processReturnOrder/processReturnProductDetail',
        name: 'ProcessReturnProductDetail',
        component: () =>
          import(
            '@/views/workbenche/processManagement/processReturnOrder/processReturnProductDetail.vue'
          ),
        meta: {
          title: '退货明细',
          // icon: 'home-2-line',
          // noClosable: true,
          guard: ['workbenche.process.processRetDetail'],
        },
      },
    ],
  },
]
