import { VabRouteRecord } from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const customer: VabRouteRecord[] = [
  {
    path: '/customer',
    name: 'Customer',
    component: Layout,
    meta: {
      title: '客户管理',
      icon: 'group-line',
      guard: ['basic.customer'],
    },
    children: [
      {
        path: '/customer',
        name: 'Customer',
        component: () => import('@/views/basic/customer/index.vue'),
        meta: {
          title: '客户管理',
          // icon: 'user-3-line',
          guard: ['basic.customer.customer'],
        },
      },
      {
        path: 'customerManager',
        name: 'CustomerManager',
        component: () => import('@/views/basic/customerManager/index.vue'),
        meta: {
          title: '客户经理',
          // icon: 'user-3-line',
          guard: ['basic.customer.manager'],
        },
      },
      {
        path: 'customerManagerIndex',
        name: 'CustomerManagerIndex',
        component: () => import('@/views/basic/customerManagerIndex/index.vue'),
        meta: {
          title: '业绩指标',
          // icon: 'user-3-line',
          guard: ['basic.customer.perIndex'],
        },
      },
      {
        path: 'customerDetail',
        name: 'CustomerDetail',
        component: () =>
          import('@/views/basic/customer/components/customerDetail.vue'),
        meta: {
          title: '客户详情',
          // icon: 'user-3-line',
          // dynamicNewTab: true,
          hidden: true,
          // guard: ['Admin'],
        },
      },
    ],
  },
]
