// 本地常量 USE_ABLE(1,'启用')
// 当前最大：36
export const BUSINESS_TYPE = {
  PROCURED_BUSINESS: { value: 1, label: '采购申请' },
  PROCURED_RECEIVE_BUSINESS: { value: 2, label: '采购收货' },
  PROCURED_RETURN_BUSINESS: { value: 3, label: '采购退货' },
  MATERIAL_OUT_BUSINESS: { value: 4, label: '原料手动出库' },
  MATERIAL_PUT_BUSINESS: { value: 5, label: '原料手动入库' },
  PROCESS_OUT_BUSINESS: { value: 35, label: '原料加工出库' },
  PROCESS_PUT_BUSINESS: { value: 36, label: '原料加工入库' },
  BASE_PROCURED_BUSINESS: { value: 6, label: '原纸采购' },
  BASE_RECEIVE_BUSINESS: { value: 7, label: '原纸收货' },
  BASE_RETURN_BUSINESS: { value: 8, label: '原纸退货' },
  MATERIAL_LOSS: { value: 9, label: '原料手动损耗' },
  MATERIAL_COVER: { value: 10, label: '原料转换' },
  BASE_COVER: { value: 11, label: '原纸转移' },
  MATERIAL_LOCK: { value: 12, label: '原料手动锁定' },
  MATERIAL_CHECK: { value: 13, label: '原料盘点' },
  SALES_APPLY: {value: 22, label: '销售接单'},
  SHIPMENT_APPLY: {value: 23, label: '销售发货'},
  SHIP_RETURN_APPLY: {value: 24, label: '销售退货'},
  WAIGOU_APPLY: {value: 25, label: '外购单'},
  WAIGOU_RECEIVE_APPLY: {value: 26, label: '外购收货'},
  WAIGOU_RETURN_APPLY: {value: 27, label: '外购退货'},

  PROCESS_APPLY: { value: 28, label: '加工申请' },
  PROCESS_RECEIVE_APPLY: { value: 29, label: '加工收货' },
  PROCESS_RETURN_APPLY: { value: 30, label: '加工退货' },

  WORK_MATERIAL_OUT: { value: 57, label: '生产原料出库' },
  WORK_MATERIAL_PUT: { value: 58, label: '生产原料归还' },
  WORK_MATERIAL_LOSS: { value: 59, label: '生产原料损耗' },
  WORK_PRODUCT_PUT: { value: 60, label: '生产成品入库' },
  WORK_PRODUCT_DEFEC: { value: 61, label: '生产次品入库' },
  PROCESS_MATERIAL_APPLY: { value: 62, label: '加工原料出库' },
}

export const MATERIAL_LOCK_BUSINESS_TYPE = {
  PROCURED_RETURN_BUSINESS: { value: 3, label: '采购退货' },
  MATERIAL_OUT_BUSINESS: { value: 4, label: '原料手动出库' },
  PROCESS_OUT_BUSINESS: { value: 35, label: '原料加工出库' },
  MATERIAL_LOCK: { value: 12, label: '原料手动锁定' },
  SHIPMENT_APPLY: {value: 23, label: '销售发货'},
  PROCESS_APPLY: { value: 28, label: '加工申请' },
  PROCESS_RETURN_APPLY: { value: 30, label: '加工退货' },
  WORK_MATERIAL_OUT: { value: 57, label: '生产原料出库' },
  WORK_MATERIAL_LOSS: { value: 59, label: '生产原料损耗' },
  PROCESS_MATERIAL_APPLY: { value: 62, label: '加工原料出库' },
}

export const MATERIAL_OP_BUSINESS_TYPE = {
  SYS_INIT: { value: -1, label: '系统初始化' },
  PROCURED_RECEIVE_BUSINESS: { value: 2, label: '采购收货' },
  PROCURED_RETURN_BUSINESS: { value: 3, label: '采购退货' },
  MATERIAL_OUT_BUSINESS: { value: 4, label: '原料手动出库' },
  MATERIAL_PUT_BUSINESS: { value: 5, label: '原料手动入库' },
  PROCESS_OUT_BUSINESS: { value: 35, label: '原料加工出库' },
  PROCESS_PUT_BUSINESS: { value: 36, label: '原料加工入库' },
  MATERIAL_LOSS: { value: 9, label: '原料手动损耗' },
  MATERIAL_COVER: { value: 10, label: '原料转换' },
  MATERIAL_LOCK: { value: 12, label: '原料手动锁定' },
  MATERIAL_CHECK: { value: 13, label: '原料盘点' },
  MATERIAL_BATCH_COVER: { value: 80, label: '原料批次转移' },
  SHIPMENT_APPLY: {value: 23, label: '销售发货'},
  SHIP_RETURN_APPLY: {value: 24, label: '销售退货'},
  WAIGOU_RECEIVE_APPLY: {value: 26, label: '外购收货'},
  WAIGOU_RETURN_APPLY: {value: 27, label: '外购退货'},
  PROCESS_APPLY: { value: 28, label: '加工申请' },
  PROCESS_RECEIVE_APPLY: { value: 29, label: '加工收货' },
  PROCESS_RETURN_APPLY: { value: 30, label: '加工退货' },
  WORK_MATERIAL_OUT: { value: 57, label: '生产原料出库' },
  WORK_MATERIAL_PUT: { value: 58, label: '生产原料归还' },
  WORK_MATERIAL_LOSS: { value: 59, label: '生产原料损耗' },
  PROCESS_MATERIAL_APPLY: { value: 62, label: '加工原料出库' },
}

export const FONT_LIST = [
  { label: 'STSong-Light', value: 'STSong-Light' },
  { label: '仿宋', value: '仿宋' },
  { label: '宋体', value: '宋体' },
  { label: '华文宋体', value: '华文宋体' },
  { label: '华文仿宋', value: '华文仿宋' },
  { label: '华文中宋', value: '华文中宋' },
  { label: '楷体', value: '楷体' },
  { label: '华文楷体', value: '华文楷体' },
  { label: '黑体', value: '黑体' },
  { label: '方正粗黑宋体', value: '方正粗黑宋体' },
]

export const USABLE_SELECT = [
  { value: 1, label: '启用', type: 'success' },
  { value: 2, label: '禁用', type: 'danger' },
]

export const DELETE_STATE = {
  NOT_DELETE: 1, //不删除
  IS_DELETE: 2, //逻辑删除
}

export const PRODUCT_MODEL = [
  { value: 1, label: '标尺', type: 'success' },
  { value: 2, label: '4*9尺', type: 'danger' },
]

export const BASE_MODEL = [
  { value: 0, label: '无', type: 'info' },
  { value: 1, label: '多层', type: 'success' },
  { value: 2, label: '颗粒', type: 'danger' },
  { value: 3, label: '多层颗粒', type: 'primary' },
]
/**
 * 销售单加急状态
 */
export const SALES_STATE = [
  { value: 1, label: '进行中', type: 'primary' },
  { value: 2, label: '已完成', type: 'success' },
  { value: 3, label: '已关闭', type: 'danger' },
]
/**
 * 销售单状态
 */
export const SALES_URGENT = [
  { value: 1, label: '正常', type: 'info' },
  { value: 2, label: '加急', type: 'danger' },
]

/**
 * 出库状态
 */
export const ORDER_OP_TYPE = [
  { value: 1, label: '出', type: 'info' },
  { value: 2, label: '不出', type: 'danger' },
]

/**
 * 入库状态
 */
export const ORDER_PP_TYPE = [
  { value: 1, label: '入', type: 'info' },
  { value: 2, label: '不入', type: 'danger' },
]

export const PARAMETER = {
  /* 产品类型 */
  PRODUCT_CHILD: 'product_child',
  /* 高光类型 */
  PRODUCT_HIGHLIGHT: 'product_highlight',
  /* 产品单位 */
  PRODUCT_UNIT: 'product_unit',
  // 原料规格
  MATERIAL_MODEL: 'material_model',
  // 类别
  MATERIAL_PAPER: 'material_paper',
  // 客户类型
  CUSTOMER_TYPE: 'customer_type',
  // 客户付款类型
  CUSTOMER_PAY_TYPE: 'customer_pay_type',
  SUPPLIER_TYPE: 'supplier_type',
  MATERIAL_UNIT: 'material_unit',
  PEIJIAN_UNIT: 'peijian_unit',
  PEIJIAN_MODEL: 'peijian_model',
  PEIJIAN_TYPE: 'peijian_type',
  // 原料类别
  MATERIAL_TYPE: 'material_type',
  // 环保等级
  MATERIAL_LEVEL: 'material_level',
  // 原料厚度
  MATERIAL_HD: 'material_hd',
  // 封边条类型--rawThickness
  MATERIAL_FB_TYPE: 'material_fb_type',
  // 封边条表面状态 -- rawLevel
  MATERIAL_FB_BM: 'material_fb_bm',
  // 封边条规格--rawModel
  MATERIAL_FB_MODEL: 'material_fb_model',
  // 原料损耗原因
  MATERIAL_LOSS: 'wtml_loss_type',
  // 防伪  工艺
  MATERIAL_FW: 'material_fw',
  MATERIAL_GY: 'material_gy',
  MATERIAL_XL: 'material_xl',
  MATERIAL_DH_MODEL: 'material_dh_model',
  PRODUCT_PRICE_TYPE: 'product_price_type',
}
export const PRODUCT_PROP_SHOW = {
  productCode: { show: true, prop: 'productCode', label: '编号', width: '150' },
  rawPaper: { show: true, prop: 'rawPaper', label: '颜色', width: '160' },
  config: { show: true, prop: 'config', label: '单双', width: '70' },
  wspName: { show: true, prop: 'wspName', label: '钢板', width: '130' },
  rawBase: { show: true, prop: 'rawBase', label: '基材', width: '160' },
  childTypeName: {
    show: true,
    prop: 'childTypeName',
    label: '类型',
    width: '100',
  },
  wareName: { show: true, prop: 'wareName', label: '仓库', width: '100' },
  locationName: {
    show: true,
    prop: 'locationName',
    label: '库位',
    width: '100',
  },
  batchCode: { show: true, prop: 'batchCode', label: '批次', width: '150' },
  relationPaper: {
    show: true,
    prop: 'relationPaper',
    label: '颜色别名',
    width: '150',
  },
  relationBase: {
    show: true,
    prop: 'relationBase',
    label: '基材别名',
    width: '150',
  },
  highlightColor: {
    show: false,
    prop: 'highlightColor',
    label: '仓库位置',
    width: '100',
  },
  goodsName: { show: true, prop: 'goodsName', label: '名称', width: '150' },
  goodsModel: { show: true, prop: 'goodsModel', label: '规格', width: '100' },
  typeName: { show: true, prop: 'typeName', label: '类型', width: '100' },
}

export const EXOPRT_HEAL = {
  HEADMAP: [
    PRODUCT_PROP_SHOW.wspName.label,
    PRODUCT_PROP_SHOW.rawPaper.label,
    PRODUCT_PROP_SHOW.rawBase.label,
    PRODUCT_PROP_SHOW.config.label,
  ],
  DATASTR: [
    PRODUCT_PROP_SHOW.wspName.prop,
    PRODUCT_PROP_SHOW.rawPaper.prop,
    PRODUCT_PROP_SHOW.rawBase.prop,
    PRODUCT_PROP_SHOW.config.prop,
  ],
}

export const GOODS_EXOPRT_HEAL = {
  HEADMAP: [
    PRODUCT_PROP_SHOW.goodsName.label,
    PRODUCT_PROP_SHOW.goodsModel.label,
    PRODUCT_PROP_SHOW.typeName.label,
  ],
  DATASTR: [
    PRODUCT_PROP_SHOW.goodsName.prop,
    PRODUCT_PROP_SHOW.goodsModel.prop,
    PRODUCT_PROP_SHOW.typeName.prop,
  ],
}

/** 单据产品类型 */
export const ORDER_PRODUCT_TYPE = {
  BASE_FLAG: { value: 1, label: '板' },
  PAPER_FLAG: { value: 2, label: '纸' },
  FENGBIAN_FLAG: { value: 4, label: '封边条' },
  PRODUCT_FLAG: { value: 20, label: '产品' },
  SECHA_FLAG: { value: 21, label: '色差' },
  CHEAPLY_USE: { value: 22, label: '次品可用' },
  CHEAPLY_SCRAP: { value: 23, label: '次品报废' },
  GOODS_FLAG: { value: 10, label: '配件' },
  MATERIAL_FLAG: { value: 9, label: '原料' },
}

/** 单据产品类型 */
export const WARE_PRODUCT_TYPE = {
  APERISE_DEFAULT: { code: 'HW0001', label: '客户订货' },
  BENWARE_DEFAULT: { code: 'HW0002', label: '本厂备货' },
  LOCATION_DEFAULT: { code: 'KW0001', label: '默认库位' },
}

export const BEN_CHANGE={
  CODE:"CU0058",
  NAME:"本厂"
}

export const ORDER_STATE = [
  { value: 1, label: '未开始', type: 'danger' },
  { value: 2, label: '进行中', type: 'primary' },
  { value: 3, label: '已完成', type: 'success' },
  { value: 4, label: '已终止', type: 'danger' },
]

export const ORDER_PRODUCT_SHOW = [
  { value: 1, label: '板', type: 'primary' },
  { value: 2, label: '加工板', type: 'primary' },
  { value: 3, label: '纸', type: 'primary' },
  { value: 4, label: '加工纸', type: 'primary' },
  { value: 5, label: '封边条', type: 'primary' },
  { value: 6, label: '低耗', type: 'primary' },
  { value: 10, label: '产品', type: 'success' },
  { value: 11, label: '色差', type: 'success' },
  { value: 12, label: '次品可用', type: 'success' },
  { value: 13, label: '次品报废', type: 'success' },
  { value: 14, label: '配件', type: 'success' },
]

export const APPROVE_STATE = [
  { value: 1, label: '待审核', type: 'info', icon: 'approve-wait' },
  { value: 2, label: '审核中', type: 'success', icon: 'approve-ing' },
  { value: 3, label: '已同意', type: 'primary', icon: 'approve-success' },
  { value: 4, label: '已撤销', type: 'warning', icon: 'approve-return' },
  { value: 5, label: '已拒绝', type: 'danger', icon: 'approve-not' },
]

/**
 * 审核状态
 */
export const APPROVE_STATE_DATA = {
  WAIT: 1,
  ING: 2,
  SUCCESS: 3,
  RETURN: 4,
  NOT: 5,
}
/**
 * 未审核单据状态
 */
export const APPROVE_STATE_NOT = [1, 2]
/**
 * 扫码状态
 */
export const SYS_PARAM = {
  CONFIREM_STATE: { value: 2, label: '单据二次确认状态， 1是 2否' },
}
/**
 * 撤销审核状态，不展示审核流
 */
export const APPROVE_STATE_RETURN = 4

export const RECEIVE_STATE = [
  { value: 1, label: '未收货', type: 'danger' },
  { value: 2, label: '收货中', type: 'primary' },
  { value: 3, label: '已完成', type: 'success' },
]

export const CONFIRM_STATE = [
  { value: 1, label: '已确认', type: 'success' },
  { value: 2, label: '未确认', type: 'danger' },
]

export const WFCCODE = [{ value: 'WFC01' }]
/**
 * 入库状态
 */
export const PRODUCT_OUT_PUT = [
  { value: 1, label: '出库', type: 'info' },
  { value: 2, label: '入库', type: 'info' },
]

export const ORDER_LEVEL = [
  { value: 1, label: '正常', type: 'success' },
  { value: 2, label: '加急', type: 'danger' },
]

/**
 * 成品是否存在表面状态（高光）
 */
export const HAVE_HIGHLIGHT = [
  { value: 1, label: 'YES', type: 'success' },
  { value: 2, label: 'NO', type: 'info' },
]

/**
 * 是否
 */
export const YES_NO = {
  YES: 1,
  NO: 2,
}
/**
 * 是否
 */
export const YES_NO_LIST = [
  { value: 1, label: '是' },
  { value: 2, label: '否', type: 'info' },
]

export const SHORTCUTS = [
  {
    text: '前一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '前一个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '前三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
]

/**
 * 全局常量
 */
export const STATIC_VARIABLE = {
  // 纸的默认过期天数
  expireDate: 80,
  row_add: 'a',
  row_update: 'u',
  row_del: 'd',
  // 搜索时间的时间跨度 默认6个月前
  TIME_SPAN: -6,
  // 预计收货时间 默认3个月
  RECEIVE_SPAN: 3,
  // 预计付款时间
  PAY_SPAN: 3,
  // 原纸转换比
  BASE_TO_MATERIAL_RATE: 3.8,
}

export const DEFAULT_SELECT = [
  { label: '是', value: 1, type: 'success' },
  { label: '否', value: 2, type: 'primary' },
]

export const MATERIAL_OP_TYPE = {
  OP_OUT: { value: 1, label: '出库', type: 'danger' },
  OP_PUT: { value: 2, label: '入库', type: 'success' },
}

export const MATERIAL_TYPE = {
  PLANK: { value: 1, label: '板', type: 'primary' },
  PLANK_P: { value: 2, label: '加工板', type: 'primary' },
  PAPER: { value: 3, label: '纸', type: 'primary' },
  PAPER_P: { value: 4, label: '加工纸', type: 'primary' },
  FB: { value: 5, label: '封边条', type: 'primary' },
  DH: { value: 6, label: '低耗', type: 'primary' },
}

export const MATERIAL_TYPE_VALUE = {
  1: '板',
  2: '加工板',
  3: '纸',
  4: '加工纸',
  5: '封边条',
  6: '低耗',
}

export const MATERIAL_ATTRIBUTES = {
  RAW_BRAND: { 1: '品类', 2: '品类', 3: '编号', 4: '编号', 5: '编号', 6: '' },
  RAW_BASE: {
    1: '基材',
    2: '基材',
    3: '名称',
    4: '名称',
    5: '名称',
    6: '名称',
  },
  RAW_MODEL: {
    1: '规格',
    2: '规格',
    3: '规格',
    4: '规格',
    5: '规格',
    6: '规格',
  },
  RAW_THICKNESS: { 1: '厚度', 2: '厚度', 3: '', 4: '', 5: '类型', 6: '' },
  RAW_LEVEL: { 1: '等级', 2: '等级', 3: '', 4: '', 5: '表面状态', 6: '' },
  TYPE: { 1: '板', 2: '加工板', 3: '纸', 4: '加工纸', 5: '封边条', 6: '低耗' },
}
/**
 * Confirm弹窗确定按钮标红关键词
 */
export const CONFIRM_BUTTON_DANGER = [
  '删除',
  '确定删除',
  '继续删除',
  '终止',
  '继续终止',
  '关闭',
  '确定关闭',
  '确定终止',
  '确定退出',
]

/**
 * 产品单双鸳鸯面
 */
export const PRODUCT_CONFIG_LIST = [
  { value: '单面', label: '单面', type: 'info' },
  { value: '双面', label: '双面', type: 'info' },
  { value: '鸳鸯面', label: '鸳鸯面', type: 'info' },
]

/**
 * 产品规格
 */
export const PRODUCT_MODEL_LIST = [
  { value: 1, label: '标尺', type: 'info' },
  { value: 2, label: '4*9尺', type: 'info' },
]

export const MATERIAL_COLUMNS = {
  1: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBase', label: '基材' },
    { popr: 'rawBrand', label: '品类' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'rawThickness', label: '厚度' },
    { popr: 'rawLevel', label: '等级' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'transportNum', label: '在途数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'baseInventory', label: '原纸库存' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'usable   ', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'rawRemark', label: '备注' },

  ],
  2: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBase', label: '基材' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'rawThickness', label: '厚度' },
    { popr: 'rawLevel', label: '等级' },
    { popr: 'rawSpecialGeneral', label: '类别' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'transportNum', label: '在途数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'usable   ', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'rawRemark', label: '备注' },
  ],
  3: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'aliasName', label: '供商编号' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBrand', label: '编号' },
    { popr: 'rawBase', label: '名称' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'rawSpecial', label: '多层' },
    { popr: 'rawGeneral', label: '颗粒' },
    { popr: 'rawHighlight', label: '高光编号' },
    { popr: 'craft', label: '工艺' },
    { popr: 'antifate', label: '防伪' },
    { popr: 'wspNameStr', label: '钢板' },
    { popr: 'paperType', label: '类别' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'baseInventory', label: '原纸库存' },
    { popr: 'expireNum ', label: '过期数量' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'baseProcured', label: '原纸采购' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'colorCancel', label: '颜色状态' },
    { popr: 'colorTime', label: '取消时间' },
    { popr: 'usable', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'seriesStr', label: '系列' },
    { popr: 'rawRemark', label: '备注' },
  ],
  4: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBase', label: '名称' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'rawSpecial', label: '多层' },
    { popr: 'rawGeneral', label: '颗粒' },
    { popr: 'rawHighlight', label: '高光编号' },
    { popr: 'paperType', label: '类别' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'usable', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'rawRemark', label: '备注' },
  ],
  5: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBrand', label: '编号' },
    { popr: 'rawBase', label: '名称' },
    { popr: 'rawThickness', label: '类型' },
    { popr: 'rawLevel', label: '表面状态' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'paperType', label: '类别' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'transportNum', label: '在途数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'usable   ', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'rawRemark', label: '备注' },
  ],
  6: [
    { popr: 'rawCode', label: '编码' },
    { popr: 'lastSuName', label: '供应商' },
    { popr: 'rawBase', label: '名称' },
    { popr: 'rawModel', label: '规格' },
    { popr: 'unitTxt', label: '单位' },
    { popr: 'rawBaseNum', label: '库存数量' },
    { popr: 'rawLockNum', label: '锁定数量' },
    { popr: 'rawUseNum', label: '可用数量' },
    { popr: 'transportNum', label: '在途数量' },
    { popr: 'rawThresholdValue', label: '报警数量' },
    { popr: 'currentPut', label: '入库数量' },
    { popr: 'currentOut', label: '出库数量' },
    { popr: 'rawLossNum', label: '损耗数量' },
    { popr: 'rawCheckNum', label: '盘点' },
    { popr: 'lastPut', label: '上一年入库' },
    { popr: 'lastOut', label: '上一年出库' },
    { popr: 'lastPrice', label: '上次单价' },
    { popr: 'rawCuStr', label: '关联客户' },
    { popr: 'stateNum', label: '状态' },
    { popr: 'usable   ', label: '上下架' },
    { popr: 'shelfTime', label: '上下架时间' },
    { popr: 'rawRemark', label: '备注' },
  ],
}

export const CM_STATE = [
  { value: 1, label: '正常', type: 'info' },
  { value: 2, label: '已关闭', type: 'danger' },
  { value: 3, label: '已生成', type: 'success' },
]

/**
 * 本厂备货仓库
 */
export const BEN_CU = {
    NAME:"本厂备货",
    CODE:"CU0058"
}

/**
 * 客户信息
 */
export const CUSTOMER_DATA = {
  XIAN_FU_DIALOG:false,
  XIN_YONG_DIALOG:false,
  XIAN_FU:'003'
}


export const MATERIAL_CLENGTH=[
{ value: 1, label: '标/纸板', type: 'success' },
{ value: 2, label: '长/纸板', type: 'success' },
]
