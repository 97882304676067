import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "d-flex a-center"
};
const _hoisted_2 = {
  class: "cp"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vab_icon = _resolveComponent("vab-icon");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_el_upload = _resolveComponent("el-upload");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_upload, {
    class: "upload-demo",
    action: _ctx.action,
    headers: _ctx.headers,
    "before-upload": $setup.beforeAvatarUpload,
    "on-success": $setup.handleAvatarSuccess,
    "on-error": $setup.handleAvatarError,
    "on-change": $setup.handleChange,
    "file-list": _ctx.fileList,
    "show-file-list": false,
    data: $props.data
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_tooltip, {
      "hide-after": 1000,
      class: "item",
      effect: "dark",
      content: $props.content,
      placement: "top"
    }, {
      default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", {}, () => [_createVNode(_component_vab_icon, {
        icon: "upload-cloud-2-line",
        style: {
          "width": "20px",
          "height": "20px"
        }
      })])]),
      _: 3
    }, 8, ["content"])])]),
    _: 3
  }, 8, ["action", "headers", "before-upload", "on-success", "on-error", "on-change", "file-list", "data"])])]);
}