import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2750a71c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
import { InfoFilled } from '@element-plus/icons-vue';
import { reactive, ref, toRaw, watch } from 'vue';
import { findLabelByValueArray } from '@/utils/customUtils';
import { USABLE_SELECT } from '@/enums/constant';

import { inject } from 'vue';
export default {
  __name: 'index',
  props: {
    /**
     * usable值
     * 可以是对象也可以是数字，对象徐使用usableValue定义key值
     */
    dataValue: {
      type: [Number, String]
    },
    /**
     * dataValue key值
     */
    valueKey: {
      type: String,
      default: 'value'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    /**
     * 点击确定请求方法-API
     */
    confirmFun: {
      type: Function
    },
    /**
     * 点击确定请求方法所带参数,不包含修改状态的参数，修改状态参数自动改变
     */
    queryData: {
      type: Object,
      default: () => {}
    },
    /**
     * 数据源，默认启用禁用
     */
    dataArray: {
      type: Array,
      default: USABLE_SELECT
    },
    // 是否可点击  修改
    changeFlag: {
      type: Boolean,
      default: true
    },
    // 使用change事件时 加载状态
    changeLoading: {
      type: Boolean,
      default: false
    },
    // 自定义提示
    title: {
      type: String,
      default: '确认修改当前状态?'
    }
  },
  emits: ['change', 'updateData'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const $baseMessage = inject('$baseMessage');
    //向父组件传递的函数

    // 定义组件的 props

    const data = ref({});
    const loading = ref(false);
    const initData = async value => {
      if (!value) {
        data.value = props.dataArray.find(item => item[props.valueKey] == Number(props.dataValue));
      } else {
        data.value = props.dataArray.find(item => item[props.valueKey] == value);
      }
      if (!data.value) {
        data.value = {
          type: ''
        };
        data.value[props.labelKey] = '';
      }
    };
    watch(() => props.dataValue, newVal => {
      if (newVal) {
        initData();
      }
    }, {
      immediate: true,
      deep: true
    });
    const confirmEvent = async () => {
      /**
       * 仅限于启用禁用
       */
      if (props.confirmFun) {
        loading.value = true;
        const map = toRaw(props.queryData);
        map['usable'] = data.value[props.valueKey] === 1 ? 2 : 1;
        const returnData = await props.confirmFun(map);
        if (returnData.code === 200) {
          $baseMessage(returnData.message, 'success', 'vab-hey-message-success');
          setTimeout(() => {
            initData(map['usable']);
          }, 100);
          emits('update', returnData.data);
        }
        loading.value = false;
      } else {
        //其他走自定义方法
        emits('change');
      }
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_popconfirm = _resolveComponent("el-popconfirm");
      const _component_el_text = _resolveComponent("el-text");
      return _openBlock(), _createElementBlock("div", null, [__props.changeFlag ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_popconfirm, {
        "cancel-button-text": "取消",
        "confirm-button-text": "确定",
        icon: _unref(InfoFilled),
        "icon-color": "#626AEF",
        placement: "top",
        title: __props.title,
        width: "200",
        onConfirm: confirmEvent
      }, {
        reference: _withCtx(() => [_createVNode(_component_el_button, {
          loading: loading.value || __props.changeLoading,
          text: "",
          type: data.value['type'] ? data.value['type'] : 'default'
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[__props.labelKey] || '-'), 1)]),
          _: 1
        }, 8, ["loading", "type"])]),
        _: 1
      }, 8, ["icon", "title"])])) : (_openBlock(), _createBlock(_component_el_text, {
        key: 1,
        class: "mx-1",
        type: data.value['type'] ? data.value['type'] : 'info'
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(data.value[__props.labelKey] || '-'), 1)]),
        _: 1
      }, 8, ["type"]))]);
    };
  }
};