import {VabRouteRecord} from '/#/router'
import Layout from '@vab/layouts/index.vue'

export const settingRouters: VabRouteRecord[] = [
  {
    path: '/setting',
    name: 'Setting',
    component: Layout,
    meta: {
      title: '系统设置',
      icon: 'settings-4-line',
      guard: ['setting'],
      breadcrumbHidden: true,
    },
    children: [
      {
        path: 'productPrice',
        name: 'ProductPrice',
        // components: () => import('@/views/setting/productPrice/addMaterial.vue'),
        meta: {
          title: '产品单价',
          icon: 'money-cny-circle-line',
          guard: ['setting.productPrice'],
        },
        children: [
          {
            path: 'productPriceTeam',
            name: 'ProductPriceTeam',
            component: () =>
              import('@/views/setting/productPrice/productPriceTeam/index.vue'),
            meta: {
              title: '报价组',
              // icon: 'checkbox-blank-circle-line',
              guard: ['setting.productPrice.team'],
            },
          },{
          path: 'productPriceTeamDetail',
          name: 'ProductPriceTeamDetail',
          component: () =>
            import(
              '@/views/setting/productPrice/productPriceTeam/components/productPriceTeamDetail.vue'
              ),
          meta: {
            title: '报价组详情',
            // icon: 'checkbox-blank-circle-line',
            hidden: true,
            // guard: ['setting.role.role'],
          },
        },
          {
            path: 'productPriceBase',
            name: 'ProductPriceBase',
            component: () =>
              import('@/views/setting/productPrice/productPriceBase/index.vue'),
            meta: {
              title: '报价产品原料',
              // icon: 'checkbox-blank-circle-line',
              guard: ['setting.productPrice.material'],
            },
          },
          // {
          //   path: 'productPricePaper',
          //   name: 'ProductPricePaper',
          //   component: () =>
          //     import(
          //       '@/views/setting/productPrice/productPricePaper/index.vue'
          //       ),
          //   meta: {
          //     title: '报价纸',
          //     // icon: 'checkbox-blank-circle-line',
          //     guard: ['setting.productPrice.paper'],
          //   },
          // },
          // {
          //   path: 'productMaterialPrice',
          //   name: 'ProductMaterialPrice',
          //   component: () =>
          //     import(
          //       '@/views/setting/productPrice/productMaterialPrice/index.vue'
          //       ),
          //   meta: {
          //     title: '报价原料',
          //     // icon: 'checkbox-blank-circle-line',
          //     guard: ['setting.productPrice.paper'],
          //   },
          // },
          {
            path: 'productMaterialPriceFB',
            name: 'ProductMaterialPriceFB',
            component: () =>
              import(
                '@/views/setting/productPrice/productMaterialPriceFB/index.vue'
                ),
            meta: {
              title: '报价封边',
              // icon: 'checkbox-blank-circle-line',
              guard: ['setting.productPrice.paper'],
            },
          },
          {
            path: 'saveMaterial',
            name: 'SaveMaterial',
            component: () =>
              import(
                '@/views/setting/productPrice/productPriceTeam/components/SaveMaterial.vue'
                ),
            meta: {
              title: '关联原料',
              // icon: 'checkbox-blank-circle-line',
              hidden: true,
              // guard: ['setting.role.role'],
            },
          },
          {
            path: 'saveMaterialPaper',
            name: 'SaveMaterialPaper',
            component: () =>
              import(
                '@/views/setting/productPrice/productPriceTeam/components/SaveMaterialPaper.vue'
                ),
            meta: {
              title: '关联原料纸',
              // icon: 'checkbox-blank-circle-line',
              hidden: true,
              // guard: ['setting.role.role'],
            },
          },
          {
            path: 'saveProductMaterialPrice',
            name: 'SaveProductMaterialPrice',
            component: () =>
              import(
                '@/views/setting/productPrice/productMaterialPrice/saveProductMaterialPrice.vue'
                ),
            meta: {
              title: '关联报价原料',
              // icon: 'checkbox-blank-circle-line',
              hidden: true,
              // guard: ['setting.role.role'],
            },
          },
          {
            path: 'saveProductMaterialPriceFB',
            name: 'SaveProductMaterialPriceFB',
            component: () =>
              import(
                '@/views/setting/productPrice/productMaterialPriceFB/saveProductMaterialPriceFB.vue'
                ),
            meta: {
              title: '关联报价封边',
              // icon: 'checkbox-blank-circle-line',
              hidden: true,
              // guard: ['setting.role.role'],
            },
          },
        ],
      },
      {
        path: 'rawMaterialPrice',
        name: 'RawMaterialPrice',
        meta: {
          title: '原料单价',
          icon: 'database-2-line',
          guard: ['setting.materialPrice'],
        },
        children: [
          {
            path: 'materialPriceGroup',
            name: 'MaterialPriceGroup',
            component: () =>
              import(
                '@/views/setting/rawMaterialPrice/materialPriceGroup/index.vue'
                ),
            meta: {
              title: '原料单价组',
              // icon: 'database-line',
              guard: ['setting.materialPrice.team'],
            },
          },
          {
            path: 'materialPriceUser',
            name: 'MaterialPriceUser',
            component: () =>
              import(
                '@/views/setting/rawMaterialPrice/materialPriceUser/index.vue'
                ),
            meta: {
              title: '关联供应商',
              // icon: 'team-line',
              guard: ['setting.materialPrice.supplier'],
            },
          },
          {
            path: 'materialPriceItem',
            name: 'MaterialPriceItem',
            component: () =>
              import(
                '@/views/setting/rawMaterialPrice/materialPriceItem/index.vue'
                ),
            meta: {
              title: '单价原料',
              // icon: 'database-2-line',
              guard: ['setting.materialPrice.material'],
            },
          },
          {
            path: 'addPriceItem',
            name: 'AddPriceItem',
            component: () =>
              import(
                '@/views/setting/rawMaterialPrice/materialPriceItem/components/AddPriceItem.vue'
                ),
            meta: {
              title: '添加单价组原料',
              // icon: 'database-2-line',
              hidden: true
              // guard: ['setting.count'],
            },
          },
        ],
      },
      // {
      //   path: 'count',
      //   name: 'Count',
      //   component: () => import('@/views/setting/count/rawStore.vue'),
      //   meta: {
      //     title: '计件设置',
      //     icon: 'coins-line',
      //     guard: ['setting.sendSet'],
      //   },
      // },
      {
        path: 'workPrice',
        name: 'WorkPrice',
        component: () => import('@/views/setting/workPrice/index.vue'),
        meta: {
          title: '计件规则',
          icon: 'coins-line',
          // guard: ['setting.workPrice'],
          guard: ['setting.approve'],
        },
      },{
        path: 'workPriceCreate',
        name: 'WorkPriceCreate',
        component: () =>
          import('@/views/setting/workPrice/workPriceCreate.vue'),
        meta: {
          title: '新增计件',
          hidden: true,
        },
      },
      {
        path: 'approve',
        name: 'Approve',
        component: () => import('@/views/setting/approve/index.vue'),
        meta: {
          title: '审核设置',
          icon: 'folder-settings-line',
          guard: ['setting.approve'],
        },
      },
      {
        path: 'permission',
        name: 'Permission',
        meta: {
          title: '权限管理',
          icon: 'shield-user-line',
          guard: ['setting.roule'],
        },
        children: [
          {
            path: 'role',
            name: 'Role',
            component: () =>
              import('@/views/setting/permission/role/index.vue'),
            meta: {
              title: '角色管理',
              guard: ['setting.roule.set'],
            },
          },
          {
            path: 'permission',
            name: 'Permission',
            component: () =>
              import('@/views/setting/permission/permission/index.vue'),
            meta: {
              title: '权限设置',
              guard: ['setting.roule.perSet'],
            },
          },
        ],
      },
      {
        path: 'print',
        name: 'Print',
        meta: {
          title: '打印设置',
          icon: 'printer-line',
          guard: ['setting.print'],
        },
        children: [
          {
            path: 'printPage',
            name: 'PrintPage',
            component: () =>
              import('@/views/setting/print/printPage/index.vue'),
            meta: {
              title: '纸张设置',
              // icon: 'file-2-line',
              guard: ['setting.print.paperSet'],
            },
          },
          {
            path: 'printOrder',
            name: 'PrintOrder',
            component: () =>
              import('@/views/setting/print/printOrder/index.vue'),
            meta: {
              title: '单据设置',
              // icon: 'draft-line',
              guard: ['setting.print.orderSet'],
            },
          },
          {
            path: 'printColumn',
            name: 'PrintColumn',
            component: () =>
              import('@/views/setting/print/printColumn/index.vue'),
            meta: {
              title: '字段设置',
              // icon: 'pages-line',
              guard: ['setting.print.keySet'],
            },
          },
        ],
      },
      // {
      //   path: 'notice',
      //   name: 'Notice',
      //   component: () => import('@/views/setting/notice/rawStore.vue'),
      //   meta: {
      //     title: '通知设置',
      //     icon: 'chat-settings-line',
      //     guard: ['setting.notice'],
      //   },
      // },
      {
        path: 'params',
        name: 'Params',
        // components: () => import('@/views/setting/params/addMaterial.vue'),
        meta: {
          title: '系统参数',
          icon: 'list-settings-line',
          guard: ['setting.system'],
        },
        children: [
          {
            path: 'customerParams',
            name: 'CustomerParams',
            component: () =>
              import('@/views/setting/params/customerParams/index.vue'),
            meta: {
              title: '客户参数',
              // icon: 'delete-column',
              guard: ['setting.system.cuParam'],
            },
          },
          {
            path: 'supplierParams',
            name: 'SupplierParams',
            component: () =>
              import('@/views/setting/params/supplierParams/index.vue'),
            meta: {
              title: '供应商参数',
              // icon: 'delete-column',
              guard: ['setting.system.suParam'],
            },
          },
          {
            path: 'materialParams',
            name: 'MaterialParams',
            component: () =>
              import('@/views/setting/params/materialParams/index.vue'),
            meta: {
              title: '原料参数',
              // icon: 'delete-column',
              guard: ['setting.system.materialParam'],
            },
          },
          {
            path: 'productParams',
            name: 'ProductParams',
            component: () =>
              import('@/views/setting/params/productParams/index.vue'),
            meta: {
              title: '产品参数',
              // icon: 'delete-column',
              guard: ['setting.system.productParam'],
            },
          },
          {
            path: 'goodsParams',
            name: 'GoodsParams',
            component: () =>
              import('@/views/setting/params/goodsParams/index.vue'),
            meta: {
              title: '配件参数',
              // icon: 'delete-column',
              guard: ['setting.system.goodsParam'],
            },
          },
          {
            path: 'workParams',
            name: 'WorkParams',
            component: () =>
              import('@/views/setting/params/workParams/index.vue'),
            meta: {
              title: '机位参数',
              // icon: 'delete-column',
              guard: ['setting.system.workParam'],
            },
          },
        ],
      },
      {
        path: 'loginSet',
        name: 'LoginSet',
        component: () => import('@/views/setting/loginSet/index.vue'),
        meta: {
          title: '登录设置',
          icon: 'user-settings-line',
          guard: ['setting.login'],
          noColumn: true,
        },
      }
    ],
  },
]
