import { useUserStore } from '@/store/modules/user';
import _ from 'lodash';

import { defineComponent, inject, reactive, onMounted, computed, toRefs } from 'vue';
import { storeToRefs } from 'pinia';
export default defineComponent({
  name: 'VabUpload',
  props: {
    url: {
      type: String,
      default: '/upload',
      required: true
    },
    name: {
      type: String,
      default: 'file',
      required: true
    },
    limit: {
      type: Number,
      default: 50,
      required: true
    },
    size: {
      type: Number,
      default: 1,
      required: true
    }
  },
  setup(props) {
    const userStore = useUserStore();
    const {
      token
    } = storeToRefs(userStore);
    const $baseMessage = inject('$baseMessage');
    const state = reactive({
      uploadRef: null,
      show: false,
      loading: false,
      dialogVisible: false,
      dialogImageUrl: '',
      action: '',
      headers: {},
      fileList: [],
      picture: 'picture',
      imgNum: 0,
      imgSuccessNum: 0,
      imgErrorNum: 0,
      typeList: null,
      title: '上传',
      dialogFormVisible: false,
      data: {}
    });
    const submitUpload = () => {
      state.uploadRef.submit();
    };
    const handleProgress = () => {
      state.loading = true;
      state.show = true;
    };
    const handleChange = (file, fileList) => {
      if (fileList && fileList.length > 0) {
        if (file.size > 1048576 * state.size) {
          fileList.filter(item => item !== file);
          state.fileList = fileList;
        } else {
          state.allImgNum = fileList.length;
        }
      }
    };
    const handleSuccess = (response, file, fileList) => {
      state.imgNum = state.imgNum + 1;
      state.imgSuccessNum = state.imgSuccessNum + 1;
      if (fileList.length === state.imgNum) {
        setTimeout(() => {
          $baseMessage(`上传完成! 共上传${fileList.length}张图片`, 'success', 'vab-hey-message-success');
        }, 1000);
      }
      setTimeout(() => {
        state.loading = false;
        state.show = false;
      }, 1000);
    };
    const handleError = (err, file) => {
      state.imgNum = state.imgNum + 1;
      state.imgErrorNum = state.imgErrorNum + 1;
      $baseMessage(`文件[${file.raw.name}]上传失败,文件大小为${_.round(file.raw.size / 1024, 0)}KB`, 'error', 'vab-hey-message-error');
      setTimeout(() => {
        state.loading = false;
        state.show = false;
      }, 1000);
    };
    const handleRemove = () => {
      state.imgNum = state.imgNum - 1;
      state.allNum = state.allNum - 1;
    };
    const handlePreview = file => {
      state.dialogImageUrl = file.url;
      state.dialogVisible = true;
    };
    const handleExceed = files => {
      $baseMessage(`当前限制选择 ${state.limit} 个文件，本次选择了
                  ${files.length}
                  个文件`, 'error', 'vab-hey-message-error');
    };
    const handleShow = data => {
      state.title = '上传';
      state.data = data;
      state.dialogFormVisible = true;
    };
    const handleClose = () => {
      state.fileList = [];
      state.picture = 'picture';
      state.allImgNum = 0;
      state.imgNum = 0;
      state.imgSuccessNum = 0;
      state.imgErrorNum = 0;
      state.headers['Authorization'] = `Bearer ${token}`;
      state.dialogFormVisible = false;
    };
    onMounted(() => {
      state.headers['Authorization'] = `Bearer ${token}`;
      state.action = props.url;
    });
    const percentage = computed(() => {
      if (state.allImgNum === 0) return 0;
      return _.round(state.imgNum / state.allImgNum, 2) * 100;
    });
    return {
      ...toRefs(state),
      submitUpload,
      handleProgress,
      handleChange,
      handleSuccess,
      handleError,
      handleRemove,
      handlePreview,
      handleExceed,
      handleShow,
      handleClose,
      percentage
    };
  }
});